import { CandidateRegistrationDtoClient } from "@/api/candidate/dto/candidate-registration.dto";
import { DelegationStatus } from "@/api/enums/DelegationStatus";
import { PayementStatus } from "@/api/enums/PayementStatus";
import { PriceType } from "@/api/enums/PriceType";
import {
  useCancelDelegationMutation,
  useNewDelegationMutation,
} from "@/api/exam-registration/exam-registration";
import { keyFactory } from "@/api/keyFactory";
import { Button } from "@/components/atoms/Button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/atoms/Dialog";
import { Form } from "@/components/atoms/Form";
import DelegationStatusBadge from "@/components/molecules/Badges/DelegationStatusBadge";
import ConfirmDialog from "@/components/molecules/ConfirmDialog";
import DelegationForm from "@/components/molecules/DelegationForm/DelegationForm";
import {
  delegationDefaultValues,
  delegationSchema,
} from "@/components/molecules/DelegationForm/DelegationFormSchema";
import { Card, CardTitle } from "@/components/molecules/RegistrationCard/Card";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";

interface DelegationCardProps {
  registration: CandidateRegistrationDtoClient;
}

const DelegationCard = ({
  registration: { delegation, id, priceType, payementStatus },
}: DelegationCardProps) => {
  const [newDelegationDialogOpen, setNewDelegationDialogOpen] = useState(false);
  const [cancelDelegationDialogOpen, setCancelDelegationDialogOpen] =
    useState(false);
  const queryClient = useQueryClient();

  const newDelegationMutation = useNewDelegationMutation({
    onSuccess: () => {
      toast.success("Délégation demandée", {
        description: "Votre demande de délégation a bien été prise en compte.",
      });

      queryClient.invalidateQueries({
        queryKey: keyFactory.candidate.registrations(),
      });

      setNewDelegationDialogOpen(false);
    },
    onError: () => {
      toast.error("Echèc de la requête", {
        description: "Votre action n'a pas pu être prise en compte.",
      });
    },
  });
  const cancelDelegationMutation = useCancelDelegationMutation({
    onSuccess: () => {
      toast.success("Délégation annulée", {
        description:
          "Vous pouvez désormais payer vous-même, ou demander une nouvelle délégation.",
      });

      queryClient.invalidateQueries({
        queryKey: keyFactory.candidate.registrations(),
      });
    },
    onError: () => {
      toast.error("Echèc de la requête", {
        description: "Votre action n'a pas pu être prise en compte.",
      });
    },
  });

  const form = useForm<z.infer<typeof delegationSchema>>({
    resolver: zodResolver(delegationSchema),
    defaultValues: delegationDefaultValues,
  });

  const onSubmit = (values: z.infer<typeof delegationSchema>) => {
    const { data, error, success } = delegationSchema.safeParse(values);

    if (!success) {
      console.error("can't parse form, aborting :", error);
      return;
    }

    newDelegationMutation.mutate({
      registrationId: id,
      delegationDraft: data,
    });
  };

  const canAskDelegation =
    (delegation && delegation.status === DelegationStatus.REFUSED) ||
    (!delegation &&
      priceType !== PriceType.REDUCED &&
      payementStatus !== PayementStatus.PERSONNAL_WAITING_FOR_VALIDATION);

  const canCancelDelegation =
    delegation &&
    (delegation.status === DelegationStatus.WAITING_ACCOUNT_CREATION ||
      delegation.status === DelegationStatus.WAITING_ACCEPTATION);

  if (!delegation && !canAskDelegation) return null;

  return (
    <Card>
      <Dialog open={newDelegationDialogOpen}>
        <DialogContent
          className="max-w-lg"
          onClose={() => setNewDelegationDialogOpen(false)}
        >
          <DialogHeader>
            <DialogTitle>Choisir un délégataire</DialogTitle>
            <DialogDescription hidden={true}>
              Formulaire pour effectuer une nouvelle demande de délégation.
            </DialogDescription>
          </DialogHeader>
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="flex w-full flex-col gap-2"
            >
              <DelegationForm />
              <Button type="submit">Confirmer</Button>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
      <ConfirmDialog
        isOpen={cancelDelegationDialogOpen}
        cancelStr="Garder ma demande "
        validateButtonVariant="red"
        validateStr="Annuler ma demande"
        onResult={(accepted) => {
          if (accepted)
            cancelDelegationMutation.mutate({
              registrationId: id,
            });

          setCancelDelegationDialogOpen(false);
        }}
        title="Annuler ma demande de délégation"
        message="Vous êtes sur le point d'annuler votre demande de délégation."
        close={() => setCancelDelegationDialogOpen(false)}
      />
      <div className="flex flex-row justify-between w-full items-center">
        {delegation && (
          <div className="flex flex-col gap-1">
            <div className="flex flex-row gap-2 items-center">
              <span className="text-gray-900 font-medium">{`${delegation.company.personSurname} ${delegation.company.personName}`}</span>
              <DelegationStatusBadge delegation={delegation} />
            </div>
            <div className="flex flex-row w-full text-gray-500 gap-3 text-xs ">
              <span>{delegation.company.name}</span>
              {/* <div className="flex flex-col w-full gap-2"> */}
              <span>{delegation.company.email}</span>
              <span>{delegation.company.phoneNumber}</span>
              {/* </div> */}
            </div>
          </div>
        )}
        {canCancelDelegation && (
          <Button
            variant="ghostWithBorders"
            onClick={() => setCancelDelegationDialogOpen(true)}
          >
            Annuler
          </Button>
        )}
        {!delegation && <CardTitle>Délégation</CardTitle>}
        {canAskDelegation && (
          <Button onClick={() => setNewDelegationDialogOpen(true)}>
            Nouveau délég.
          </Button>
        )}
      </div>
    </Card>
  );
};

export default DelegationCard;
