import { ExamType } from "@/api/enums/ExamType";
import { Badge } from "@/components/atoms/Badge";

interface ExamTypeBadgeProps {
  examType: ExamType;
}

const ExamTypeBadge = ({ examType }: ExamTypeBadgeProps) => {
  switch (examType) {
    case ExamType.INITIAL:
      return <Badge variant="purple">Initiale</Badge>;
    case ExamType.RENEW:
      return <Badge variant="sky">Renouvellement</Badge>;
  }
};

export default ExamTypeBadge;
