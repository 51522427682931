import { CertificateStatus } from "@/api/enums/CertificateStatus";
import { Badge } from "@/components/atoms/Badge";

interface CertificateStatusBadgeProps {
  expirationDate: Date;
  status: CertificateStatus;
  isAdmin?: boolean;
}

const CertificateStatusBadge = ({
  status,
  expirationDate,
  isAdmin,
}: CertificateStatusBadgeProps) => {
  if (expirationDate < new Date()) return <Badge variant="white">Expiré</Badge>;

  switch (status) {
    case CertificateStatus.PENDING:
      if (isAdmin) return <Badge variant="red">En attente validation</Badge>;
      else return <Badge variant="orange">En cours de validation</Badge>;
    case CertificateStatus.REFUSED:
      return <Badge variant="red">Refusé</Badge>;
    case CertificateStatus.VALIDATED:
      return <Badge variant="green">Valide</Badge>;
  }
};

export default CertificateStatusBadge;
