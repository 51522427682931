import { AdminRegistrationDtoClient } from "@/api/admin/dto/admin-registration.dto";
import { ExamRegistrationStatus } from "@/api/enums/exam-registration-status.enum";
import { useRegistrationsQuery } from "@/api/exam/exam";
import { Badge } from "@/components/atoms/Badge";
import { Skeleton } from "@/components/atoms/Skeleton";
import ExamRegistrationStatusBadge from "@/components/molecules/Badges/ExamRegistrationStatusBadge";
import FormationAttestationsStatusBadge from "@/components/molecules/Badges/FormationAttestationsStatusBadge";
import PreTestStatusBadge from "@/components/molecules/Badges/PreTestStatusBadge";
import { LoadingSpinner } from "@/components/molecules/LoadingSpinner";
import SmartPagination from "@/components/molecules/SmartPagination";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/molecules/Table";
import { ROUTE } from "@/constants/routes";
import usePersistent from "@/hooks/use-persistent";
import { PerimeterUtil } from "@/lib/perimeter-util";
import { cn } from "@/lib/utils";
import { keepPreviousData } from "@tanstack/react-query";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

const columnHelper = createColumnHelper<AdminRegistrationDtoClient>();

interface CandidatesTableProps {
  examId: number;
  searchText?: string;
  status?: ExamRegistrationStatus[];
}

const CandidatesTable = ({
  examId,
  searchText,
  status,
}: CandidatesTableProps) => {
  const [pageIndex, setPageIndex] = usePersistent(
    "admin-session-candidates-pageIndex",
    0
  );
  const navigate = useNavigate();

  const {
    data: registrations,
    isLoading,
    isError,
  } = useRegistrationsQuery(
    {
      examId,
      pageIndex,
      searchText,
      status,
    },
    {
      placeholderData: keepPreviousData,
    }
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor(
        ({ candidate: { firstName, lastName }, currentDelegation }) => ({
          name: `${firstName} ${lastName}`,
          currentDelegation,
        }),
        {
          id: "candidate",
          header: "Candidat",
          cell: (info) => {
            const { currentDelegation, name } = info.getValue();
            return (
              <div className="flex flex-col items-center h-9 justify-center">
                <span className="text-gray-900 whitespace-nowrap">{name}</span>
                {currentDelegation?.company && (
                  <span className="text-gray-500 font-normal whitespace-nowrap">
                    {currentDelegation.company.name}
                  </span>
                )}
              </div>
            );
          },
        }
      ),
      columnHelper.accessor("registrationNumber", {
        id: "registrationNumber",
        header: "",
        cell: (info) => <span>{info.getValue()}</span>,
      }),
      columnHelper.accessor("perimeter", {
        id: "perimeter",
        header: "",
        cell: (info) => (
          <Badge variant="white">{PerimeterUtil.toCode(info.getValue())}</Badge>
        ),
      }),
      columnHelper.accessor("status", {
        id: "status",
        header: "Statut inscr.",
        cell: (info) => (
          <ExamRegistrationStatusBadge status={info.getValue()} />
        ),
      }),
      columnHelper.accessor("preTestStatus", {
        id: "preTestStatus",
        header: "Test préalable",
        cell: (info) => (
          <PreTestStatusBadge status={info.getValue()} fromAdmin />
        ),
      }),
      columnHelper.accessor("formationCertificatesStatus", {
        id: "formationCertificatesStatus",
        header: "Attest. de form.",
        cell: (info) => (
          <FormationAttestationsStatusBadge status={info.getValue()} />
        ),
      }),
      columnHelper.accessor("candidate.currentPerimeter", {
        id: "currentPerimeter",
        header: "Pér. actuel",
        cell: (info) => {
          const perimeter = info.getValue();
          if (!perimeter || PerimeterUtil.isEmpty(perimeter)) return <></>;

          return (
            <Badge variant="white">{PerimeterUtil.toCode(perimeter)}</Badge>
          );
        },
      }),
      columnHelper.accessor("convocation", {
        id: "convocation",
        header: "Convocation",
        cell: (info) => {
          const convocation = info.getValue();
          if (!convocation)
            return <Badge variant="white"> Non disponible </Badge>;

          return (
            <Badge variant="green">
              Convocation envoyée{" "}
              {convocation.createdAt && (
                <>
                  le{" "}
                  {new Date(convocation.createdAt).toLocaleDateString("fr-FR")}
                </>
              )}
            </Badge>
          );
        },
      }),
    ],
    []
  );

  const table = useReactTable({
    data: registrations?.data ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  if (!registrations)
    return isLoading ? (
      <div className="min-w-full min-h-96">
        <Skeleton className="w-full h-full" />
      </div>
    ) : (
      <LoadingSpinner
        isError={isError}
        loadingMessage="Erreur lors du chargement des inscriptions"
      />
    );

  return (
    <>
      <div className="rounded-lg overflow-hidden border border-gray-200">
        <Table className="text-gray-600 font-medium text-sm leading-6 rounded-lg">
          <TableHeader className="items-center bg-gray-50">
            <TableRow>
              {table.getHeaderGroups()[0].headers.map((header) => (
                <TableHead
                  key={header.id}
                  style={{ width: `${header.getSize()}px` }}
                >
                  <div className="flex flex-row items-center gap-3 text-xs">
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </div>
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows.map((row, i) => (
              <TableRow
                key={row.id}
                className={cn({ "bg-gray-50": i % 2 }, "cursor-pointer")}
                onClick={() =>
                  navigate(
                    ROUTE.admin.dashboard.candidate.onGoingSession(
                      row.original.candidate.id
                    ),
                    {
                      state: { from: location.pathname },
                    }
                  )
                }
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id} className="py-4">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <SmartPagination
        currentPage={pageIndex}
        onPageChange={(newPageIndex) => setPageIndex(newPageIndex)}
        totalPages={registrations.totalPages}
      />
    </>
  );
};

export default CandidatesTable;
