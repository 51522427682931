import { Progress } from "@/components/atoms/Progress";

interface PercentageProgressProps {
  percentage: number;
}

const PercentageProgress = ({ percentage }: PercentageProgressProps) => {
  if (Number.isNaN(percentage)) return false;

  return (
    <div className="flex flex-row items-center gap-3 min-w-36">
      <Progress
        value={percentage}
        className="h-2 bg-gray-200"
        indicatorClassName="bg-purple-600"
      />
      {Math.round(percentage)}%
    </div>
  );
};

export default PercentageProgress;
