const RadioSelectedItem = ({ text }: { text: string }) => {
  // must be disabled and checked, just for display
  return (
    <div className="flex flex-row items-center gap-2">
      <div className="flex w-4 h-4 rounded-full bg-white border border-gray-300 items-center justify-center">
        <div className="w-2 h-2 rounded-full bg-gray-300 border border-gray-300 relative" />
      </div>
      <span className="text-gray-500">{text}</span>
    </div>
  );
};

export default RadioSelectedItem;
