import { useTransferDoneMutation as useCompanyTransferDoneMutation } from "@/api/company/company";
import { ExamType } from "@/api/enums/ExamType";
import { PriceType } from "@/api/enums/PriceType";
import { Role } from "@/api/enums/Role";
import { useTransferDoneMutation as useCandidateTransferDoneMutation } from "@/api/exam-registration/exam-registration";
import { keyFactory } from "@/api/keyFactory";
import { Badge } from "@/components/atoms/Badge";
import { Button } from "@/components/atoms/Button";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/atoms/Collapsible";
import { Input } from "@/components/atoms/Input";
import CopyableInput from "@/components/molecules/CopyableInput";
import { LoadingSpinner } from "@/components/molecules/LoadingSpinner";
import { Prices } from "@/constants/prices";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@atoms/Dialog";
import { DialogDescription } from "@radix-ui/react-dialog";
import { useQueryClient } from "@tanstack/react-query";
import { format } from "date-fns";
import { Check, ChevronDown, ChevronUp } from "lucide-react";
import { useState } from "react";
import { toast } from "sonner";
import ConfirmDialog from "./ConfirmDialog";

interface PayingDialogProps {
  isOpen: boolean;
  close: () => void;
  candidateFirstName: string;
  candidateLastName: string;
  registrationId: number;
  examDate: Date;
  examType: ExamType;
  priceType: PriceType;
  registrationEndDate: Date;
  // is this for a company or a candidate ?
  role: Role;
}

const PayingDialog = ({
  isOpen,
  close,
  candidateFirstName,
  candidateLastName,
  examDate,
  examType,
  priceType,
  registrationEndDate,
  registrationId,
  role,
}: PayingDialogProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const queryClient = useQueryClient();

  const onSuccess = async () => {
    toast.success("Virement pris en compte", {
      description: "Le CIFMD vous confirmera bonne réception de celui-ci.",
    });

    queryClient.invalidateQueries({
      queryKey: keyFactory.company.delegations(),
    });

    queryClient.invalidateQueries({
      queryKey: keyFactory.candidate.registrations(),
    });

    close();
  };

  const onError = () => {
    toast.error("Echèc de la requête", {
      description: "Votre action n'a pas pu être prise en compte.",
    });
  };

  const companyTransferDoneMutation = useCompanyTransferDoneMutation({
    onSuccess,
    onError,
  });

  const candidateTransferDoneMutation = useCandidateTransferDoneMutation({
    onSuccess,
    onError,
  });

  const transferDonePending =
    companyTransferDoneMutation.isPending ||
    candidateTransferDoneMutation.isPending;

  return (
    <>
      <ConfirmDialog
        isOpen={confirmDialogOpen}
        cancelStr="Annuler"
        cancelButtonVariant="ghostWithBorders"
        validateStr="Confirmer"
        onResult={(accepted) => {
          if (accepted) {
            if (role === Role.CANDIDATE)
              candidateTransferDoneMutation.mutate(registrationId);
            else if (role === Role.COMPANY)
              companyTransferDoneMutation.mutate(registrationId);
            setConfirmDialogOpen(false);
            close();
          } else {
            setConfirmDialogOpen(false);
          }
        }}
        title="Marquer le virement comme effectué"
        close={() => setConfirmDialogOpen(false)}
      />
      <Dialog open={isOpen}>
        <DialogContent
          className="max-w-lg"
          showCloseButton={!!close}
          onClose={close}
        >
          <DialogDescription>Paying dialog</DialogDescription>
          <DialogHeader>
            <DialogTitle>Paiement de l'examen</DialogTitle>
          </DialogHeader>
          <div className="flex flex-col gap-4 w-full">
            {examType === ExamType.INITIAL ? (
              <Badge variant="purple">Session initiale</Badge>
            ) : examType === ExamType.RENEW ? (
              <Badge variant="sky"> Session de renouvellement</Badge>
            ) : (
              <></>
            )}
            <div className="flex flex-col gap-1 text-gray-700">
              Candidat
              <Input
                disabled={true}
                placeholder={candidateLastName + " " + candidateFirstName}
              />
            </div>
            <div className="flex flex-row gap-2">
              <div className="flex flex-col gap-1 text-gray-700">
                Clôture des inscriptions
                <Input
                  disabled={true}
                  placeholder={format(registrationEndDate, "dd/MM/yyyy")}
                />
              </div>
              <div className="flex flex-col gap-1 text-gray-700">
                Date examen
                <Input
                  disabled={true}
                  placeholder={format(examDate, "dd/MM/yyyy")}
                />
              </div>
            </div>
            <Button className="font-medium">
              Régler {Prices[priceType]}€ par carte bancaire
            </Button>
            <Collapsible
              open={isExpanded}
              onOpenChange={setIsExpanded}
              className="w-full space-y-2"
            >
              <CollapsibleTrigger asChild className="w-full">
                <Button variant="blueLink" className="gap-2">
                  <span className="font-medium">
                    Je préfère payer via un virement bancaire
                  </span>
                  {isExpanded ? (
                    <ChevronUp className="h-5 w-5" />
                  ) : (
                    <ChevronDown className="h-5 w-5" />
                  )}
                  <span className="sr-only">Toggle</span>
                </Button>
              </CollapsibleTrigger>

              <CollapsibleContent>
                <div className="w-full text-gray-600 font-normal text-xs mb-2">
                  Privilégiez le paiement par carte pour que votre candidat soit
                  instantanément inscrit par nos équipes. Voici les informations
                  bancaires à entrer pour effectuer votre virement. Le virement
                  doit être effectué avant la date de clôture des inscriptions{" "}
                  <span className="font-semibold">
                    {format(registrationEndDate, "dd/MM/yyyy")}
                  </span>
                  . Le paiement par chèque n’est pas accepté.
                </div>
                <div className="flex flex-col w-full gap-2">
                  <div className="flex flex-row gap-2">
                    <div className="flex flex-col gap-1 w-1/2">
                      Bénéficiaire
                      <CopyableInput text="CIFMD" />
                    </div>
                    <div className="flex flex-col gap-1 w-1/2">
                      Banque
                      <CopyableInput text="CREDIT INDUSTRIEL ET COMMERCIAL" />
                    </div>
                  </div>
                  <div className="flex flex-col gap-1">
                    BIC
                    <CopyableInput text="-" />
                  </div>
                  <div className="flex flex-col gap-1">
                    IBAN
                    <CopyableInput text="FR76 3006 6104 5100 0104 2540 144" />
                  </div>
                  <div className="flex flex-col gap-1">
                    À renseigner sur votre virement
                    <CopyableInput
                      text={`${candidateLastName} ${candidateFirstName}`}
                    />
                  </div>
                  <div className="flex flex-col gap-1">
                    Montant
                    <CopyableInput text={`${Prices[priceType]}€`} />
                  </div>
                  <Button
                    variant="ghostWithBorders"
                    className="w-full gap-2"
                    onClick={() => setConfirmDialogOpen(true)}
                    disabled={transferDonePending}
                  >
                    {transferDonePending ? (
                      <LoadingSpinner />
                    ) : (
                      <>
                        <Check className="h-5 w-5" />
                        Marquer le virement comme effectué
                      </>
                    )}
                  </Button>
                </div>
              </CollapsibleContent>
            </Collapsible>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PayingDialog;
