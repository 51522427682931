import { ENDPOINT } from "@/constants/endpoints";
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import axios, { AxiosError, AxiosResponse } from "axios";
import { keyFactory } from "../keyFactory";
import { CompanyDelegationDtoClient } from "./dto/company-delegation.dto";
import { CompanyInfoDto } from "./dto/company-info.dto";

// company info

const getInfo = async () => {
  return axios
    .get<CompanyInfoDto>(ENDPOINT.company.info())
    .then((res) => res.data);
};

export const useCompanyinfoQuery = (
  props?: Partial<UseQueryOptions<CompanyInfoDto, AxiosError>>
) => {
  return useQuery({
    queryKey: keyFactory.company.info(),
    queryFn: getInfo,
    staleTime: Infinity,
    ...props,
  });
};

const changeInfo = async (data: CompanyInfoDto) => {
  return await axios.post<void>(ENDPOINT.company.postChangeInfo(), data);
};

export const useChangeCompanyinfoMutation = (
  props?: UseMutationOptions<AxiosResponse<void>, AxiosError, CompanyInfoDto>
) => {
  return useMutation({
    mutationFn: changeInfo,
    ...props,
  });
};

// delegations

const getDelegations = async () => {
  return axios
    .get<CompanyDelegationDtoClient[]>(ENDPOINT.company.delegations.getAll())
    .then((res) => res.data);
};

export const useCompanyDelegationsQuery = (
  props?: Partial<UseQueryOptions<CompanyDelegationDtoClient[], AxiosError>>
) => {
  return useQuery({
    queryKey: keyFactory.company.delegations(),
    queryFn: getDelegations,
    staleTime: Infinity,
    ...props,
  });
};

// refuse delegation

const refuseDelegation = async (registrationId: number) => {
  return await axios.post<void>(ENDPOINT.company.delegations.postRefuse(), {
    registrationId,
  });
};

export const useRefuseDelegationMutation = (
  props?: UseMutationOptions<AxiosResponse<void>, AxiosError, number>
) => {
  return useMutation({
    mutationFn: refuseDelegation,
    ...props,
  });
};

// indicate wire transfer done

const transferDone = async (registrationId: number) => {
  return await axios.post<void>(
    ENDPOINT.company.delegations.postTransferDone(),
    {
      registrationId,
    }
  );
};

export const useTransferDoneMutation = (
  props?: UseMutationOptions<AxiosResponse<void>, AxiosError, number>
) => {
  return useMutation({
    mutationFn: transferDone,
    ...props,
  });
};
