import { useGetCandidateinfoQuery } from "@/api/candidate/candidate";
import { CandidateRegistrationDtoClient } from "@/api/candidate/dto/candidate-registration.dto";
import { Role } from "@/api/enums/Role";
import { Button } from "@/components/atoms/Button";
import FileLink from "@/components/molecules/FileLink";
import { QueryLoadingSpinner } from "@/components/molecules/LoadingSpinner";
import PayingDialog from "@/components/molecules/PayingDialog";
import { Card, CardTitle } from "@/components/molecules/RegistrationCard/Card";
import PayementBadge from "@/components/pages/Candidate/Dashboard/Exams/Home/ToCome/RegistrationCard/Cards/PayementBadge";
import { RegistrationUtil } from "@/lib/registration-util";
import { useState } from "react";
interface PayementCardProps {
  registration: CandidateRegistrationDtoClient;
}

const PayementCard = ({ registration }: PayementCardProps) => {
  const { exam, id, priceType } = registration;

  const [open, setOpen] = useState(false);
  const infoQuery = useGetCandidateinfoQuery();

  if (!infoQuery.data)
    return (
      <QueryLoadingSpinner
        queries={[infoQuery]}
        loadingMessage="Chargement des informations..."
        errorMessage="Erreur de chargement"
      />
    );

  const {
    personnalInfo: { firstName, lastName },
  } = infoQuery.data;

  return (
    <Card>
      <PayingDialog
        isOpen={open}
        close={() => setOpen(false)}
        candidateFirstName={firstName}
        candidateLastName={lastName}
        registrationId={id}
        examDate={exam.date}
        examType={exam.type}
        priceType={priceType}
        registrationEndDate={exam.registeringEndDate}
        role={Role.CANDIDATE}
      />
      <div className="flex flex-row justify-between w-full items-center">
        <div className="flex flex-row gap-2">
          <CardTitle>Paiement</CardTitle>
          <PayementBadge registration={registration} />
        </div>

        <FileLink
          showName={false}
          file={RegistrationUtil.currentDebitNote(registration)}
        />

        {RegistrationUtil.candidateCanPay(registration) && (
          <Button onClick={() => setOpen(true)}>Régler maintenant</Button>
        )}
      </div>
    </Card>
  );
};

export default PayementCard;
