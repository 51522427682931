import { useCandidateCertificatesQuery } from "@/api/candidate/candidate";
import { CertificateStatus } from "@/api/enums/CertificateStatus";
import { Alert, AlertDescription, AlertTitle } from "@/components/atoms/Alert";
import { Button } from "@/components/atoms/Button";
import { AlertCircle, ArrowRight, X } from "lucide-react";
import { useState } from "react";

interface CertificatesAlertsProps {
  openCertificatePopup: () => void;
}

const CertificatesAlerts = ({
  openCertificatePopup,
}: CertificatesAlertsProps) => {
  const { data: certificates } = useCandidateCertificatesQuery();
  const [warningVisible, setWarningVisible] = useState(true);
  const [errorVisible, setErrorVisible] = useState(true);

  return (
    <>
      {warningVisible &&
        certificates?.some(
          ({ status }) => status === CertificateStatus.PENDING
        ) && (
          <Alert variant="orange">
            <AlertCircle className="h-5 w-5 stroke-white" />
            <AlertTitle className="ml-2">
              Votre certificat étranger n’est pas encore validé par notre équipe
            </AlertTitle>
            <AlertDescription className="font-normal ml-2">
              Vous ne pouvez pas vous inscrire pour le moment en renouvellement
              et en extension pour ce certificat
            </AlertDescription>
            <Button
              variant="none"
              className="absolute top-3 right-3"
              onClick={() => setWarningVisible(false)}
            >
              <X className="w-5 h-5" />
            </Button>
          </Alert>
        )}
      {errorVisible &&
        certificates?.some(
          ({ status }) => status === CertificateStatus.REFUSED
        ) && (
          <Alert variant="red">
            <AlertCircle className="h-5 w-5 stroke-white" />
            <AlertTitle className="ml-2">
              Votre certificat étranger n’a pas été validé. Veuillez déposer un
              nouveau document.
            </AlertTitle>
            <AlertDescription className="font-normal ml-2">
              Vous ne pouvez pas vous inscrire pour le moment en renouvellement
              et en extension pour ce certificat
            </AlertDescription>
            <Button
              variant="none"
              className="absolute top-3 right-3"
              onClick={() => setErrorVisible(false)}
            >
              <X className="w-5 h-5" />
            </Button>
            <Button
              className="absolute top-4 right-20 bg-white border rounded-lg text-[#DC2626] gap-2 hover:text-white hover:bg-[#DC2626] hover:border-white"
              onClick={openCertificatePopup}
            >
              Déposer mon certificat étranger
              <ArrowRight className="w-5 h-5" />
            </Button>
          </Alert>
        )}
    </>
  );
};

export default CertificatesAlerts;
