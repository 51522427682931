import { FileAskUploadDto, FileUploadDtoClient } from "@/api/dto/file.dto";
import { ENDPOINT } from "@/constants/endpoints";
import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";

// ask upload mutation

const askUploadLinks = async (data: FileAskUploadDto[]) => {
  return axios
    .post<FileUploadDtoClient[]>(ENDPOINT.files.postAskUploadLinks(), data)
    .then((res) => res.data);
};

export const useAskUploadLinksMutation = (
  props?: UseMutationOptions<
    FileUploadDtoClient[],
    AxiosError,
    FileAskUploadDto[]
  >
) => {
  return useMutation({
    mutationFn: askUploadLinks,
    ...props,
  });
};
