import { GlobalFileHelper } from "@/constants/globalFile";
import { emptyfile } from "@/constants/zodTypes";
import { cn } from "@/lib/utils";
import { useFormField } from "@atoms/Form";
import { CloudUpload, Trash2 } from "lucide-react";
import { ReactNode } from "react";
import Dropzone from "react-dropzone";
import FileLink from "./FileLink";

interface DropzoneProps {
  id?: string;
  children?: ReactNode;
  canBeRemoved?: boolean;
}

const DropzoneMolecule = ({
  id,
  children,
  canBeRemoved = false,
}: DropzoneProps) => {
  const { invalid, value, onChange } = useFormField();

  const valueIsEmpty = value instanceof File ? value.size === 0 : false;

  return (
    <Dropzone
      onDrop={(acceptedFiles) => {
        if (acceptedFiles.length > 0) {
          onChange(acceptedFiles[0]);
        } else {
          onChange(emptyfile());
        }
      }}
      accept={{
        "image/png": [".png"],
        "image/jpeg": [".jpg", ".jpeg"],
        "application/pdf": [".pdf"],
      }}
      multiple={false}
    >
      {({ getRootProps, getInputProps }) => (
        <div
          {...getRootProps()}
          className={cn(
            "flex px-6 py-4 flex-col items-center gap-2 rounded-xl border border-gray-200 bg-white hover:bg-accent hover:text-accent-foreground cursor-pointer",
            invalid && "border-red-500"
          )}
        >
          <input {...getInputProps()} id={id} />
          <div className="p-2 flex items-center justify-center rounded-lg border border-gray-200 bg-white shadow-sm">
            <CloudUpload className="w-5 h-5" />
          </div>
          {children}
          <div className="flex flex-row gap-4">
            {/* File Name / Download Link */}
            {!valueIsEmpty ? (
              <FileLink file={value}>
                {GlobalFileHelper.fileName(value)}
              </FileLink>
            ) : (
              <span className="text-gray-500">No file selected</span>
            )}
            {/* Remove Button */}
            {canBeRemoved && !valueIsEmpty && (
              <button
                className="text-red-600"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  onChange(emptyfile());
                }}
                aria-label="Remove file"
              >
                <Trash2 className="w-4 h-4" />
              </button>
            )}
          </div>
        </div>
      )}
    </Dropzone>
  );
};

export default DropzoneMolecule;
