import { useFormationInstitutesQuery } from "@/api/app";
import { FormationCertificateDtoClient } from "@/api/dto/formation-certificate.dto";
import { FormationTypeDescriptions } from "@/api/enums/FormationType";
import { ValidationStatus } from "@/api/enums/ValidationStatus";
import { Badge } from "@/components/atoms/Badge";
import { Input } from "@/components/atoms/Input";
import PerimeterDisplay from "@/components/pages/Candidate/Dashboard/Exams/Home/ToCome/RegistrationCard/DetailsDialog/PerimeterDisplay";
import { Ban, Check } from "lucide-react";
import FileLink from "../FileLink";
import { QueryLoadingSpinner } from "../LoadingSpinner";

interface FormationAttestationDisplayProps {
  attestation: FormationCertificateDtoClient;
}

const FormationAttestationDisplay = ({
  attestation: {
    formationType,
    certificate,
    perimeter,
    formationInstituteId,
    status,
  },
}: FormationAttestationDisplayProps) => {
  const formationInstitutesQuery = useFormationInstitutesQuery();

  if (!formationInstitutesQuery.data)
    return (
      <QueryLoadingSpinner
        queries={[formationInstitutesQuery]}
        loadingMessage="Chargement des instituts de formation..."
        errorMessage="Erreur lors du chargement des instituts de formation."
      />
    );

  return (
    <div className="w-full flex flex-col gap-2">
      <div className="flex flex-row gap-2">
        {status === ValidationStatus.PENDING && (
          <Badge variant="orange">En attente d'acceptation</Badge>
        )}
        {status === ValidationStatus.REFUSED && (
          <>
            <Ban className="w-6 h-6 text-red-500" />
            <Badge variant="red">Refusée</Badge>
          </>
        )}
        {status === ValidationStatus.VALIDATED && (
          <Check className="w-6 h-6 text-green-500" />
        )}
      </div>
      <div className="flex flex-row w-full gap-2 mb-4">
        <div className="flex flex-col gap-1.5 w-1/2">
          <span>Type de formation</span>
          <Input
            value={FormationTypeDescriptions[formationType]}
            disabled={true}
          />
        </div>
        <div className="flex flex-col gap-1.5 w-1/2">
          <span>Organisme de formation</span>
          <Input
            value={
              formationInstitutesQuery.data.find(
                (institute) => institute.id === formationInstituteId
              )?.description
            }
            disabled={true}
          />
        </div>
      </div>
      <FileLink outerBox={true} file={certificate} variant="secondary" />
      <PerimeterDisplay perimeter={perimeter} />
    </div>
  );
};

export default FormationAttestationDisplay;
