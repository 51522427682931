import { ExamType } from "@/api/enums/ExamType";
import { RegistrationType } from "@/api/enums/RegistrationType";
import { useAllExamsQuery } from "@/api/exam/exam";
import { LoadingSpinner } from "@/components/molecules/LoadingSpinner";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@atoms/Form";
import { ComboboxField } from "@molecules/Combobox";
import { format } from "date-fns";
import { useFormContext } from "react-hook-form";

interface ExamDateComboboxProps {
  fieldName: string;
  registrationType: RegistrationType;
  forbiddenExamIds?: Set<number>;
}

const ExamDateCombobox = ({
  fieldName,
  registrationType,
  forbiddenExamIds = new Set(),
}: ExamDateComboboxProps) => {
  const { control } = useFormContext();

  const getAllExamsQuery = useAllExamsQuery();

  return (
    <FormField
      control={control}
      name={fieldName}
      render={() => (
        <FormItem className="w-full">
          <FormLabel>
            <h2>Date de passage de l'examen</h2>
          </FormLabel>
          <FormControl>
            {getAllExamsQuery.data ? (
              <ComboboxField
                displaySearchBox={false}
                options={
                  getAllExamsQuery.isSuccess && getAllExamsQuery.data
                    ? getAllExamsQuery.data
                        .filter(
                          (exam) =>
                            // still time to register
                            new Date(exam.registeringEndDate) >= new Date() &&
                            // coherent exam type
                            ((exam.type === ExamType.INITIAL &&
                              [
                                RegistrationType.EXTENSION,
                                RegistrationType.INITIAL,
                              ].includes(registrationType)) ||
                              (exam.type === ExamType.RENEW &&
                                registrationType === RegistrationType.RENEW))
                        )
                        .map((exam) => {
                          return {
                            value: exam.id,
                            disabled: forbiddenExamIds.has(exam.id),
                            label: `${format(
                              exam.date,
                              "dd/MM/yyyy"
                            )} (Date limite d'inscription le ${format(
                              exam.registeringEndDate,
                              "dd/MM/yyyy"
                            )} )`,
                          };
                        })
                    : []
                }
                placeholder={"-"}
              />
            ) : (
              <LoadingSpinner
                isLoading={getAllExamsQuery.isLoading}
                loadingMessage="Chargement des certificats..."
                isError={getAllExamsQuery.isError}
              />
            )}
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export default ExamDateCombobox;
