import { RegistrationType } from "@/api/enums/RegistrationType";
import { Badge } from "../../atoms/Badge";

interface RegistrationTypeBadgeProps {
  registrationType: RegistrationType;
}

const RegistrationTypeBadge = ({
  registrationType,
}: RegistrationTypeBadgeProps) => {
  switch (registrationType) {
    case RegistrationType.RENEW:
      return <Badge variant="sky">Renouvellement</Badge>;
    case RegistrationType.EXTENSION:
      return <Badge variant="green">Extension</Badge>;
    case RegistrationType.INITIAL:
      return <Badge variant="purple">Initiale</Badge>;
  }
};

export default RegistrationTypeBadge;
