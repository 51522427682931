import { ENDPOINT } from "@/constants/endpoints";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import _ from "lodash";
import { FormationInstituteDtoClient } from "./dto/formation-institutes.dto";
import { keyFactory } from "./keyFactory";

export const replaceNullsWith = <T>(obj: T, replacement: any): T => {
  return _.cloneDeepWith(obj, (value) => {
    if (value === null) {
      return replacement;
    }
  });
};

export const removeNullsDeep = (obj: any): any => {
  if (_.isArray(obj)) {
    return obj.map(removeNullsDeep);
  } else if (_.isObject(obj)) {
    return _.omitBy(
      _.mapValues(obj, removeNullsDeep),
      (value) => value === null
    );
  }
  return obj;
};

// hello world

const helloWorld = async () => {
  return axios.get<string>(ENDPOINT.helloWorld).then((res) => res.data);
};

export const useHelloWorldQuery = () => {
  return useQuery<string>({
    queryKey: keyFactory.hello(),
    queryFn: helloWorld,
  });
};

// formation institutes

const formationInstitutes = async () => {
  return axios
    .get<FormationInstituteDtoClient[]>(ENDPOINT.formationInstitutes.getAll())
    .then((res) => res.data);
};

export const useFormationInstitutesQuery = () => {
  return useQuery<FormationInstituteDtoClient[]>({
    queryKey: keyFactory.formationInstitutes.all(),
    queryFn: formationInstitutes,
    staleTime: 1000 * 60 * 60,
    gcTime: 1000 * 60 * 60,
  });
};
