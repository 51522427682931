import { useEffect, useState } from "react";

function usePersistent<T>(
  storageKey: string,
  initialValue: T
): [T, (tab: T) => void] {
  const [current, setCurrent] = useState<T>(() => {
    const savedValue = localStorage.getItem(storageKey);
    if (!savedValue) return initialValue;

    const parsedValue = JSON.parse(savedValue);
    return typeof parsedValue === typeof initialValue
      ? parsedValue
      : initialValue;
  });

  useEffect(() => {
    localStorage.setItem(storageKey, JSON.stringify(current));
  }, [current, storageKey]);

  return [current, setCurrent];
}

export default usePersistent;
