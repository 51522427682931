import {
  useCertificateProrogationRemoveMutation,
  useCertificatesQuery,
} from "@/api/admin/admin";
import { CertificateDtoClient } from "@/api/dto/certificate.dto";
import { CertificateStatus } from "@/api/enums/CertificateStatus";
import { keyFactory } from "@/api/keyFactory";
import { Badge } from "@/components/atoms/Badge";
import { Button } from "@/components/atoms/Button";
import CertificateStatusBadge from "@/components/molecules/Badges/CertificateStatusBadge";
import ConfirmDialog from "@/components/molecules/ConfirmDialog";
import FileLink from "@/components/molecules/FileLink";
import { QueryLoadingSpinner } from "@/components/molecules/LoadingSpinner";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/molecules/Table";
import { PerimeterUtil } from "@/lib/perimeter-util";
import { cn } from "@/lib/utils";
import { useQueryClient } from "@tanstack/react-query";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Trash2 } from "lucide-react";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "sonner";
import AddProrogationDialog from "./AddProrogationDialog";

const columnHelper = createColumnHelper<CertificateDtoClient>();

const Certificates = () => {
  const { id: idstr } = useParams();
  const id = Number(idstr);
  const [certificateIdRemove, setCertificateIdRemove] = useState<null | number>(
    null
  );
  const [certificateAdd, setCertificateIdAdd] = useState<
    undefined | CertificateDtoClient
  >(undefined);
  const queryClient = useQueryClient();

  const certificatesQuery = useCertificatesQuery(id);
  const prorogationRemoveMutation = useCertificateProrogationRemoveMutation();

  const columns = useMemo(
    () => [
      columnHelper.accessor("file.createdAt", {
        id: "createdAt",
        header: "Date dépôt",
        cell: (info) => (
          <span>
            {new Date(info.getValue() as Date).toLocaleDateString("fr-FR")}
          </span>
        ),
      }),
      columnHelper.accessor(
        ({ status, expirationDate }) => ({ status, expirationDate }),
        {
          id: "status",
          header: "Statut",
          cell: (info) => {
            const { expirationDate, status } = info.getValue() as {
              expirationDate: Date;
              status: CertificateStatus;
            };
            return (
              <CertificateStatusBadge
                expirationDate={new Date(expirationDate)}
                status={status}
              />
            );
          },
        }
      ),
      columnHelper.accessor("country", {
        id: "country",
        header: "Pays",
        cell: (info) => <span>{info.getValue()}</span>,
      }),
      columnHelper.accessor("number", {
        id: "number",
        header: "N° certificat",
        cell: (info) => <span>{info.getValue()}</span>,
      }),
      columnHelper.accessor(
        ({ expirationDate, expirationDateWithoutProrogation }) =>
          new Date(
            expirationDateWithoutProrogation ?? expirationDate
          ).toLocaleDateString("fr-FR"),
        {
          id: "expirationDate",
          header: "Fin de validité",
          cell: (info) => <span>{info.getValue()}</span>,
        }
      ),
      columnHelper.accessor(
        ({ expirationDate, expirationDateWithoutProrogation }) =>
          expirationDateWithoutProrogation
            ? new Date(expirationDate).toLocaleDateString("fr-FR")
            : "-",
        {
          id: "prorogationDate",
          header: "Date de prorogation",
          cell: (info) => <span>{info.getValue()}</span>,
        }
      ),
      columnHelper.accessor((x) => x, {
        id: "prorogation",
        header: "Prorogation",
        cell: (info) => {
          const certificate = info.getValue();

          const {
            id: certificateId,
            prorogationDocument,
            expirationDateWithoutProrogation,
            status,
          } = certificate;

          return (
            <span>
              {expirationDateWithoutProrogation ? (
                <div className="flex flex-row items-center gap-2">
                  <FileLink file={prorogationDocument} showName={false} />
                  <Button
                    variant="red"
                    className="px-2.5 py-1.5"
                    onClick={() => setCertificateIdRemove(certificateId)}
                  >
                    <Trash2 className="w-4 h-4" />
                  </Button>
                </div>
              ) : status === CertificateStatus.VALIDATED ? (
                <Button onClick={() => setCertificateIdAdd(certificate)}>
                  Ajouter
                </Button>
              ) : (
                "-"
              )}
            </span>
          );
        },
      }),
      columnHelper.accessor("perimeter", {
        id: "perimeter",
        header: "Périmètre",
        cell: (info) => (
          <Badge variant="white">{PerimeterUtil.toCode(info.getValue())}</Badge>
        ),
      }),
      columnHelper.accessor("file", {
        id: "file",
        header: "Certificat",
        cell: (info) => <FileLink file={info.getValue()} showName={false} />,
      }),
    ],
    []
  );

  const table = useReactTable({
    data: certificatesQuery?.data ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  if (!certificatesQuery.data)
    return (
      <QueryLoadingSpinner
        queries={[certificatesQuery]}
        errorMessage="Erreur lors du chargement des certificats"
        loadingMessage="Chargement des certificats..."
      />
    );

  return (
    <>
      <ConfirmDialog
        isOpen={certificateIdRemove !== null}
        close={() => setCertificateIdRemove(null)}
        title="Supprimer la prorogation"
        validateStr="Supprimer"
        validateButtonVariant="red"
        cancelButtonVariant="ghostWithBorders"
        onResult={(accepted) => {
          if (accepted)
            prorogationRemoveMutation.mutate(
              {
                certificateId: certificateIdRemove as number,
              },
              {
                onSuccess: () => {
                  toast.success("Prorogation supprimée");
                  queryClient.invalidateQueries({
                    queryKey: keyFactory.admin.candidate.certificates(id),
                  });
                  setCertificateIdRemove(null);
                },
                onError: () => {
                  toast.error("Echec", {
                    description: "La prorogation n'a pas pu être supprimée",
                  });
                },
              }
            );
          else setCertificateIdRemove(null);
        }}
        validatePending={prorogationRemoveMutation.isPending}
      />
      <AddProrogationDialog
        candidateId={id}
        close={() => setCertificateIdAdd(undefined)}
        certificate={certificateAdd}
      />
      <div className="rounded-lg overflow-hidden border border-gray-200 w-full my-4 mx-6">
        <Table className="text-gray-600 font-medium text-sm leading-6">
          <TableHeader className="items-center bg-gray-50">
            <TableRow>
              {table.getHeaderGroups()[0].headers.map((header) => (
                <TableHead
                  key={header.id}
                  style={{ width: `${header.getSize()}px` }}
                >
                  <div className="flex flex-row items-center gap-3 text-xs">
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </div>
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows.map((row, i) => (
              <TableRow key={row.id} className={cn({ "bg-gray-50": i % 2 })}>
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id} className="py-4">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </>
  );
};

export default Certificates;
