import { DocumentType } from "@/api/dto/document-type.enum";
import { FileDto } from "@/api/dto/file.dto";
import useFileUpload from "@/hooks/FileUpload";
import useRoleBasedRedirect from "@/hooks/RoleBasedRedirect";
import { Button } from "@atoms/Button";
import { Form } from "@atoms/Form";
import { zodResolver } from "@hookform/resolvers/zod";
import AddressForm from "@molecules/AddressForm/AddressForm";
import {
  addressDefaultValues,
  optionnalAddressSchema,
} from "@molecules/AddressForm/AddressFormSchema";
import ErrorDisplay from "@molecules/ErrorDisplay";
import HelloPageLayout from "@templates/hello-page";
import { AxiosError, HttpStatusCode } from "axios";
import { Plus } from "lucide-react";
import { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { z } from "zod";
import { useRegisterUserMutation } from "../../../../api/auth/auth";
import { ROUTE } from "../../../../constants/routes";
import PersonnalInformationForm from "../../../molecules/PersonnalInfoForm/PersonnalInfoForm";
import {
  credentialsDefaultValues,
  credentialsSchema,
  personnalInfoDefaultValues,
  personnalInfoSchema,
} from "../../../molecules/PersonnalInfoForm/PersonnalInfoFormSchema";
import CertificateForm from "./CertificateForm/CertificateForm";
import {
  certificateDefaultValues,
  certificateSchema,
} from "./CertificateForm/CertificateFormSchema";
import TopText from "./TopText";

const accountCreationSchema = z.object({
  credentials: credentialsSchema,
  address: optionnalAddressSchema,
  personnalInfo: personnalInfoSchema,
  foreignCertificates: z.array(certificateSchema),
});

const AccountCreation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const hasCertificates = location.state?.hasCertificates ?? (false as boolean);
  const [errorMsg, setErrorMsg] = useState("");
  const { ensureUploaded, uploadIsPending } = useFileUpload(
    DocumentType.FOREIGN_CERTIFICATE
  );

  useRoleBasedRedirect(null);

  // need to know if candidate has certificate(s)
  useEffect(() => {
    if (!location.state)
      navigate(ROUTE.connectionHome(), { state: { hasCertificate: false } });
  });

  const handleErrorCode = (error: AxiosError) => {
    switch (error.status) {
      case HttpStatusCode.Conflict:
        setErrorMsg("Cet email est déjà utilisé.");
        return;

      case HttpStatusCode.InternalServerError:
        setErrorMsg(
          "Le serveur a rencontré une erreur lors de la création de votre compte."
        );
        return;

      case HttpStatusCode.BadRequest:
        if (
          error.response?.data !== undefined &&
          (error.response?.data as { message: string[] }).message[0].endsWith(
            "empty"
          )
        ) {
          setErrorMsg(
            "Les périmètres doivent contenir au moins un mode de transport et une catégorie de marchandises."
          );
        } else {
          setErrorMsg(
            "Une erreur est survenue lors de la création de votre compte."
          );
        }
        break;

      default:
        setErrorMsg(
          "Une erreur est survenue lors de la création de votre compte."
        );
        return;
    }
  };
  const registerUserMutation = useRegisterUserMutation({
    onSuccess: () => {
      navigate(ROUTE.connectionHome());

      toast.success("Votre compte a bien été créé.", {
        description: "Vous pouvez maintenant y acéder",
      });
    },
    onError: (error) => {
      handleErrorCode(error);
    },
  });

  const form = useForm<z.infer<typeof accountCreationSchema>>({
    resolver: zodResolver(accountCreationSchema),
    defaultValues: {
      credentials: credentialsDefaultValues,
      address: addressDefaultValues,
      personnalInfo: personnalInfoDefaultValues,
      foreignCertificates: hasCertificates ? [certificateDefaultValues] : [],
    },
  });

  const onSubmit = async (values: z.infer<typeof accountCreationSchema>) => {
    const { data, error, success } = accountCreationSchema.safeParse(values);

    if (!success) {
      console.error("can't parse form, aborting registration :", error);
      return;
    }

    const uploadedFiles = await ensureUploaded(
      ...data.foreignCertificates.map((certif) => certif.file)
    );

    if (uploadedFiles.some((file) => !file)) {
      console.error("error while uploading files");
      return;
    }

    const {
      credentials: { email, key },
      personnalInfo,
      address,
      foreignCertificates,
    } = data;

    registerUserMutation.mutate(
      {
        userInfo: {
          email,
          key,
        },
        personnalInfo,
        ...(address.country && { address }),
        foreignCertificates: foreignCertificates.map(
          ({ file, ...props }, i) => ({
            ...props,
            file: uploadedFiles[i] as FileDto,
          })
        ),
      },
      {
        onError: (error) => {
          handleErrorCode(error);
        },
      }
    );
  };

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "foreignCertificates",
  });

  return (
    <HelloPageLayout className="max-w-3xl">
      <TopText hasCertificates={hasCertificates} />
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex w-full flex-col space-y-8"
        >
          <PersonnalInformationForm />
          {hasCertificates && (
            <div className="flex flex-col w-full gap-2">
              <h2>Certificat(s) étranger</h2>
              {fields.map((field, index) => (
                <CertificateForm
                  key={field.id}
                  remove={index > 0 ? () => remove(index) : null}
                  fieldName={`foreignCertificates.${index}`}
                />
              ))}
              <Button
                variant={"white"}
                className="w-full text-gray-800 font-semibold text-sm gap-2"
                onClick={(e) => {
                  e.preventDefault();
                  append(certificateDefaultValues);
                }}
              >
                <Plus className="text-gray-500 w-5 h-5" /> Ajouter un autre
                certificat
              </Button>
            </div>
          )}
          <AddressForm fieldName="address" />
          <Button
            className="flex w-full font-semibold bg-brand-600 text-white"
            type="submit"
            disabled={uploadIsPending || registerUserMutation.isPending}
          >
            {uploadIsPending
              ? "Envoi des certificats..."
              : registerUserMutation.isPending
              ? "Création de votre compte..."
              : "Créer mon compte"}
          </Button>
          {errorMsg && <ErrorDisplay msg={errorMsg} />}
        </form>
      </Form>
    </HelloPageLayout>
  );
};

export default AccountCreation;
