import { ExamType } from "@/api/enums/ExamType";

const ENDPOINT = {
  helloWorld: "/",
  debugSentry: "/debug-sentry",

  auth: {
    base: "/auth",
    getRole: () => `${ENDPOINT.auth.base}/role`,
    postLogin: () => `${ENDPOINT.auth.base}/login`,
    postRefresh: () => `${ENDPOINT.auth.base}/refresh`,
    postLogout: () => `${ENDPOINT.auth.base}/logout`,
    postChangeEmail: () => `${ENDPOINT.auth.base}/change-email`,
    register: {
      base: () => `${ENDPOINT.auth.base}/register`,
      postCandidate: () => `${ENDPOINT.auth.register.base()}/candidate`,
      company: {
        base: () => `${ENDPOINT.auth.register.base()}/company`,
        getPrefilledInfo: () =>
          `${ENDPOINT.auth.register.company.base()}/get-prefilled-info`,
        postRegister: () => ENDPOINT.auth.register.company.base(),
      },
    },
    password: {
      base: () => `${ENDPOINT.auth.base}/password`,
      postAskChange: () => `${ENDPOINT.auth.password.base()}/ask-change`,
      postChange: () => `${ENDPOINT.auth.password.base()}/change`,
    },
  },

  candidate: {
    base: "/candidate",
    getInfo: () => ENDPOINT.candidate.base,
    getCertificates: () => `${ENDPOINT.candidate.base}/certificates`,
    getRegistrations: () => `${ENDPOINT.candidate.base}/registrations`,
    postChangeInfo: () => `${ENDPOINT.candidate.base}/change-info`,
    postCertificateUpload: () =>
      `${ENDPOINT.candidate.base}/certificate-upload`,
  },

  company: {
    base: "/company",
    info: () => ENDPOINT.company.base,
    postChangeInfo: () => `${ENDPOINT.company.base}/change-info`,
    delegations: {
      base: () => `${ENDPOINT.company.base}/delegations`,
      getAll: () => ENDPOINT.company.delegations.base(),
      postRefuse: () => `${ENDPOINT.company.delegations.base()}/refuse`,
      postTransferDone: () =>
        `${ENDPOINT.company.delegations.base()}/transfer-done`,
    },
  },

  examRegistration: {
    base: "/exam-registration",
    getCanRegister: () => `${ENDPOINT.examRegistration.base}/can-register`,
    getOptions: () => `${ENDPOINT.examRegistration.base}/get-options`,
    postCreate: () => ENDPOINT.examRegistration.base,
    postSetOptions: () => `${ENDPOINT.examRegistration.base}/set-options`,
    postConfirm: () => `${ENDPOINT.examRegistration.base}/confirm`,
    reducedPrice: {
      base: () => `${ENDPOINT.examRegistration.base}/reduced-price`,
      postNewDocument: () =>
        `${ENDPOINT.examRegistration.reducedPrice.base()}/new-document`,
    },
    formationAttestations: {
      base: () => `${ENDPOINT.examRegistration.base}/formation-attestations`,
      postNewDocuments: () =>
        `${ENDPOINT.examRegistration.formationAttestations.base()}/new-documents`,
      postAllAttestationsProvided: () =>
        `${ENDPOINT.examRegistration.formationAttestations.base()}/all-provided`,
    },
    delegation: {
      base: () => `${ENDPOINT.examRegistration.base}/delegation`,
      postRefuse: () => `${ENDPOINT.examRegistration.delegation.base()}/refuse`,
      postNew: () => `${ENDPOINT.examRegistration.delegation.base()}/new`,
    },
    postTransferDone: () => `${ENDPOINT.examRegistration.base}/transfer-done`,
  },

  exam: {
    base: "/exam",
    getAll: () => `${ENDPOINT.exam.base}/`,
    getAllDetailled: () => `${ENDPOINT.exam.base}/detailled`,
    getNextNumber: (type: ExamType) =>
      `${ENDPOINT.exam.base}/next-number/${type}`,
    getRegistrations: () => `${ENDPOINT.exam.base}/registrations`,
    postCreate: () => `${ENDPOINT.exam.base}/create`,
    postModify: () => `${ENDPOINT.exam.base}/modify`,
  },

  formationInstitutes: {
    base: "/formation-institutes",
    getAll: () => `${ENDPOINT.formationInstitutes.base}`,
  },

  files: {
    base: "/files",
    postAskUploadLinks: () => `${ENDPOINT.files.base}/ask-upload-links`,
  },

  admin: {
    base: "/admin",
    foreignCertificates: {
      base: () => `${ENDPOINT.admin.base}/foreign-certificates`,
      getAll: () => ENDPOINT.admin.foreignCertificates.base(),
      postSetStatus: () =>
        `${ENDPOINT.admin.foreignCertificates.base()}/set-status`,
    },
    candidates: {
      base: () => `${ENDPOINT.admin.base}/candidates`,
      getAll: () => ENDPOINT.admin.candidates.base(),
    },
    candidate: {
      base: () => `${ENDPOINT.admin.base}/candidate`,
      getBasicInfo: (candidateId: number) =>
        `${ENDPOINT.admin.candidate.base()}/${candidateId}`,
      info: {
        base: () => `${ENDPOINT.admin.candidate.base()}/info`,
        get: (candidateId: number) =>
          `${ENDPOINT.admin.candidate.info.base()}/${candidateId}`,
        postModify: () => `${ENDPOINT.admin.candidate.info.base()}/modify/`,
      },
      getOngoingRegistration: (candidateId: number) =>
        `${ENDPOINT.admin.candidate.base()}/ongoing-registration/${candidateId}`,
      getRegistrations: (candidateId: number) =>
        `${ENDPOINT.admin.candidate.base()}/registrations/${candidateId}`,
      getCertificates: (candidateId: number) =>
        `${ENDPOINT.admin.candidate.base()}/certificates/${candidateId}`,
      postDelete: (candidateId: number) =>
        `${ENDPOINT.admin.candidate.base()}/delete/${candidateId}`,
      registration: {
        base: () => `${ENDPOINT.admin.candidate.base()}/registration`,
        get: (candidateId: number, registrationId: number) =>
          `${ENDPOINT.admin.candidate.registration.base()}/${candidateId}/${registrationId}`,
        formationAttestation: {
          base: () =>
            `${ENDPOINT.admin.candidate.registration.base()}/formation-attestation`,
          postStatus: () =>
            `${ENDPOINT.admin.candidate.registration.formationAttestation.base()}/status`,
          postGlobalStatus: () =>
            `${ENDPOINT.admin.candidate.registration.formationAttestation.base()}/global-status`,
          postUpload: () =>
            `${ENDPOINT.admin.candidate.registration.formationAttestation.base()}/upload`,
        },
        reducedPrice: {
          base: () =>
            `${ENDPOINT.admin.candidate.registration.base()}/reduced-price`,
          postStatus: () =>
            `${ENDPOINT.admin.candidate.registration.reducedPrice.base()}/status`,
          postUpload: () =>
            `${ENDPOINT.admin.candidate.registration.reducedPrice.base()}/upload`,
        },
        payement: {
          base: () =>
            `${ENDPOINT.admin.candidate.registration.base()}/payement`,
          postTransferStatus: () =>
            `${ENDPOINT.admin.candidate.registration.payement.base()}/transfer-status`,
        },
        postPreTestStatus: () =>
          `${ENDPOINT.admin.candidate.registration.base()}/pre-test-status`,
        postUnregister: () =>
          `${ENDPOINT.admin.candidate.registration.base()}/unregister`,
        postReport: () =>
          `${ENDPOINT.admin.candidate.registration.base()}/report`,
      },
      certificate: {
        base: () => `${ENDPOINT.admin.candidate.base()}/certificate`,
        prorogation: {
          base: () =>
            `${ENDPOINT.admin.candidate.certificate.base()}/prorogation`,
          postAdd: () =>
            `${ENDPOINT.admin.candidate.certificate.prorogation.base()}/add`,
          postRemove: () =>
            `${ENDPOINT.admin.candidate.certificate.prorogation.base()}/remove`,
        },
      },
    },
  },
};

export { ENDPOINT };
