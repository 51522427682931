import { DocumentType } from "@/api/dto/document-type.enum";
import { FileDto, FileUploadDtoClient } from "@/api/dto/file.dto";
import { useAskUploadLinksMutation } from "@/api/s3/s3";
import { GlobalFile } from "@/constants/globalFile";
import axios from "axios";
import { useCallback, useState } from "react";

// helpers

const uploadDocument = async (uploadLink: string, file: File) => {
  await axios.put(uploadLink, file, {
    headers: {
      "Content-Type": file.type,
    },
    withCredentials: false,
  });
};

// hook

const useFileUpload = (documentType: DocumentType) => {
  const [uploadIsPending, setUploadIsPending] = useState(false);
  const askUploadMutation = useAskUploadLinksMutation();

  const ensureUploaded = useCallback(
    async (...files: GlobalFile[]): Promise<(FileDto | null)[]> => {
      if (files.length === 0) return [];

      // which files need to be uploaded ?
      const filesToUpload = files.filter((file) => file instanceof File);
      if (filesToUpload.length === 0) return files as FileDto[];

      // ask for upload links
      let fileUploadDtos: FileUploadDtoClient[];

      try {
        fileUploadDtos = await askUploadMutation.mutateAsync(
          filesToUpload.map(({ name }) => ({
            name,
            documentType,
          }))
        );
      } catch (error) {
        console.error("Error while fetching upload links:", error);
        return files.map(() => null);
      }

      setUploadIsPending(true);

      // upload files and handle errors individually
      const newFileDtos: (FileDto | null)[] = await Promise.all(
        files.map(async (file) => {
          if (!(file instanceof File)) return file;

          const fileUploadDto = fileUploadDtos.find(
            (link) => link.name === file.name
          );
          if (!fileUploadDto) return null;

          const { putObjectURL, ...fileDto } = fileUploadDto;

          // try to upload file
          try {
            await uploadDocument(putObjectURL, file);
            return fileDto;
          } catch (uploadError) {
            console.error(`Error uploading file ${file.name}:`, uploadError);
            return null;
          }
        })
      );

      setUploadIsPending(false);

      return newFileDtos;
    },
    [documentType, askUploadMutation]
  );

  return { ensureUploaded, uploadIsPending };
};

export default useFileUpload;
