"use client";

import { CircleCheck, Info, TriangleAlert, XCircle } from "lucide-react";
import { useTheme } from "next-themes";
import { Toaster as Sonner } from "sonner";

type ToasterProps = React.ComponentProps<typeof Sonner>;

const Toaster = ({ ...props }: ToasterProps) => {
  const { theme = "system" } = useTheme();

  return (
    <Sonner
      theme={theme as ToasterProps["theme"]}
      className="toaster group"
      toastOptions={{
        classNames: {
          toast:
            "gap-4 group toast group-[.toaster]:bg-background group-[.toaster]:text-foreground group-[.toaster]:border-border group-[.toaster]:shadow-lg",
          description: "group-[.toast]:text-muted-foreground",
          actionButton:
            "group-[.toast]:bg-primary group-[.toast]:text-primary-foreground",
          cancelButton:
            "group-[.toast]:bg-muted group-[.toast]:text-muted-foreground",
          title: "font-semibold",
          content: "text-sm",
        },
      }}
      icons={{
        info: <Info className="text-blue-500" />,
        success: <CircleCheck className="text-green-500" />,
        warning: <TriangleAlert className="text-orange-500" />,
        error: <XCircle className="text-red-500" />,
      }}
      closeButton
      duration={3000}
      {...props}
    />
  );
};

export { Toaster };
