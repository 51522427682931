import { Role } from "@/api/enums/Role";
import SideBar from "@/components/molecules/SideBar";
import { ROUTE } from "@/constants/routes";
import useRoleBasedRedirect from "@/hooks/RoleBasedRedirect";
import { cn } from "@/lib/utils";
import { Check, Info, UserSquare } from "lucide-react";
import { NavLink, Outlet } from "react-router-dom";

const Dashboard = () => {
  useRoleBasedRedirect(Role.COMPANY);

  return (
    <div className="flex flex-row h-screen">
      <SideBar
        collapsable={false}
        expandedChildren={
          <div className="flex flex-col px-4 text-gray-700 font-medium w-full h-full">
            {[
              {
                to: ROUTE.company.dashboard.delegations(),
                children: (
                  <>
                    <Check className="w-4 h-4" />
                    <span>Délégations</span>
                  </>
                ),
              },
              {
                to: ROUTE.company.dashboard.candidates(),
                children: (
                  <>
                    <UserSquare className="w-4 h-4" />
                    <span>Candidats</span>
                  </>
                ),
              },
              {
                to: ROUTE.company.dashboard.information(),
                children: (
                  <>
                    <Info className="w-4 h-4" />
                    <span>Informations entreprise</span>
                  </>
                ),
              },
            ].map(({ children, to }) => (
              <NavLink
                key={to}
                to={to}
                className={({ isActive }) =>
                  cn(
                    "flex flex-row items-center gap-2.5 rounded-lg py-2 px-4 text-sm font-medium transition-colors duration-200",
                    {
                      "text-brand-700 bg-gray-100 hover:bg-blue-50 hover:text-blue-700":
                        isActive,
                      "text-gray-600 hover:bg-gray-100 hover:text-gray-700":
                        !isActive,
                    }
                  )
                }
              >
                <div className="flex flex-row py-1.5 gap-2 items-center">
                  {children}
                </div>
              </NavLink>
            ))}
          </div>
        }
      />
      <div className="flex-1 overflow-auto">
        <Outlet />
      </div>
    </div>
  );
};

export default Dashboard;
