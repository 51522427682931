import { MerchandiseClass } from "@/api/enums/MerchandiseClass";
import { TransportMode } from "@/api/enums/TransportMode";

type Perimeter = {
  merchandises: MerchandiseClass[];
  transportModes: TransportMode[];
};

class PerimeterUtil {
  public static emptyPerimeter = (): Perimeter => ({
    merchandises: [],
    transportModes: [],
  });

  public static toCode = ({ transportModes, merchandises }: Perimeter) => {
    return (
      (transportModes.includes(TransportMode.ROAD) ? "R" : "") +
      (transportModes.includes(TransportMode.TRAIN) ? "F" : "") +
      (transportModes.includes(TransportMode.BOAT) ? "B" : "") +
      (merchandises.includes(MerchandiseClass.EXPLOSIVES) ? "1" : "") +
      (merchandises.includes(MerchandiseClass.GAS) ? "2" : "") +
      (merchandises.includes(MerchandiseClass.RADIOACTIVE) ? "7" : "") +
      (merchandises.includes(MerchandiseClass.CHEMICAL) ? "C" : "") +
      (merchandises.includes(MerchandiseClass.HYDROCARBONS) ? "H" : "")
    );
  };

  public static fromCode = (code: string): Perimeter => {
    const transportModes = [];
    const merchandises = [];

    if (code.includes("R")) transportModes.push(TransportMode.ROAD);
    if (code.includes("T")) transportModes.push(TransportMode.TRAIN);
    if (code.includes("B")) transportModes.push(TransportMode.BOAT);

    if (code.includes("1")) merchandises.push(MerchandiseClass.EXPLOSIVES);
    if (code.includes("2")) merchandises.push(MerchandiseClass.GAS);
    if (code.includes("7")) merchandises.push(MerchandiseClass.RADIOACTIVE);
    if (code.includes("C")) merchandises.push(MerchandiseClass.CHEMICAL);
    if (code.includes("H")) merchandises.push(MerchandiseClass.HYDROCARBONS);

    return {
      transportModes,
      merchandises,
    };
  };

  public static union = (...perimeters: Perimeter[]): Perimeter => {
    if (perimeters.length === 0)
      return { merchandises: [], transportModes: [] };

    return perimeters.reduce(
      (acc, perimeter) => {
        return {
          merchandises: [
            ...new Set([...acc.merchandises, ...perimeter.merchandises]),
          ],
          transportModes: [
            ...new Set([...acc.transportModes, ...perimeter.transportModes]),
          ],
        };
      },
      { merchandises: [], transportModes: [] } // Initial accumulator value
    );
  };

  public static includes = (
    including: Perimeter,
    included: Perimeter
  ): boolean => {
    return (
      included.transportModes.every((mode) =>
        including.transportModes.includes(mode)
      ) &&
      included.merchandises.every((mode) =>
        including.merchandises.includes(mode)
      )
    );
  };

  public static restrict = (
    original: Perimeter,
    restricted: Perimeter
  ): Perimeter => {
    return {
      merchandises: original.merchandises.filter((val) =>
        restricted.merchandises.includes(val)
      ),
      transportModes: original.transportModes.filter((val) =>
        restricted.transportModes.includes(val)
      ),
    };
  };

  public static fullPerimeter = (): Perimeter => ({
    merchandises: Object.values(MerchandiseClass),
    transportModes: Object.values(TransportMode),
  });

  public static isEmpty = (perimeter: Perimeter): boolean => {
    return (
      perimeter.merchandises.length === 0 &&
      perimeter.transportModes.length === 0
    );
  };
}

export { PerimeterUtil };
export type { Perimeter };
