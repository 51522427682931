import { useRegistrationsQuery } from "@/api/candidate/candidate";
import GreenCheck from "@/components/atoms/GreenCheck";
import { LoadingSpinner } from "@/components/molecules/LoadingSpinner";
import RegistrationCard from "./RegistrationCard/RegistrationCard";

const ToCome = () => {
  const registrationsQuery = useRegistrationsQuery();

  if (!registrationsQuery.data)
    return (
      <LoadingSpinner
        isLoading={registrationsQuery.isLoading}
        isError={registrationsQuery.isError}
        loadingMessage="Chargement de vos inscriptions..."
        errorMessage="Erreur lors du chargement de vos inscriptions."
      />
    );

  // sorting allows for consistent placement
  // between refreshes
  return (
    <div className="flex flex-col w-full gap-6">
      <div>
        <span className="flex flex-row gap-2 items-center">
          Votre inscription à l'examen de votre choix ne sera validée que
          lorsque tous les éléments obligatoires requis ci-dessous auront un
          "coche vert"
          <GreenCheck />.
        </span>
        <span>
          Assurez-vous de compléter chaque élément dans les délais prévus.
        </span>
      </div>
      <div className="flex flex-col gap-6">
        {registrationsQuery.data
          .filter(({ exam: { date } }) => new Date(date) >= new Date())
          .sort(
            ({ exam: { date: date0 } }, { exam: { date: date1 } }) =>
              new Date(date1).getTime() - new Date(date0).getTime()
          )
          .map((registration, i) => (
            <RegistrationCard registration={registration} key={i} />
          ))}
      </div>
    </div>
  );
};

export default ToCome;
