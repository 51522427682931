import { ReactNode } from "react";

interface GrayCardProps {
  children: ReactNode;
}

const GrayCard = ({ children }: GrayCardProps) => {
  return (
    <div className="flex flex-row bg-gray-100 rounded-md px-4 py-2 gap-2 w-fit items-center">
      {children}
    </div>
  );
};

export default GrayCard;
