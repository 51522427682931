import { ExamRegistrationStatus } from "@/api/enums/exam-registration-status.enum";
import { Badge } from "@/components/atoms/Badge";

interface ExamRegistrationStatusBadgeProps {
  status: ExamRegistrationStatus;
}

const ExamRegistrationStatusBadge = ({
  status,
}: ExamRegistrationStatusBadgeProps) => {
  switch (status) {
    case ExamRegistrationStatus.UNCOMPLETE:
      return <Badge variant="orange">Pré-inscrit</Badge>;
    default:
      return <Badge variant="green">Inscrit</Badge>;
  }
};

export default ExamRegistrationStatusBadge;
