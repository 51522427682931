import { ENDPOINT } from "@/constants/endpoints";
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { AdminRegistrationDtoClient } from "../admin/dto/admin-registration.dto";
import { ExamDtoClient } from "../dto/exam.dto";
import { PaginationResponseDto } from "../dto/pagination.dto";
import { ExamType } from "../enums/ExamType";
import { keyFactory } from "../keyFactory";
import { ExamCreationDto } from "./dto/exam-creation.dto";
import { ExamDetailledtoClient } from "./dto/exam-detailled.dto";
import { ExamModificationDto } from "./dto/exam-modification.dto";
import { RegistrationsPaginationRequestDto } from "./dto/registrations.dto";

// get all existing exams

const allExams = async () => {
  return axios
    .get<ExamDtoClient[] | null>(ENDPOINT.exam.getAll())
    .then((res) => res.data);
};

export const useAllExamsQuery = (
  props?: UseQueryOptions<ExamDtoClient[] | null, AxiosError>
) => {
  return useQuery({
    queryKey: keyFactory.exam.all(),
    queryFn: allExams,
    staleTime: 1000 * 60 * 5,
    gcTime: 1000 * 60 * 5,
    ...props,
  });
};

// get all existing exams, detailled

const allExamsDetailled = async () => {
  return axios
    .get<ExamDetailledtoClient[]>(ENDPOINT.exam.getAllDetailled())
    .then((res) => res.data);
};

export const useAllExamsDetailledQuery = (
  props?: UseQueryOptions<ExamDetailledtoClient[], AxiosError>
) => {
  return useQuery({
    queryKey: keyFactory.exam.allDetailed(),
    queryFn: allExamsDetailled,
    staleTime: 1000 * 60 * 5,
    gcTime: 1000 * 60 * 5,
    ...props,
  });
};

// next exam number

const nextExamNumber = async (type: ExamType) => {
  return axios
    .get<string | null>(ENDPOINT.exam.getNextNumber(type))
    .then((res) => res.data);
};

export const useNextExamNumberQuery = (
  type: ExamType,
  props?: Partial<UseQueryOptions<string | null, AxiosError>>
) => {
  return useQuery({
    queryKey: keyFactory.exam.nextNumber(type),
    queryFn: () => nextExamNumber(type),
    staleTime: 1000 * 60 * 5,
    gcTime: 1000 * 60 * 5,
    ...props,
  });
};

// create exam

const create = async (body: ExamCreationDto) => {
  await axios.post<void>(ENDPOINT.exam.postCreate(), body);
};

export const useExamCreateMutation = (
  props?: Partial<UseMutationOptions<void, AxiosError, ExamCreationDto>>
) => {
  return useMutation({
    mutationFn: create,
    ...props,
  });
};

// create exam

const modify = async (body: ExamModificationDto) => {
  await axios.post<void>(ENDPOINT.exam.postModify(), body);
};

export const useExamModifyMutation = (
  props?: Partial<UseMutationOptions<void, AxiosError, ExamModificationDto>>
) => {
  return useMutation({
    mutationFn: modify,
    ...props,
  });
};

// registrations

const registrations = async (params: RegistrationsPaginationRequestDto) => {
  return axios
    .get<PaginationResponseDto<AdminRegistrationDtoClient>>(
      ENDPOINT.exam.getRegistrations(),
      {
        params,
      }
    )
    .then(({ data }) => data);
};

export const useRegistrationsQuery = (
  params: RegistrationsPaginationRequestDto,
  props?: Partial<
    UseQueryOptions<PaginationResponseDto<AdminRegistrationDtoClient>>
  >
) => {
  return useQuery({
    queryKey: keyFactory.exam.registrations(params),
    queryFn: () => registrations(params),
    staleTime: 1000 * 60 * 60,
    gcTime: 1000 * 60 * 5,
    ...props,
  });
};
