import { Checkbox } from "@/components/atoms/CheckBox";
import { cn } from "@/lib/utils";
import { ReactNode, useEffect, useRef, useState } from "react";

interface MustBeScrolledBoxProps {
  checked: boolean;
  setChecked: (b: boolean) => void;
  title: string;
  checkedText: string;
  children: ReactNode;
}

const MustBeScrolledBox = ({
  checked,
  setChecked,
  checkedText,
  children,
  title,
}: MustBeScrolledBoxProps) => {
  const [canCheck, setCanCheck] = useState(false);

  const contentRef = useRef<HTMLDivElement>(null);

  // Check if the user scrolled to the bottom
  const handleScroll = () => {
    if (contentRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
      if (scrollTop + clientHeight >= scrollHeight) {
        setCanCheck(true); // Enable the checkbox when scrolled to the bottom
      }
    }
  };

  useEffect(() => {
    const contentEl = contentRef.current;
    if (contentEl) {
      contentEl.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (contentEl) {
        contentEl.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  return (
    <>
      <div className="flex flex-col gap-3 mt-2 w-full">
        <span className="text-gray-700 text-sm font-medium">{title}</span>
        <div
          ref={contentRef}
          className="shadow-sm border border-gray-300 bg-white rounded-sm p-4 max-h-96 overflow-auto"
        >
          {children}
        </div>
      </div>
      <div
        className={cn("flex flex-row items-center gap-2", {
          "text-gray-700": checked,
        })}
      >
        <Checkbox
          disabled={!canCheck}
          checked={checked}
          onCheckedChange={(checked) => setChecked(!!checked)}
          className={cn("rounded-sm", {
            "bg-gray-50 border border-gray-300": !checked,
          })}
        />
        <span className={cn({ "opacity-40": !canCheck })}>{checkedText}</span>
        {!canCheck && (
          <span className="italic">Veuillez lire l'intégralité du texte</span>
        )}
      </div>
    </>
  );
};

export default MustBeScrolledBox;
