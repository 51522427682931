import { ExamType } from "@/api/enums/ExamType";
import { useNextExamNumberQuery } from "@/api/exam/exam";
import {
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/atoms/Form";
import { Input } from "@/components/atoms/Input";
import { Tabs, TabsList, TabsTrigger } from "@/components/atoms/Tabs";
import DatePicker from "@/components/molecules/DatePicker";
import useChildForm from "@/hooks/ChildForm";
import { useMemo } from "react";
import { ExamFormField } from "./exam-form-schema";

interface ExamFormProps {
  disabledFields?: ExamFormField[];
  hiddenFields?: ExamFormField[];
  currentNumber?: string;
  path?: string;
}

const ExamForm = ({
  path,
  currentNumber,
  hiddenFields = [],
  disabledFields = [],
}: ExamFormProps) => {
  const { control, transformPath, watch, formState } = useChildForm(path);

  const nextSessionQuery = useNextExamNumberQuery(watch(transformPath("type")));

  const type = watch(transformPath("type"));

  const currentNumberUpdated = useMemo(
    () =>
      currentNumber
        ? (type == ExamType.INITIAL ? "I" : "R") +
          Number(currentNumber.substring(1))
        : undefined,
    [type, currentNumber]
  );

  const globalErrors = useMemo(
    () => Object.entries(formState.errors).filter(([key]) => key === ""),
    [formState.errors]
  );

  return (
    <div className="flex flex-col gap-2 w-full">
      <h2>Informations session</h2>{" "}
      {!hiddenFields.includes(ExamFormField.Type) && (
        <FormField
          control={control}
          name="type"
          render={({ field }) => (
            <FormItem className="w-full">
              <Tabs
                value={field.value}
                onValueChange={
                  disabledFields.includes(ExamFormField.Type)
                    ? undefined
                    : field.onChange
                }
                className="w-full"
              >
                <TabsList className="w-full">
                  <TabsTrigger
                    value={ExamType.INITIAL}
                    type="button"
                    className="w-full"
                    disabled={
                      disabledFields.includes(ExamFormField.Type) &&
                      type === ExamType.RENEW
                    }
                  >
                    Initiale
                  </TabsTrigger>
                  <TabsTrigger
                    value={ExamType.RENEW}
                    type="button"
                    className="w-full"
                    disabled={
                      disabledFields.includes(ExamFormField.Type) &&
                      type === ExamType.INITIAL
                    }
                  >
                    Renouvellement
                  </TabsTrigger>
                </TabsList>
              </Tabs>
              <FormMessage />
            </FormItem>
          )}
        />
      )}
      <div className="flex flex-col gap-1.5">
        <span>Numéro d'examen</span>
        <Input
          name="examNumber"
          value={
            currentNumberUpdated ?? nextSessionQuery.data ?? "chargement..."
          }
          disabled={true}
        />
      </div>
      <div className="flex flex-row gap-2">
        {!hiddenFields.includes(ExamFormField.RegisteringStartDate) && (
          <FormField
            control={control}
            name="registeringStartDate"
            render={() => (
              <FormItem className="w-full">
                <FormLabel>Ouverture des inscriptions</FormLabel>
                <DatePicker
                  disabledDates={(date) => date < new Date()}
                  disabled={disabledFields.includes(
                    ExamFormField.RegisteringStartDate
                  )}
                />
                <FormMessage />
              </FormItem>
            )}
          />
        )}
        {!hiddenFields.includes(ExamFormField.RegisteringEndDate) && (
          <FormField
            control={control}
            name="registeringEndDate"
            render={() => (
              <FormItem className="w-full">
                <FormLabel>Clôture des inscriptions</FormLabel>
                <DatePicker
                  disabledDates={(date) => date < new Date()}
                  disabled={disabledFields.includes(
                    ExamFormField.RegisteringEndDate
                  )}
                />
                <FormMessage />
              </FormItem>
            )}
          />
        )}
        {!hiddenFields.includes(ExamFormField.FormationAttestationEndDate) && (
          <FormField
            control={control}
            name="formationCertificateEndDate"
            render={() => (
              <FormItem className="w-full">
                <FormLabel>Clôture att. formation</FormLabel>
                <DatePicker
                  disabledDates={(date) => date < new Date()}
                  disabled={disabledFields.includes(
                    ExamFormField.FormationAttestationEndDate
                  )}
                />
                <FormMessage />
              </FormItem>
            )}
          />
        )}
        {!hiddenFields.includes(ExamFormField.Date) && (
          <FormField
            control={control}
            name="date"
            render={() => (
              <FormItem className="w-full">
                <FormLabel>Examen</FormLabel>
                <DatePicker
                  disabledDates={(date) => date < new Date()}
                  disabled={disabledFields.includes(ExamFormField.Date)}
                />
                <FormMessage />
              </FormItem>
            )}
          />
        )}
      </div>
      {/* Display global error messages */}
      {globalErrors.length > 0 && (
        <div className="p-6 text-red-600">
          {globalErrors.map(([, error], i) => (
            <div key={i}>{error?.message as string}</div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ExamForm;
