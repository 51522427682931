import { ENDPOINT } from "@/constants/endpoints";
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import axios, { AxiosError, AxiosResponse } from "axios";
import { CertificateDto, CertificateDtoClient } from "../dto/certificate.dto";
import { keyFactory } from "../keyFactory";
import { CandidateInfoDtoClient } from "./dto/candidate-info.dto";
import { CandidateRegistrationDtoClient } from "./dto/candidate-registration.dto";
import { ChangeCandidateInfoDto } from "./dto/change-candidate-info.dto";

// info

const getInfo = async () => {
  return axios
    .get<CandidateInfoDtoClient>(ENDPOINT.candidate.getInfo())
    .then((res) => res.data);
};

export const useGetCandidateinfoQuery = (
  props?: Partial<UseQueryOptions<CandidateInfoDtoClient, AxiosError>>
) => {
  return useQuery({
    queryKey: keyFactory.candidate.info(),
    queryFn: getInfo,
    staleTime: Infinity,
    gcTime: Infinity,
    ...props,
  });
};

const changeInfo = async (data: ChangeCandidateInfoDto) => {
  return await axios.post(ENDPOINT.candidate.postChangeInfo(), data);
};

export const useChangeCandidateinfoMutation = (
  props?: UseMutationOptions<AxiosResponse, AxiosError, ChangeCandidateInfoDto>
) => {
  return useMutation({
    mutationFn: changeInfo,
    ...props,
  });
};

// certificates

const getCertificates = async () => {
  return axios
    .get<CertificateDtoClient[]>(ENDPOINT.candidate.getCertificates())
    .then((res) => res.data);
};

export const useCandidateCertificatesQuery = (
  props?: UseQueryOptions<CertificateDtoClient[], AxiosError>
) => {
  return useQuery({
    queryKey: keyFactory.candidate.certificates(),
    queryFn: getCertificates,
    staleTime: 1000 * 60 * 5,
    gcTime: 1000 * 60 * 5,
    ...props,
  });
};

// upload certificate

const addCertificate = async (data: CertificateDto) => {
  return axios
    .post<void>(ENDPOINT.candidate.postCertificateUpload(), data)
    .then((res) => res.data);
};

export const useAddCertificateMutation = (
  props?: Partial<UseMutationOptions<void, AxiosError, CertificateDto>>
) => {
  return useMutation({
    mutationFn: addCertificate,
    ...props,
  });
};

// registrations

const getRegistrations = async () => {
  return axios
    .get<CandidateRegistrationDtoClient[]>(
      ENDPOINT.candidate.getRegistrations()
    )
    .then((res) => res.data);
};

export const useRegistrationsQuery = (
  props?: UseQueryOptions<CandidateRegistrationDtoClient[], AxiosError>
) => {
  return useQuery({
    queryKey: keyFactory.candidate.registrations(),
    queryFn: getRegistrations,
    staleTime: Infinity,
    gcTime: Infinity,
    ...props,
  });
};
