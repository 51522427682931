import { cn } from "@/lib/utils";
import { FormControl, FormField } from "@atoms/Form";
import { Switch } from "@atoms/Switch";
import { SwitchProps } from "@radix-ui/react-switch";
import { useFormContext } from "react-hook-form";

interface GreenSwitchProps extends SwitchProps {
  name?: string;
  checked?: boolean;
  onCheckedModify?: (checked: boolean, field: any) => any;
  uncheckedAreRed?: boolean;
  uniqueName?: string;
}

const GreenSwitch = ({
  name = "",
  checked,
  onCheckedModify,
  children,
  uniqueName,
  uncheckedAreRed,
  ...props
}: GreenSwitchProps) => {
  const { control } = useFormContext();

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => {
        return (
          <div
            className={cn("flex p-4 w-full rounded-lg border justify-between", {
              "bg-green-100 border-green-200": checked,
              "bg-gray-25 border-gray-200": !checked && !uncheckedAreRed,
              "bg-red-100 border-red-200": !checked && uncheckedAreRed,
            })}
          >
            <div className="flex gap-2 items-center font-bold">{children}</div>
            <div className="flex gap-2 items-center">
              <FormControl>
                <Switch
                  {...props}
                  name={uniqueName}
                  checked={checked}
                  onCheckedChange={(checkedb) =>
                    field.onChange(
                      onCheckedModify
                        ? onCheckedModify(checkedb, field)
                        : field.value
                    )
                  }
                  className="data-[state=checked]:bg-green-500"
                />
              </FormControl>
              {checked ? (
                <span>Oui</span>
              ) : (
                <span className={cn({ "text-red-500": uncheckedAreRed })}>
                  Non
                </span>
              )}
            </div>
          </div>
        );
      }}
    />
  );
};

interface GreenSwitchArrayValueProps extends GreenSwitchProps {
  name?: string; // path of the array in the schema
  value?: string; // value to add or remove from array
  unique?: boolean; // must the array be of length 1 ?
  transform?: (val: string) => any; // used to parse to other type
}

const GreenSwitchArrayValue = ({
  name = "",
  value = "",
  unique = false,
  transform,
  ...props
}: GreenSwitchArrayValueProps) => {
  const { watch } = useFormContext();
  const array = watch(name);

  return (
    <GreenSwitch
      {...props}
      uniqueName={name + value}
      name={name}
      checked={array && array.includes(transform ? transform(value) : value)}
      onCheckedModify={(checked: boolean, field: any) => {
        const val = transform ? transform(value) : value;
        return checked
          ? unique
            ? [val]
            : [...field.value, val] // Add value
          : field.value.filter((item: string) => item !== val); // Remove value
      }}
    />
  );
};

export { GreenSwitch, GreenSwitchArrayValue };
export type { GreenSwitchArrayValueProps };
