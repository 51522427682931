import { Button } from "@atoms/Button";
import { LucideChevronLeft } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { ROUTE } from "../../../../constants/routes";

interface TopTextProps {
  hasCertificates: boolean;
}

const TopText = ({ hasCertificates }: TopTextProps) => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col gap-1 items-start">
      <Button
        className="text-gray-800 gap-1.5 text-xs pl-2.5"
        variant="ghostWithBorders"
        onClick={() =>
          navigate(`${ROUTE.connectionHome}`, {
            state: {
              tab: "register",
              hasCertificates,
            },
          })
        }
      >
        <LucideChevronLeft />
        Retour à la page précédente
      </Button>
      <h1 className="mt-1.5">Création du compte</h1>
      <p className="mt-1">
        Complétez les différents champs pour la création de votre compte.
        <span className="text-brand-600 font-bold">
          {" "}
          Les champs marqués d'un astérisque *{" "}
        </span>
        figureront sur votre certificat : ils doivent être soigneusement
        vérifiés et correctement renseignés.
      </p>
    </div>
  );
};

export default TopText;
