import { CandidateRegistrationDtoClient } from "@/api/candidate/dto/candidate-registration.dto";
import DelegationStatusBadge from "@/components/molecules/Badges/DelegationStatusBadge";
import { Card, CardTitle } from "@/components/molecules/RegistrationCard/Card";

interface DelegationCardProps {
  registration: CandidateRegistrationDtoClient;
}

const DelegationCard = ({
  registration: { delegation },
}: DelegationCardProps) => {
  if (!delegation) return false;

  return (
    <Card>
      <div className="flex flex-row justify-between w-full items-center">
        <CardTitle>Délégation</CardTitle>
        <div className="flex flex-col gap-1">
          <div className="flex flex-row gap-2 items-center">
            <span className="text-gray-900 font-medium">{`${delegation.company.personSurname} ${delegation.company.personName}`}</span>
            <DelegationStatusBadge delegation={delegation} />
          </div>
          <div className="flex flex-row w-full text-gray-500 gap-3 text-xs ">
            <span>{delegation.company.name}</span>
            <span>{delegation.company.email}</span>
            <span>{delegation.company.phoneNumber}</span>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default DelegationCard;
