import { RegistrationType } from "@/api/enums/RegistrationType";
import { FormControl, FormField, FormItem, FormLabel } from "@atoms/Form";
import { RadioGroup, RadioGroupItem } from "@atoms/RadioGroup";
import { useFormContext } from "react-hook-form";

// TODO: don't propose renewing or extension if there is no valid certificate

const RegistrationTypeRadio = () => {
  const { control } = useFormContext();

  return (
    <div>
      <h2>Type d'examen</h2>
      <FormField
        control={control}
        name="type"
        render={({ field }) => (
          <FormItem className="w-full">
            <FormControl>
              <RadioGroup
                name={field.name}
                defaultValue={field.value}
                onValueChange={field.onChange}
                className="flex flex-row gap-16 w-full mt-1"
              >
                {[
                  {
                    value: RegistrationType.INITIAL,
                    msg: "Initial",
                  },
                  {
                    value: RegistrationType.RENEW,
                    msg: "Renouvellement d'un certificat",
                  },
                  {
                    value: RegistrationType.EXTENSION,
                    msg: "Extension d'un certificat valide",
                  },
                ].map(({ value, msg }) => {
                  const itemId = `${field.name}${value}`;

                  return (
                    <div className="flex items-center self-stretch" key={value}>
                      <RadioGroupItem
                        id={itemId}
                        value={value}
                        className="flex items-center justify-center gap-2"
                      />
                      <FormLabel htmlFor={itemId}>{msg}</FormLabel>
                    </div>
                  );
                })}
              </RadioGroup>
            </FormControl>
          </FormItem>
        )}
      />
    </div>
  );
};

export default RegistrationTypeRadio;
