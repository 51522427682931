import { FormField, FormItem, FormLabel, FormMessage } from "@atoms/Form";
import DatePicker from "@molecules/DatePicker";
import { useFormContext } from "react-hook-form";
import FieldWithOptionnality from "./Formatters/FieldWithOptionnality";
import { FieldMandatoryType } from "./Formatters/mandatory-type";
import { Modifiers, PersonnalInfoField } from "./PersonnalInfoFields";

interface BirthDatePickerProps {
  modifiers: Modifiers;
}

const BirthDatePicker = ({
  modifiers: { disabledFields, hiddenFields },
}: BirthDatePickerProps) => {
  const { control } = useFormContext();

  if (hiddenFields.includes(PersonnalInfoField.Birthdate)) return <></>;

  return (
    <FormField
      control={control}
      name="personnalInfo.birthdate"
      render={() => (
        <FormItem className="w-full">
          <FormLabel>
            <FieldWithOptionnality
              str="Date de naissance"
              fieldMandatoryType={FieldMandatoryType.MANDATORY_ON_CERTIFICATE}
            />
          </FormLabel>
          <DatePicker
            disabled={disabledFields.includes(PersonnalInfoField.Birthdate)}
            disabledDates={(date) =>
              date >
              new Date(new Date().getTime() - 18 * 365 * 24 * 60 * 60 * 1000)
            }
          />
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export default BirthDatePicker;
