import { useUnregisterMutation } from "@/api/admin/admin";
import { CandidateRegistrationDtoClient } from "@/api/candidate/dto/candidate-registration.dto";
import { keyFactory } from "@/api/keyFactory";
import { Button } from "@/components/atoms/Button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/atoms/Popover";
import ConfirmDialog from "@/components/molecules/ConfirmDialog";
import { useQueryClient } from "@tanstack/react-query";
import { Calendar, ChevronRight, EllipsisVertical, X } from "lucide-react";
import { useState } from "react";
import { toast } from "sonner";
import ReportDialog from "./ReportDialog";

interface ActionsButtonProps {
  registration: CandidateRegistrationDtoClient;
  candidateId: number;
}

const ActionsButton = ({ registration, candidateId }: ActionsButtonProps) => {
  const [unregisterDialogOpen, setUnregisterDialogOpen] = useState(false);
  const [reportDialogOpen, setReportDialogOpen] = useState(false);
  const queryClient = useQueryClient();

  const unregisterMutation = useUnregisterMutation();

  return (
    <>
      <ReportDialog
        close={() => setReportDialogOpen(false)}
        isOpen={reportDialogOpen}
        registration={registration}
        candidateId={candidateId}
      />
      <ConfirmDialog
        isOpen={unregisterDialogOpen}
        close={() => setUnregisterDialogOpen(false)}
        title="Désinscription"
        message={`Êtes-vous sûr de vouloir désinscrire ce candidat de la session du ${new Date(
          registration.exam.date
        ).toLocaleDateString("fr-FR")} ?`}
        validateStr="Oui, désinscrire"
        validateButtonVariant="red"
        cancelStr="Non, annuler"
        validatePending={unregisterMutation.isPending}
        onResult={(accepted) => {
          if (accepted) {
            unregisterMutation.mutate(
              {
                registrationId: registration.id,
                candidateId,
              },
              {
                onSuccess: () => {
                  toast.success("Candidat désinscrit");
                  queryClient.invalidateQueries({
                    queryKey:
                      keyFactory.admin.candidate.ongoingRegistration(
                        candidateId
                      ),
                  });
                  queryClient.invalidateQueries({
                    queryKey: keyFactory.admin.candidate.basicInfo(candidateId),
                  });
                  setUnregisterDialogOpen(false);
                },
                onError: () => {
                  toast.error("Echèc de la requête", {
                    description:
                      "Votre action n'a pas pu être prise en compte.",
                  });
                },
              }
            );
          } else setUnregisterDialogOpen(false);
        }}
      />
      <Popover>
        <PopoverTrigger className="flex flex-row items-center bg-brand-600 hover:bg-brand-800 text-white rounded-lg p-2">
          Actions <EllipsisVertical className="w-5 h-5" />
        </PopoverTrigger>
        <PopoverContent className="flex flex-col p-1" align="end">
          <Button
            variant="ghost"
            className="flex flex-row items-center justify-between w-full pl-2 pr-0"
            onClick={() => setUnregisterDialogOpen(true)}
          >
            <div className="flex flex-row items-center gap-2">
              <X className="w-4 h-4" />
              Désinscrire de la session
            </div>
            <ChevronRight />
          </Button>
          <Button
            variant="ghost"
            className="flex flex-row items-center justify-between w-full pl-2 pr-0"
            onClick={() => setReportDialogOpen(true)}
          >
            <div className="flex flex-row items-center gap-2">
              <Calendar className="w-4 h-4" />
              Reporter l'inscription
            </div>
            <ChevronRight />
          </Button>
        </PopoverContent>
      </Popover>
    </>
  );
};

export default ActionsButton;
