import { CandidateBasicPaginationRequestDto } from "./admin/dto/candidate.dto";
import { ForeignCertificatesPaginationRequestDto } from "./certificate/dto/foreign-certificates-paginated.dto";
import { ExamType } from "./enums/ExamType";
import { RegistrationsPaginationRequestDto } from "./exam/dto/registrations.dto";

const keyFactory = {
  hello: () => ["hello"],
  role: () => ["role"],
  file: (key: string) => ["file", key],

  auth: {
    base: () => ["auth"],
    role: () => keyFactory.auth.base().concat(["role"]),
    register: {
      base: () => keyFactory.auth.base().concat(["register"]),
      company: {
        base: () => keyFactory.auth.register.base().concat(["company"]),
        getPrefilledInfo: () =>
          keyFactory.auth.register.company.base().concat(["getPrefilledInfo"]),
      },
    },
  },

  candidate: {
    base: () => ["candidate"],
    info: () => keyFactory.candidate.base().concat(["info"]),
    certificates: () => keyFactory.candidate.base().concat(["certificates"]),
    registrations: () => keyFactory.candidate.base().concat(["registrations"]),
  },

  company: {
    base: () => ["company"],
    info: () => keyFactory.company.base().concat(["info"]),
    delegations: () => keyFactory.company.base().concat(["delegations"]),
  },

  examRegistration: {
    base: () => ["examRegistration"],
    options: () => keyFactory.examRegistration.base().concat(["options"]),
    canRegister: () =>
      keyFactory.examRegistration.base().concat(["canRegister"]),
  },

  exam: {
    base: () => ["exam"],
    all: () => keyFactory.exam.base().concat(["all"]),
    allDetailed: () => keyFactory.exam.all().concat(["detailed"]),
    nextNumber: (type: ExamType) =>
      keyFactory.exam.base().concat(["nextNumber", type]),
    registrations: ({
      examId,
      pageIndex,
      searchText,
      status,
    }: RegistrationsPaginationRequestDto) =>
      keyFactory.exam
        .base()
        .concat([
          "registrations",
          examId.toString(),
          pageIndex.toString(),
          searchText ?? "",
          ...(status ?? []),
        ]),
  },

  formationInstitutes: {
    base: () => ["formationInstitutes"],
    all: () => keyFactory.formationInstitutes.base().concat(["all"]),
  },

  admin: {
    base: () => ["admin"],
    candidates: ({
      pageIndex,
      status,
      searchText,
    }: CandidateBasicPaginationRequestDto) =>
      keyFactory.admin
        .base()
        .concat(["all", pageIndex.toString(), status ?? "", searchText ?? ""]),
    candidate: {
      base: (candidateId: number) =>
        keyFactory.admin.base().concat(["candidate", candidateId.toString()]),
      registrations: (candidateId: number) =>
        keyFactory.admin.candidate.base(candidateId).concat(["registrations"]),
      certificates: (candidateId: number) =>
        keyFactory.admin.candidate.base(candidateId).concat(["certificates"]),
      ongoingRegistration: (candidateId: number) =>
        keyFactory.admin.candidate
          .base(candidateId)
          .concat(["lastRegistration"]),
      basicInfo: (candidateId: number) =>
        keyFactory.admin.candidate.base(candidateId).concat(["basicInfo"]),
      registration: (candidateId: number, registrationId: number) =>
        keyFactory.admin.candidate
          .base(candidateId)
          .concat([
            "registration",
            candidateId.toString(),
            registrationId.toString(),
          ]),
      info: (candidateId: number) =>
        keyFactory.admin.candidate.base(candidateId).concat(["info"]),
    },
    foreignCertificates: {
      base: () => keyFactory.admin.base().concat(["foreign-certificates"]),
      all: (params: ForeignCertificatesPaginationRequestDto) =>
        keyFactory.admin.foreignCertificates
          .base()
          .concat([
            params.pageIndex.toString(),
            params.status ?? "",
            params.searchText ?? "",
          ]),
    },
  },
};

export { keyFactory };
