import {
  useCandidateBasicInfo,
  useFormationAttestationStatusMutation,
} from "@/api/admin/admin";
import { FormationCertificateDtoClient } from "@/api/dto/formation-certificate.dto";
import { keyFactory } from "@/api/keyFactory";
import { Badge } from "@/components/atoms/Badge";
import { Button } from "@/components/atoms/Button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
} from "@/components/atoms/Dialog";
import FormationAttestationDisplay from "@/components/molecules/FormationAttestationsForm/FormationAttestationDisplay";
import { LoadingSpinner } from "@/components/molecules/LoadingSpinner";
import { Perimeter, PerimeterUtil } from "@/lib/perimeter-util";
import { DialogTitle } from "@radix-ui/react-dialog";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "sonner";

interface FormationAttestationDialogProps {
  isOpen: boolean;
  close: () => void;
  candidateId: number;
  registrationId: number;
  formationAttestation: FormationCertificateDtoClient;
  examPerimeter: Perimeter;
}

const FormationAttestationDialog = ({
  isOpen,
  close,
  candidateId,
  registrationId,
  formationAttestation,
  examPerimeter,
}: FormationAttestationDialogProps) => {
  const { data, isLoading, isError } = useCandidateBasicInfo(candidateId);
  const queryClient = useQueryClient();

  const statusMutation = useFormationAttestationStatusMutation({
    onSuccess: (_data, { validated }) => {
      toast.success(
        "Attestation de formation " + validated ? "validée" : "refusée"
      );
      queryClient.invalidateQueries({
        queryKey: keyFactory.admin.candidate.ongoingRegistration(candidateId),
      });
      close();
    },
    onError: () => {
      console.error("Echèc de la requête", {
        description: "Votre action n'a pas pu être prise en compte.",
      });
    },
  });

  const mutate = (validated: boolean) =>
    statusMutation.mutate({
      attestationId: formationAttestation.id,
      validated,
      candidateId,
      registrationId,
    });

  if (!data) return <LoadingSpinner isLoading={isLoading} isError={isError} />;
  if (data === "id-not-found") return false;

  return (
    <Dialog open={isOpen}>
      <DialogContent onClose={close} className="p-4 max-w-3xl">
        <DialogHeader>
          <DialogTitle>
            {data.firstName} {data.lastName} - Attestation de formation
          </DialogTitle>
          <DialogDescription hidden={true}>
            Vérification d'une attestation de formation
          </DialogDescription>
        </DialogHeader>
        <div className="flex flex-row items-center gap-4">
          <span>Périmètre de l'examen</span>
          <Badge variant="white">{PerimeterUtil.toCode(examPerimeter)}</Badge>
        </div>
        <div className="flex flex-col w-full gap-4">
          <FormationAttestationDisplay attestation={formationAttestation} />
          <div className="flex flex-row gap-4">
            <Button
              variant="red"
              onClick={() => mutate(false)}
              className="w-1/2"
            >
              Refuser
            </Button>
            <Button
              variant="green"
              onClick={() => mutate(true)}
              className="w-1/2"
            >
              Valider
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default FormationAttestationDialog;
