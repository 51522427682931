import { Check, Copy } from "lucide-react";
import { useState } from "react";

const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text).catch((err) => {
    console.error("Failed to copy text:", err);
  });
};

// Static variable to track the currently active copied component
let currentlyCopied: (() => void) | null = null;

interface CopyableInputProps {
  text: string;
}

const CopyableInput = ({ text }: CopyableInputProps) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    // If another component is in the copied state, reset it
    if (currentlyCopied && currentlyCopied !== resetCopiedState) {
      currentlyCopied();
    }

    copyToClipboard(text);
    setIsCopied(true);
    currentlyCopied = resetCopiedState;

    // Automatically reset the state after a delay
    setTimeout(() => {
      if (currentlyCopied === resetCopiedState) {
        resetCopiedState();
      }
    }, 4000);
  };

  const resetCopiedState = () => {
    setIsCopied(false);
    currentlyCopied = null;
  };

  return (
    <div className="flex flex-row gap-2 text-gray-700 w-full border border-gray-300 rounded-md bg-gray-50 py-2 px-3 self-stretch">
      <span className="truncate flex-1 text-gray-500">{text}</span>
      <button onClick={handleCopy}>
        {isCopied ? (
          <Check className="w-5 h-5 text-green-500" />
        ) : (
          <Copy className="w-5 h-5" />
        )}
      </button>
    </div>
  );
};

export default CopyableInput;
