import { ENDPOINT } from "@/constants/endpoints";
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { CandidateInfoDtoClient } from "../candidate/dto/candidate-info.dto";
import { CandidateRegistrationDtoClient } from "../candidate/dto/candidate-registration.dto";
import { ForeignCertificateStatusDto } from "../certificate/dto/foreign-certificate-status.dto";
import {
  ForeignCertificateDtoClient,
  ForeignCertificatesPaginationRequestDto,
} from "../certificate/dto/foreign-certificates-paginated.dto";
import { CertificateDtoClient } from "../dto/certificate.dto";
import { PaginationResponseDto } from "../dto/pagination.dto";
import { keyFactory } from "../keyFactory";
import { TransferStatusDto } from "./dto/accept-transfer.dto";
import { AddProrogationDto } from "./dto/add-prorogation.dto";
import { AdminChangeCandidateInfoDto } from "./dto/admin-change-candidate-info.dto";
import { AdminRegistrationIdentifier } from "./dto/admin-registration-identifier.dto";
import { CandidateRegistrationBasicDtoClient } from "./dto/candidate-registration-basic.dto";
import {
  CandidateBasicDtoClient,
  CandidateBasicPaginationRequestDto,
} from "./dto/candidate.dto";
import {
  FormationAttestationsGlobalStatusDto,
  FormationAttestationStatusDto,
} from "./dto/formation-attestation-status.dto";
import { FormationAttestationsUploadDto } from "./dto/formation-attestation-upload.dto";
import { PreTestStatusDto } from "./dto/pre-test-status.dto";
import { ReducedPriceDocumentStatusDto } from "./dto/reduced-price-document-status.dto";
import { ReducedPriceDocumentUploadDto } from "./dto/reduced-price-document-upload.dto";
import { ReportRegistrationDto } from "./dto/report-registration.dto";

// all candidates basic info (paginated)

const getCandidates = async (params: CandidateBasicPaginationRequestDto) => {
  return await axios
    .get<PaginationResponseDto<CandidateBasicDtoClient>>(
      ENDPOINT.admin.candidates.getAll(),
      {
        params,
      }
    )
    .then(({ data }) => data);
};

export const useCandidatesQuery = (
  params: CandidateBasicPaginationRequestDto,
  props?: Partial<
    UseQueryOptions<PaginationResponseDto<CandidateBasicDtoClient>>
  >
) => {
  return useQuery({
    queryKey: keyFactory.admin.candidates(params),
    queryFn: () => getCandidates(params),
    staleTime: Infinity,
    gcTime: 1000 * 60,
    ...props,
  });
};

// one candidate basic info

const getCandidateBasicInfo = async (candidateId: number) => {
  return await axios
    .get<CandidateBasicDtoClient | "id-not-found">(
      ENDPOINT.admin.candidate.getBasicInfo(candidateId)
    )
    .then(({ data }) => data);
};

export const useCandidateBasicInfo = (
  candidateId: number,
  props?: Partial<UseQueryOptions<CandidateBasicDtoClient | "id-not-found">>
) => {
  return useQuery({
    queryKey: keyFactory.admin.candidate.basicInfo(candidateId),
    queryFn: () => getCandidateBasicInfo(candidateId),
    staleTime: Infinity,
    gcTime: 1000 * 60,
    ...props,
  });
};

// candidate last registration

const getCandidatesOnGoingRegistration = async (candidateId: number) => {
  return await axios
    .get<CandidateRegistrationDtoClient | "no-registration">(
      ENDPOINT.admin.candidate.getOngoingRegistration(candidateId)
    )
    .then(({ data }) => data);
};

export const useCandidatesLastRegistrationQuery = (
  candidateId: number,
  props?: Partial<
    UseQueryOptions<CandidateRegistrationDtoClient | "no-registration">
  >
) => {
  return useQuery({
    queryKey: keyFactory.admin.candidate.ongoingRegistration(candidateId),
    queryFn: () => getCandidatesOnGoingRegistration(candidateId),
    staleTime: Infinity,
    gcTime: 1000 * 60,
    ...props,
  });
};

// candidate delete

const candidateDelete = async (candidateId: number) => {
  await axios.post(ENDPOINT.admin.candidate.postDelete(candidateId));
};

export const useCandidateDeleteMutation = (
  props?: Partial<UseMutationOptions<void, AxiosError, number>>
) => {
  return useMutation({
    mutationFn: candidateDelete,
    ...props,
  });
};

// formation attestation status

const formationAttestationStatus = async (
  body: FormationAttestationStatusDto
) => {
  await axios.post<void>(
    ENDPOINT.admin.candidate.registration.formationAttestation.postStatus(),
    body
  );
};

export const useFormationAttestationStatusMutation = (
  props?: Partial<
    UseMutationOptions<void, AxiosError, FormationAttestationStatusDto>
  >
) => {
  return useMutation({
    mutationFn: formationAttestationStatus,
    ...props,
  });
};

// global formation attestations status

const globalFormationAttestationStatus = async (
  body: FormationAttestationsGlobalStatusDto
) => {
  await axios.post<void>(
    ENDPOINT.admin.candidate.registration.formationAttestation.postGlobalStatus(),
    body
  );
};

export const useGlobalFormationAttestationStatusMutation = (
  props?: Partial<
    UseMutationOptions<void, AxiosError, FormationAttestationsGlobalStatusDto>
  >
) => {
  return useMutation({
    mutationFn: globalFormationAttestationStatus,
    ...props,
  });
};

// formation attestation upload

const formationAttestationUpload = async (
  data: FormationAttestationsUploadDto
) => {
  await axios.post<void>(
    ENDPOINT.admin.candidate.registration.formationAttestation.postUpload(),
    data
  );
};

export const useFormationAttestationUploadMutation = (
  props?: Partial<
    UseMutationOptions<void, AxiosError, FormationAttestationsUploadDto>
  >
) => {
  return useMutation({
    mutationFn: formationAttestationUpload,
    ...props,
  });
};

// reduced price document upload

const reducedPriceDocumentUpload = async (
  body: ReducedPriceDocumentUploadDto
) => {
  await axios.post<void>(
    ENDPOINT.admin.candidate.registration.reducedPrice.postUpload(),
    body
  );
};

export const useReducedPriceDocumentUploadMutation = (
  props?: Partial<
    UseMutationOptions<void, AxiosError, ReducedPriceDocumentUploadDto>
  >
) => {
  return useMutation({
    mutationFn: reducedPriceDocumentUpload,
    ...props,
  });
};

// reduced price document status

const reducedPriceDocumentStatus = async (
  body: ReducedPriceDocumentStatusDto
) => {
  await axios.post<void>(
    ENDPOINT.admin.candidate.registration.reducedPrice.postStatus(),
    body
  );
};

export const useReducedPriceDocumentStatusMutation = (
  props?: Partial<
    UseMutationOptions<void, AxiosError, ReducedPriceDocumentStatusDto>
  >
) => {
  return useMutation({
    mutationFn: reducedPriceDocumentStatus,
    ...props,
  });
};

// payement transfer status

const payementTransferStatus = async (body: TransferStatusDto) => {
  await axios.post<void>(
    ENDPOINT.admin.candidate.registration.payement.postTransferStatus(),
    body
  );
};

export const useTransferStatusMutation = (
  props?: Partial<UseMutationOptions<void, AxiosError, TransferStatusDto>>
) => {
  return useMutation({
    mutationFn: payementTransferStatus,
    ...props,
  });
};

// pre-test status

const preTestStatus = async (body: PreTestStatusDto) => {
  await axios.post<void>(
    ENDPOINT.admin.candidate.registration.postPreTestStatus(),
    body
  );
};

export const usePreTestStatusMutation = (
  props?: Partial<UseMutationOptions<void, AxiosError, PreTestStatusDto>>
) => {
  return useMutation({
    mutationFn: preTestStatus,
    ...props,
  });
};

// unregister

const unregister = async (body: AdminRegistrationIdentifier) => {
  await axios.post<void>(
    ENDPOINT.admin.candidate.registration.postUnregister(),
    body
  );
};

export const useUnregisterMutation = (
  props?: Partial<
    UseMutationOptions<void, AxiosError, AdminRegistrationIdentifier>
  >
) => {
  return useMutation({
    mutationFn: unregister,
    ...props,
  });
};

// report registration

const reportRegistration = async (body: ReportRegistrationDto) => {
  await axios.post<void>(
    ENDPOINT.admin.candidate.registration.postReport(),
    body
  );
};

export const useReportRegistrationMutation = (
  props?: Partial<UseMutationOptions<void, AxiosError, ReportRegistrationDto>>
) => {
  return useMutation({
    mutationFn: reportRegistration,
    ...props,
  });
};

// get registrations

const registrations = async (candidateId: number) => {
  return axios
    .get<CandidateRegistrationBasicDtoClient[]>(
      ENDPOINT.admin.candidate.getRegistrations(candidateId)
    )
    .then(({ data }) => data);
};

export const useRegistrationsQuery = (
  candidateId: number,
  props?: Partial<UseQueryOptions<CandidateRegistrationBasicDtoClient[]>>
) => {
  return useQuery({
    queryKey: keyFactory.admin.candidate.registrations(candidateId),
    queryFn: () => registrations(candidateId),
    staleTime: 1000 * 60 * 60,
    gcTime: 1000 * 60,
    ...props,
  });
};

// get certificates

const certificates = async (candidateId: number) => {
  return axios
    .get<CertificateDtoClient[]>(
      ENDPOINT.admin.candidate.getCertificates(candidateId)
    )
    .then(({ data }) => data);
};

export const useCertificatesQuery = (
  candidateId: number,
  props?: Partial<UseQueryOptions<CertificateDtoClient[]>>
) => {
  return useQuery({
    queryKey: keyFactory.admin.candidate.certificates(candidateId),
    queryFn: () => certificates(candidateId),
    staleTime: 1000 * 60 * 60,
    gcTime: 1000 * 60,
    ...props,
  });
};

// get one registration

const registration = async (candidateId: number, registrationId: number) => {
  return axios
    .get<CandidateRegistrationDtoClient>(
      ENDPOINT.admin.candidate.registration.get(candidateId, registrationId)
    )
    .then(({ data }) => data);
};

export const useRegistrationQuery = (
  candidateId: number,
  registrationId: number,
  props?: Partial<UseQueryOptions<CandidateRegistrationDtoClient>>
) => {
  return useQuery({
    queryKey: keyFactory.admin.candidate.registration(
      candidateId,
      registrationId
    ),
    queryFn: () => registration(candidateId, registrationId),
    ...props,
  });
};

// get candidate info

const candidateInfo = async (candidateId: number) => {
  return axios
    .get<CandidateInfoDtoClient>(ENDPOINT.admin.candidate.info.get(candidateId))
    .then((res) => res.data);
};

export const useCandidateInfoQuery = (
  candidateId: number,
  props?: Partial<UseQueryOptions<CandidateInfoDtoClient>>
) => {
  return useQuery({
    queryKey: keyFactory.admin.candidate.info(candidateId),
    queryFn: () => candidateInfo(candidateId),
    gcTime: 1000 * 60,
    staleTime: 1000 * 60 * 60,
    ...props,
  });
};

// modify candidate info

const modifyCandidateInfo = async (body: AdminChangeCandidateInfoDto) => {
  await axios
    .post<void>(ENDPOINT.admin.candidate.info.postModify(), body)
    .then();
};

export const useModifyCandidateInfoMutation = (
  props?: Partial<
    UseMutationOptions<void, AxiosError, AdminChangeCandidateInfoDto>
  >
) => {
  return useMutation({
    mutationFn: modifyCandidateInfo,
    ...props,
  });
};

// certificate add prorogation

const certificateProrogationAdd = async (body: AddProrogationDto) => {
  await axios.post<void>(
    ENDPOINT.admin.candidate.certificate.prorogation.postAdd(),
    body
  );
};

export const useCertificateProrogationAddMutation = (
  props?: Partial<UseMutationOptions<void, AxiosError, AddProrogationDto>>
) => {
  return useMutation({
    mutationFn: certificateProrogationAdd,
    ...props,
  });
};

// certificate remove prorogation

type ProrogationRemoveDto = {
  certificateId: number;
};

const certificateProrogationRemove = async (body: ProrogationRemoveDto) => {
  await axios.post<void>(
    ENDPOINT.admin.candidate.certificate.prorogation.postRemove(),
    body
  );
};

export const useCertificateProrogationRemoveMutation = (
  props?: Partial<UseMutationOptions<void, AxiosError, ProrogationRemoveDto>>
) => {
  return useMutation({
    mutationFn: certificateProrogationRemove,
    ...props,
  });
};

// foreign certificates get all

const foreignCertificates = async (
  params: ForeignCertificatesPaginationRequestDto
) => {
  return axios
    .get<PaginationResponseDto<ForeignCertificateDtoClient>>(
      ENDPOINT.admin.foreignCertificates.getAll(),
      {
        params,
      }
    )
    .then(({ data }) => data);
};

export const useForeignCertificatesQuery = (
  params: ForeignCertificatesPaginationRequestDto,
  props?: Partial<
    UseQueryOptions<PaginationResponseDto<ForeignCertificateDtoClient>>
  >
) => {
  return useQuery<PaginationResponseDto<ForeignCertificateDtoClient>>({
    queryKey: keyFactory.admin.foreignCertificates.all(params),
    queryFn: () => foreignCertificates(params),
    ...props,
  });
};

// foreign certificates set status

const foreignCertificatesSetStatus = async (
  body: ForeignCertificateStatusDto
) => {
  await axios.post<void>(
    ENDPOINT.admin.foreignCertificates.postSetStatus(),
    body
  );
};

export const useForeignCertificatesSetStatusMutation = (
  props?: Partial<
    UseMutationOptions<void, AxiosError, ForeignCertificateStatusDto>
  >
) => {
  return useMutation({
    mutationFn: foreignCertificatesSetStatus,
    ...props,
  });
};
