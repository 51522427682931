import logo from "@/assets/logo.png";
import useRoleBasedRedirect from "@/hooks/RoleBasedRedirect";
import { Button } from "@atoms/Button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@atoms/Form";
import { Input } from "@atoms/Input";
import { zodResolver } from "@hookform/resolvers/zod";
import BackButton from "@molecules/BackButton";
import ErrorDisplay from "@molecules/ErrorDisplay";
import { HttpStatusCode } from "axios";
import { ChevronLeft } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { z } from "zod";
import { useAskChangePasswordMutation } from "../../../api/auth/auth";
import { ROUTE } from "../../../constants/routes";
import { email } from "../../../constants/zodTypes";

const askResetPasswordSchema = z.object({
  email: email(),
});

const ForgottenPassword = () => {
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState("");
  useRoleBasedRedirect(null);

  const form = useForm<z.infer<typeof askResetPasswordSchema>>({
    resolver: zodResolver(askResetPasswordSchema),
    defaultValues: {
      email: "",
    },
  });

  const control = form.control;

  const askChangePasswordMutation = useAskChangePasswordMutation({
    onSuccess: async () => {
      navigate(ROUTE.connectionHome());

      toast.success("Demande bien reçue.", {
        description:
          "Si votre email est associée à un compte, vous pouvez maintenant changer votre mot de passe en cliquant sur le lien reçu par email.",
      });
    },
    onError: (error) => {
      switch (error.status) {
        case HttpStatusCode.Conflict:
          setErrorMsg("Cet email n'est pas associé à un compte.");
          return;

        case HttpStatusCode.InternalServerError:
          setErrorMsg(
            "Le serveur a rencontré une erreur lors de la réinitialisation du mot de passe."
          );
          return;

        default:
          setErrorMsg(
            "Une erreur est survenue lors de la réinitialisation du mot de passe."
          );
          return;
      }
    },
  });

  function onSubmit(values: z.infer<typeof askResetPasswordSchema>) {
    const { data, error, success } = askResetPasswordSchema.safeParse(values);

    if (!success) {
      console.error("can't parse form, aborting :", error);
      return;
    }

    askChangePasswordMutation.mutate({
      email: data.email,
    });
  }

  return (
    <div className="flex flex-col items-center mt-16 h-screen">
      <BackButton />
      <img
        className="flex justify-center items-center mb-12"
        src={logo}
        alt="Description of the image"
      />
      <div className="flex w-96 flex-col items-start p-10 gap-6 rounded-lg border border-gray-200 bg-white shadow-sm">
        <button
          className="bg-transparent text-black hover:text-gray-500 hover:bg-transparent justify-start items-center"
          onClick={() => navigate(-1)}
        >
          <ChevronLeft className="w-6 h-6 pl-0 ml-0" />
        </button>
        <h1>Mot de passe oublié</h1>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex w-full flex-col space-y-6"
          >
            <FormField
              control={control}
              name="email"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>Adresse email</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={"mail@mail.com"}
                      {...field}
                      autoComplete="email"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex items-center flex-col justify-center gap-2 self-stretch">
              <Button
                className="flex px-1 py-2 mt-3 justify-center gap-1 self-stretch rounded-lg border border-[#1D64D8] bg-[#2570EB] font-semibold text-base"
                type="submit"
                disabled={askChangePasswordMutation.isPending}
              >
                {askChangePasswordMutation.isPending
                  ? "Demande en cours..."
                  : "Envoyer"}
              </Button>
              <div className="flex flex-col self-stretch mt-4">
                {errorMsg && <ErrorDisplay msg={errorMsg} />}
              </div>
            </div>
          </form>
        </Form>
      </div>
    </div>
  );
};

export default ForgottenPassword;
