"use client";

import { cn } from "@/lib/utils";
import { Button } from "@atoms/Button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@atoms/Command";
import { useFormField } from "@atoms/Form";
import { Popover, PopoverContent, PopoverTrigger } from "@atoms/Popover";
import { Check, ChevronDown } from "lucide-react";
import * as React from "react";

type Option<T> = {
  value: T;
  label: string;
  disabled?: boolean;
  disabledMessage?: string;
};

interface ComboboxFieldProps<T> {
  options: Option<T>[];
  placeholder: string;
  id?: string;
  optionnal?: boolean;
  disabled?: boolean;
  className?: string;
  contentWidth?: number;
  displaySearchBox?: boolean;
  stringifyValue?: (value: T) => string; // Function to convert value to string
}

export const ComboboxField = <T,>({
  options,
  placeholder,
  disabled = false,
  id,
  optionnal = false,
  className = "",
  contentWidth = -1,
  displaySearchBox = true,
  stringifyValue = (value) => String(value), // Default stringification
}: ComboboxFieldProps<T>) => {
  const [open, setOpen] = React.useState(false);
  const { error, value, onChange } = useFormField();

  return (
    <Popover open={open} onOpenChange={setOpen} modal={true}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className={cn(
            "w-full justify-between disabled:cursor-not-allowed disabled:border-gray-300 disabled:bg-gray-50 disabled:opacity-100",
            error ? "border-red-500 border" : "border-gray-300",
            "max-w-full overflow-hidden text-ellipsis whitespace-nowrap",
            className
          )}
          id={id}
          disabled={disabled}
        >
          {" "}
          <span className="overflow-hidden text-ellipsis whitespace-nowrap max-w-[calc(100%-2rem)]">
            {value
              ? options.find(
                  (option) =>
                    stringifyValue(option.value) === stringifyValue(value)
                )?.label
              : placeholder}
          </span>
          <ChevronDown className="ml-2 h-5 w-5 shrink-0" />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className={cn(
          contentWidth === -1
            ? "w-[--radix-popover-trigger-width] max-h-[--radix-popover-content-available-height]"
            : `w-[${contentWidth}px]`,
          "p-0"
        )}
      >
        <Command>
          {displaySearchBox && (
            <CommandInput
              className="my-2 border-none focus:border-none"
              placeholder="Rechercher..."
            />
          )}
          <CommandList className="bg-blue-50">
            <CommandEmpty>Aucun résultat.</CommandEmpty>
            <CommandGroup>
              {
                // Add null option if optional
                [
                  ...(optionnal ? [{ value: null as T, label: "-" }] : []),
                  ...options,
                ].map((option) => (
                  <CommandItem
                    key={stringifyValue(option.value)}
                    value={stringifyValue(option.value)}
                    disabled={option.disabled}
                    onSelect={(currentValue) => {
                      const selectedOption = options.find(
                        (opt) => stringifyValue(opt.value) === currentValue
                      );
                      onChange(selectedOption?.value ?? null);
                      setOpen(false);
                    }}
                    className="data-[selected=true]:bg-white"
                  >
                    <Check
                      className={cn(
                        "mr-2 h-4 w-4",
                        stringifyValue(value) === stringifyValue(option.value)
                          ? "opacity-100"
                          : "opacity-0"
                      )}
                    />
                    {option.label}
                    {option.disabled && option.disabledMessage && (
                      <span className="italic">
                        {" "}
                        - {option.disabledMessage}
                      </span>
                    )}
                  </CommandItem>
                ))
              }
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
