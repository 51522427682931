export enum DocumentType {
  CERTIFICATE = "CERTIFICATE",
  FOREIGN_CERTIFICATE = "FOREIGN_CERTIFICATE",
  CONVOCATION = "CONVOCATION",
  RESULTS = "RESULTS",
  DEBIT_PERSONNAL_PAYED = "DEBIT_PERSONNAL_PAYED",
  DEBIT_PERSONNAL_UNPAYED = "DEBIT_PERSONNAL_UNPAYED",
  DEBIT_COMPANY_UNPAYED = "DEBIT_COMPANY_UNPAYED",
  DEBIT_COMPANY_PAYED = "DEBIT_COMPANY_PAYED",
  ATTENDANCE_ATTESTATION = "ATTENDANCE_ATTESTATION",
  REDUCED_PRICE_JUSTIFICATION = "REDUCED_PRICE_JUSTIFICATION",
  FORMATION_ATTESTATION = "FORMATION_ATTESTATION",
  PROROGATION = "PROROGATION",
}
