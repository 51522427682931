import { ROUTE } from "@/constants/routes";
import { Button } from "@atoms/Button";
import { RadioGroup, RadioGroupItem } from "@atoms/RadioGroup";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const CandidateCreateAccount = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [hasCertificates, setHasCertificates] = useState(
    location.state?.hasCertificates ?? (false as boolean)
  );

  return (
    <div className="flex flex-col w-full">
      <h1 className="mt-8">Créer un compte candidat</h1>
      <div className="font-semibold mt-8">Sélectionnez votre situation</div>
      <RadioGroup
        defaultValue={hasCertificates.toString()}
        onValueChange={(str) => setHasCertificates(str === "true")}
        className="mt-2"
      >
        {[
          {
            value: "false",
            msg: "Je ne possède pas de certificat",
            key: "false",
          },
          {
            value: "true",
            msg: "Je possède un certificat d'un autre état",
            key: "true",
          },
        ].map(({ value, msg, key }) => (
          <div className="flex items-center self-stretch" key={key}>
            <RadioGroupItem
              key={value}
              value={value}
              className="flex items-center justify-center gap-2"
            />
            <div>{msg}</div>
          </div>
        ))}
      </RadioGroup>
      <div className="flex flex-col gap-2 items-start self-stretch">
        <Button
          className="flex px-1 py-2 justify-center gap-1 self-stretch rounded-lg border border-[#1D64D8] bg-[#2570EB] mt-7"
          onClick={() =>
            navigate(ROUTE.candidate.accountCreation(), {
              state: { hasCertificates },
            })
          }
        >
          Suivant
        </Button>
      </div>
    </div>
  );
};

export default CandidateCreateAccount;
