import { useCandidatesLastRegistrationQuery as useCandidatesOnGoingRegistrationQuery } from "@/api/admin/admin";
import { Badge } from "@/components/atoms/Badge";
import RegistrationTypeBadge from "@/components/molecules/Badges/RegistrationTypeBadge";
import { QueryLoadingSpinner } from "@/components/molecules/LoadingSpinner";
import GrayCard from "@/components/molecules/RegistrationCard/GrayCard";
import { Calendar, User } from "lucide-react";
import { useParams } from "react-router-dom";
import ActionsButton from "./ActionsButton/ActionsButton";
import DelegationCard from "./Cards/DelegationCard";
import PayementCard from "./Cards/PayementCard/PayementCard";
import PreTestCard from "./Cards/PreTestCard/PreTestCard";
import ReducedPriceCard from "./Cards/ReducedPriceCard/ReducedPriceCard";
import FormationAttestations from "./FormationAttestations/FormationAttestations";
import RegistrationStatusBadge from "./RegisteringStatusBadge";

const OnGoingSession = () => {
  const { id } = useParams();
  const registration = useCandidatesOnGoingRegistrationQuery(Number(id));

  if (!id) return false;

  if (!registration.data)
    return (
      <QueryLoadingSpinner
        queries={[registration]}
        loadingMessage="Chargement de la dernière inscription..."
        errorMessage="Erreur lors du chargement de la dernière inscription."
      />
    );

  if (registration.data === "no-registration")
    return (
      <span className="m-5">Aucune inscription à une session en cours</span>
    );

  return (
    <div className="flex w-full p-4">
      <div className="flex flex-col gap-4 p-4 bg-white border border-gray-200 rounded-lg w-full">
        <div className="flex flex-row justify-between">
          <div className="flex flex-row w-full gap-6">
            <GrayCard>Session : {registration.data.exam.number}</GrayCard>
            <GrayCard>
              <User className="w-4 h-4 text-gray-500" />
              N° inscription :{" "}
              {registration.data.registrationNumber ?? "Non généré"}
              <RegistrationTypeBadge
                registrationType={registration.data.type}
              />
            </GrayCard>
            <GrayCard>
              <Calendar className="w-4 h-4 text-gray-500" />
              Date de l'examen :{" "}
              {new Date(registration.data.exam.date).toLocaleDateString(
                "fr-FR"
              )}
            </GrayCard>
          </div>
          <ActionsButton
            registration={registration.data}
            candidateId={Number(id)}
          />
        </div>
        <div className="flex flex-col gap-8">
          <div className="flex flex-col gap-2">
            <div className="flex flex-row gap-2">
              <h2>Inscription</h2>
              <RegistrationStatusBadge registration={registration.data} />
              <div className="flex flex-row gap-2 ml-2">
                <span>Clôture inscrip.</span>
                <Badge variant="white">
                  {new Date(
                    registration.data.exam.registeringEndDate
                  ).toLocaleDateString()}
                </Badge>
              </div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
              <DelegationCard registration={registration.data} />
              <PayementCard
                registration={registration.data}
                candidateId={Number(id)}
              />
              <ReducedPriceCard
                registration={registration.data}
                candidateId={Number(id)}
              />
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <h2>Test préalable</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
              <PreTestCard
                registration={registration.data}
                candidateId={Number(id)}
              />
            </div>
          </div>
          <FormationAttestations
            registration={registration.data}
            candidateId={Number(id)}
          />
        </div>
      </div>
    </div>
  );
};

export default OnGoingSession;
