import { CandidateRegistrationDtoClient } from "@/api/candidate/dto/candidate-registration.dto";
import { Badge } from "@/components/atoms/Badge";
import FileLink from "@/components/molecules/FileLink";
import { Card, CardTitle } from "@/components/molecules/RegistrationCard/Card";

interface ConvocationCardProps {
  registration: CandidateRegistrationDtoClient;
}

const ConvocationCard = ({
  registration: { convocation },
}: ConvocationCardProps) => {
  return (
    <Card>
      <div className="flex flex-row justify-between w-full items-center">
        <div className="flex flex-row gap-2">
          <CardTitle>Convocation à l'examen</CardTitle>
          {!convocation && <Badge variant="white">Non disponible</Badge>}
        </div>
        {convocation && <FileLink file={convocation} />}
      </div>
    </Card>
  );
};

export default ConvocationCard;
