const CGV = () => {
  return (
    <>
      Date de mise en application : 31 janvier 2022
      <br />
      <br />
      <span className="text-lg font-semibold">
        Article 1 : Dispositions générales
      </span>
      <br />
      <br />
      <span className="text-base font-semibold">Article 1.01</span>
      <br />
      <br />
      Les présentes Conditions Générales de Vente de prestations de services
      (ci-après dénommées «CGV»), constituent l’accord régissant pendant sa
      durée, les relations entre le CIFMD, Association Loi 1901, dont le siège
      social est situé 14, rue de la République 92800 Puteaux, Siret 422 669 861
      00029 (ci-après dénommé « CIFMD ») et ses clients (ci-après dénommés «
      Candidat ») dans le cadre de la vente de prestations de services.
      <br />
      <br />
      <span className="text-base font-semibold">Article 1.02</span>
      <br />
      <br />
      Les CGV sont mises à dispositions du candidat sur le site internet du
      CIFMD et sont à valider obligatoirement au moment de l’inscription du
      candidat à un examen en ligne de conseiller à la sécurité pour le
      transport de marchandises dangereuses. Toute inscription implique donc
      l’adhésion pleine et entière du candidat aux présentes CGV.
      <br />
      <br />
      <span className="text-base font-semibold">Article 1.03</span>
      <br />
      <br />
      Le CIFMD se réserve la faculté de modifier ses CGV à tout moment. En cas
      de modification, les CGV applicables sont celles en vigueur à la date de
      l’inscription (commande de la prestation d’examen en ligne).
      <br />
      <br />
      <span className="text-lg font-semibold">
        Article 2 : Nature de la prestation
      </span>
      <br />
      <br />
      <span className="text-base font-semibold">Article 2.01</span>
      <br />
      <br />
      Le CIFMD est l’unique organisme habilité à faire passer, en France, les
      examens initiaux et de renouvellement du certificat de qualification
      professionnelle de conseiller à la sécurité pour le transport par route,
      par rail ou par voie navigable de marchandises dangereuses, conformément à
      l’arrêté du 6 février 2019 en vigueur.
      <br />
      <br />
      Le CIFMD fournit aux candidats une prestation de service intégralement
      dématérialisée pour l’inscription, le passage des examens initiaux et de
      renouvellement et la délivrance du certificat de conseiller à la sécurité
      pour le transport par route, par rail ou par voie navigable de
      marchandises dangereuses.
      <br />
      <br /> Ces conditions d’inscription et de passage d’examen en ligne sont
      décrites dans les CGU, qui doivent être lues et acceptées par le candidat
      au moment de son inscription. Des tutoriels d’aide et des supports
      techniques ainsi qu’une F.A.Q. sont disponibles : ils peuvent être
      consultés sur le site internet du CIFMD. Ceux-ci sont par nature
      évolutifs.
      <br />
      <br />
      <span className="text-base font-semibold">Article 2.02</span>
      <br />
      <br /> Le CIFMD organise différentes sessions d’examen chaque année : les
      dates sont publiées sur le site internet du CIFMD. Le candidat doit
      répondre aux conditions définies dans l’article 4 de nos CGU pour pouvoir
      passer son examen en ligne.
      <br />
      <br />
      En cas de non-respect de ces conditions, les frais d’inscription ne font
      l’objet d’aucun remboursement. L’inscription peut être gracieusement
      reportée à la session suivante, sur demande écrite du candidat au CIFMD à
      l’adresse : contact@cifmd.fr
      <br />
      <br />
      <span className="text-lg font-semibold">
        Article 3 : Conditions de vente de la prestation d’examen en ligne
      </span>
      <br />
      <br />
      <span className="text-base font-semibold">Article 3.01</span>
      <br />
      <br /> Pour passer son examen en ligne, le candidat doit s’inscrire à
      partir du site internet du CIFMD et suivre les étapes d’inscription. Le
      candidat doit en particulier indiquer son choix de périmètre de certificat
      (choix du ou des mode(s) de transport et classe(s) de danger de
      marchandises dangereuses concernés) et de date de session.
      <br />
      <br /> Le candidat a la possibilité de vérifier le détail de son
      inscription, de son prix et procéder à des rectifications avant validation
      de sa commande par le paiement. Après paiement, la modification de
      l’inscription est possible, sous réserve des dispositions décrites dans
      l’article 7 des CGU.
      <br />
      <br />
      <span className="text-base font-semibold">Article 3.02</span>
      <br />
      <br /> Pour confirmer sa commande de manière ferme et définitive, le
      candidat doit valider et payer les frais d’inscription en vigueur publiés
      sur le site du CIFMD (nommés « tarif par session »). L’enregistrement du
      paiement dans nos livres constitue la validation de l’inscription. Un
      courrier électronique accusant réception de la commande et de son paiement
      est envoyé automatiquement par mail.
      <br />
      <br />
      <span className="text-base font-semibold">Article 3.03</span>
      <br />
      <br /> Le paiement doit être effectué au plus tard avant la date de
      clôture des inscriptions définie pour la session choisie par le candidat :
      la date est publiée sur le site internet du CIFMD et la plate-forme
      d’inscription.
      <br />
      <br />
      <span className="text-base font-semibold">Article 3.04</span>
      <br />
      <br /> La confirmation de l’inscription par paiement sur le site internet
      du CIFMD vaut acceptation des présentes CGV et conclusion d’un contrat de
      vente entre le CIFMD et le candidat.
      <br />
      <br />
      <span className="text-lg font-semibold">
        Article 4 : Paiement et prix
      </span>
      <br />
      <br />
      <span className="text-base font-semibold">Article 4.01</span>
      <br />
      <br /> Le paiement des frais d’inscription ne peut être effectué que par
      carte bancaire, par le biais de notre plate-forme bancaire sécurisée en
      ligne.
      <br />
      <br />
      <span className="text-base font-semibold">Article 4.02</span>
      <br />
      <br /> Les prix des services sont ceux qui sont proposés et détaillés sur
      le site internet du CIFMD. Ils sont exprimés en euros et ne sont pas
      soumis à la TVA.
      <br />
      <br />
      <span className="text-base font-semibold">Article 4.03</span>
      <br />
      <br /> A défaut de réception du règlement complet des frais d’inscription
      en ligne, l’inscription ne sera pas validée. Aucune annulation n’est
      acceptée par le CIFMD sauf en cas de : Rétractation, si les dispositions
      prévues par l’article 5 des CGV sont respectées Force majeure, sous
      réserve d’acceptation du dossier et des justificatifs transmis selon les
      dispositions prévues par les CGU
      <br />
      <br />
      <span className="text-base font-semibold">Article 4.04</span>
      <br />
      <br /> Les documents comptables peuvent être consultés via le compte
      personnel du candidat sur le site internet du CIFMD. Ce support est fiable
      et sécurisé pour archiver les documents comptables relatifs aux examens
      conformément à l’article 1360 du Code civil. Ces documents peuvent être
      produits à titre de preuve du contrat.
      <br />
      <br />
      <span className="text-base font-semibold">Article 4.05</span>
      <br />
      <br />
      Les bons de commande ne sont pas acceptés.
      <br />
      <br />
      <span className="text-base font-semibold">Article 4.06</span>
      <br />
      <br /> Les règlements par chèque ne sont pas acceptés.
      <br />
      <br />
      <span className="text-lg font-semibold">
        Article 5 : Résiliation, rétractation et report
      </span>
      <br />
      <br />
      <span className="text-base font-semibold">Article 5.01</span>
      <br />
      <br /> Conformément aux dispositions de l’article L221-18 du Code de la
      consommation, en cas de vente à distance, le candidat dispose d’un délai
      de rétractation de quatorze jours à compter de la validation de
      l’inscription, sans avoir à justifier de motif ni à payer de pénalités, et
      même si le délai de quatorze jours vient à expirer un samedi, un dimanche
      ou un jour férié.
      <br />
      <br />
      <span className="text-base font-semibold">Article 5.02</span>
      <br />
      <br /> Pour exercer son droit de rétractation, le candidat doit informer
      le CIFMD de sa décision de rétractation en lui adressant, avant
      l’expiration du délai prévu à l’article L. 221-18 du Code de la
      consommation, soit le formulaire-type de rétractation annexé aux présentes
      CGV, soit une déclaration dénuée d’ambiguïté exprimant sa volonté de se
      rétracter.
      <br />
      <br />
      La demande de rétractation devra se faire impérativement par courrier
      recommandé avec accusé de réception, ainsi que par mail, à l’adresse
      contact@cifmd.fr, au plus tard à la date de fin de droit de rétractation
      (cf. article 5.01).
      <br />
      <br />
      <span className="text-base font-semibold">Article 5.03</span>
      <br />
      <br /> Toute rétractation notifiée dans le délai de quatorze jours,
      donnera lieu soit à un remboursement soit à un report par le CIFMD, selon
      le choix du candidat, au plus tard quatorze jours à compter de la date de
      réception de la résiliation d’inscription.
      <br />
      <br />
      <span className="text-base font-semibold">Article 5.04</span>
      <br />
      <br /> Après le délai de rétractation de quatorze jours, l’inscription à
      l’examen est ferme et définitive et ne peut plus être remboursée. Aucune
      annulation n’est acceptée par le CIFMD sauf en cas de force majeure (voir
      nos CGU). Les justificatifs appropriés devront être envoyés par mail au
      CIFMD dans un délai maximal de 15 jours après la date d’examen. Dans le
      cas de force majeure, le CIFMD ne rembourse pas les frais d’inscription et
      propose un report gratuit à l’examen suivant.
      <br />
      <br />
      <span className="text-base font-semibold">Article 5.05</span>
      <br />
      <br /> L’inscription d’un candidat peut être reportée gratuitement à une
      autre date de session, avant la date de clôture des inscriptions de la
      session pour laquelle il est inscrit, sur simple demande, par mail, à
      l’adresse : contact@cifmd.fr . Passé ce délai, aucun report gratuit n’est
      possible.
      <br />
      <br />
      <span className="text-lg font-semibold">
        Article 6 : Propriété intellectuelle
      </span>
      <br />
      <br />
      <span className="text-base font-semibold">Article 6.01</span>
      <br />
      <br /> Le CIFMD est seul détenteur du contenu intégral des épreuves des
      examens initiaux et de renouvellement des candidats. Les sujets d’examens
      ne sont ni transmis aux candidats, ni rendus publics après les sessions
      d’examen, sauf à titre d’exemple sur le site internet du CIFMD.
      <br />
      <br />
      Article 6.02
      <br />
      <br /> Toute reproduction, modification ou diffusion à des tiers de tout
      ou partie des sujets d’examens et du contenu des applications utilisées,
      est interdite et donnera lieu à des poursuites judiciaires.
      <br />
      <br />
      <span className="text-base font-semibold">Article 6.3</span>
      <br />
      <br /> Le candidat autorise le transfert de propriété au CIFMD de sa copie
      numérique d’étude de cas (examen initial uniquement), sous réserve de son
      anonymisation préalable. La correction a donc pour effet d’opérer un
      transfert de propriété de sa copie numérique au profit du CIFMD, qui
      pourra ainsi en disposer librement. Le CIFMD pourra ainsi faire corriger
      la copie du candidat et ce peu importe la nature du support. Le candidat
      autorise également le transfert de propriété au CIFMD des réponses
      renseignées dans son sujet QCM, corrigé automatiquement par l’application
      dédiée appartenant au CIFMD.
      <br />
      <br />
      <span className="text-lg font-semibold">
        Article 7 : Tribunal compétent
      </span>
      <br />
      <br /> Tout litige relatif à l’interprétation et à l’exécution des
      présentes conditions générales de vente est soumis au droit français. À
      défaut de résolution amiable, le litige sera porté devant le Tribunal de
      commerce de Paris.
      <br />
      <br />
      <span className="text-lg font-semibold">
        Annexe : formulaire-type de retractation
      </span>
      <br />
      <br />
      <a
        className="text-blue-500"
        href="https://cifmd.org/wp-content/uploads/2022/01/formulaire_retractation.pdf"
        target="_newPage"
      >
        Lien vers modèle de formulaire
      </a>
    </>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export default CGV;
