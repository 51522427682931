import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@atoms/Form";
import { ComboboxField } from "@molecules/Combobox";
import { Info } from "lucide-react";
import { useFormContext } from "react-hook-form";

interface UTCComboboxProps {
  fieldName: string;
}

const UTCCombobox = ({ fieldName }: UTCComboboxProps) => {
  const { control } = useFormContext();

  return (
    <FormField
      control={control}
      name={fieldName}
      render={() => (
        <FormItem className="w-full">
          <FormLabel>
            <h2>Fuseau horaire</h2>
          </FormLabel>
          <FormControl>
            <ComboboxField
              options={Array.from({ length: 27 }, (_, i) => i - 12).map(
                (utc) => {
                  return {
                    label: `UTC ${utc > 0 ? "+" : ""}${utc}`,
                    value: utc,
                  };
                }
              )}
              placeholder={"-"}
            />
          </FormControl>
          <FormMessage />
          <div className="flex flex-row p-4 items-start self-stretch gap-4 border rounded-md border-gray-300 bg-white font-semibold shadow">
            <Info />
            Un aménagement d'horaire est possible pour les candidats localisé en
            heure UTC-3 et -4
          </div>
        </FormItem>
      )}
    />
  );
};

export default UTCCombobox;
