import { useAllExamsDetailledQuery } from "@/api/exam/exam";
import { Button } from "@/components/atoms/Button";
import { Tabs, TabsList, TabsTrigger } from "@/components/atoms/Tabs";
import ExamStatusBadge from "@/components/molecules/Badges/ExamStatusBadge";
import { LoadingSpinner } from "@/components/molecules/LoadingSpinner";
import { ROUTE } from "@/constants/routes";
import usePersistent from "@/hooks/use-persistent";
import { ArrowLeft } from "lucide-react";
import { useEffect, useMemo } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { SessionContext } from "./session-context";
import { SessionTab } from "./session-tab.enum";

const Session = () => {
  const { id: idstr } = useParams();
  const examId = Number(idstr);
  const [currentTab, setCurrentTab] = usePersistent(
    "admin-session-tab",
    SessionTab.Candidates
  );
  const navigate = useNavigate();

  const { data: exams, isLoading, isError } = useAllExamsDetailledQuery();

  // navigate to current tab
  useEffect(() => {
    switch (currentTab) {
      case SessionTab.Candidates:
        navigate(ROUTE.admin.dashboard.session.candidates(examId));
        break;
      case SessionTab.Information:
        navigate(ROUTE.admin.dashboard.session.information(examId));
        break;
      case SessionTab.Results:
        navigate(ROUTE.admin.dashboard.session.results(examId));
        break;
    }
  }, [currentTab, examId, navigate]);

  const exam = useMemo(
    () => exams && exams.find((s) => s.id === examId),
    [examId, exams]
  );

  const context: SessionContext | undefined = exam
    ? {
        exam,
      }
    : undefined;

  if (idstr === undefined) navigate(ROUTE.admin.dashboard.sessions.root());

  return (
    <div className="flex flex-col w-full bg-white h-screen">
      <div className="flex flex-col w-full border-b border-b-gray-200 px-6 pt-3 gap-2">
        <div className="flex flex-row justify-between w-full items-center">
          <div className="flex flex-row gap-4 items-center">
            <Button
              variant="ghostWithBorders"
              className="p-2 rounded-lg"
              onClick={() => navigate(ROUTE.admin.dashboard.sessions.root())}
            >
              <ArrowLeft className="w-5 h-5 text-gray-500" />
            </Button>
            {exam ? (
              <div className="flex flex-col justify-center">
                <div className="flex flex-row gap-2 items-center">
                  <h1>{exam.number}</h1>
                  <ExamStatusBadge
                    registeringEndDate={new Date(exam.registeringEndDate)}
                    status={exam.status}
                  />
                </div>
                <div className="flex flex-row text-gray-600 gap-4 items-center">
                  <span>
                    Clôture inscr. :{" "}
                    {new Date(exam.registeringEndDate).toLocaleDateString(
                      "fr-FR"
                    )}
                  </span>
                  <span>
                    Clôture att. form. :{" "}
                    {new Date(
                      exam.formationCertificateEndDate
                    ).toLocaleDateString("fr-FR")}
                  </span>
                  <span>
                    Examen : {new Date(exam.date).toLocaleDateString("fr-FR")}
                  </span>
                </div>
              </div>
            ) : (
              <LoadingSpinner
                isLoading={isLoading}
                // error if sessions are loaded but no session is found
                // with given id
                isError={isError || !!exams}
                loadingMessage="Chargement de la session..."
                errorMessage="Erreur lors du chargement de la session."
              />
            )}
          </div>
        </div>
        <Tabs
          onValueChange={(e) => setCurrentTab(e as SessionTab)}
          value={currentTab}
        >
          <TabsList variant="transparent" className="pb-0 mb-0">
            <TabsTrigger variant="underlineBlue" value={SessionTab.Candidates}>
              Suivi candidats
            </TabsTrigger>
            {exam && new Date(exam.date) >= new Date() && (
              <TabsTrigger variant="underlineBlue" value={SessionTab.Results}>
                Résultats
              </TabsTrigger>
            )}
            <TabsTrigger variant="underlineBlue" value={SessionTab.Information}>
              Informations session
            </TabsTrigger>
          </TabsList>
        </Tabs>
      </div>
      {exam && <Outlet context={context} />}
    </div>
  );
};

export default Session;
