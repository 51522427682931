import { ENDPOINT } from "@/constants/endpoints";
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import axios, { AxiosError, AxiosResponse } from "axios";
import { keyFactory } from "../keyFactory";
import { AllAttestationsProvidedDto } from "./dto/all-attestations-provided.dto";
import { CancelDelegationDto } from "./dto/cancel-delegation.dto";
import { ExamOptionsDto } from "./dto/exam-options.dto";
import { FormationCertificatesNewDocumentsDto } from "./dto/formation-attestations-new-documents.dto";
import { NewDelegationDto } from "./dto/new-delegation.dto";
import { ReducedPriceNewDocumentDto } from "./dto/reduced-price-new-document.dto";

// create or reset draft

const createResetDraft = async () => {
  return await axios.post<null>(ENDPOINT.examRegistration.postCreate());
};

export const useCreateResetDraftMutation = (
  props?: UseMutationOptions<AxiosResponse<null>, AxiosError, void>
) => {
  return useMutation({
    mutationFn: createResetDraft,
    ...props,
  });
};

// can candidate register

const canRegister = async () => {
  return await axios
    .get<boolean>(ENDPOINT.examRegistration.getCanRegister())
    .then((res) => res.data);
};

export const useCanRegisterQuery = (
  props?: UseQueryOptions<boolean, AxiosError>
) => {
  return useQuery({
    queryKey: keyFactory.examRegistration.canRegister(),
    queryFn: canRegister,
    ...props,
  });
};

// set current draft options

const setOptions = async (data: ExamOptionsDto) => {
  return await axios.post<null>(
    ENDPOINT.examRegistration.postSetOptions(),
    data
  );
};

export const useSetOptionsMutation = (
  props?: UseMutationOptions<AxiosResponse<null>, AxiosError, ExamOptionsDto>
) => {
  return useMutation({
    mutationFn: setOptions,
    ...props,
  });
};

// get current draft options

const getCurrentOptions = async () => {
  return axios
    .get<ExamOptionsDto | "no-draft">(ENDPOINT.examRegistration.getOptions())
    .then((res) => res.data);
};

export const useGetCurrentOptionsQuery = (
  props?: UseQueryOptions<ExamOptionsDto | "no-draft", AxiosError>
) => {
  return useQuery({
    queryKey: keyFactory.examRegistration.options(),
    queryFn: getCurrentOptions,
    ...props,
  });
};

// confirm registration

const confirmRegistration = async () => {
  return await axios.post<void>(ENDPOINT.examRegistration.postConfirm());
};

export const useConfirmRegistrationMutation = (
  props?: UseMutationOptions<AxiosResponse<void>, AxiosError, void>
) => {
  return useMutation({
    mutationFn: confirmRegistration,
    ...props,
  });
};

// reduced price document

const reducedPriceNewDocument = async (data: ReducedPriceNewDocumentDto) => {
  return await axios
    .post<void>(ENDPOINT.examRegistration.reducedPrice.postNewDocument(), data)
    .then((res) => res.data);
};

export const useReducedPriceNewDocumentMutation = (
  props?: UseMutationOptions<void, AxiosError, ReducedPriceNewDocumentDto>
) => {
  return useMutation({
    mutationFn: reducedPriceNewDocument,
    ...props,
  });
};

// formation certificates upload

const formationCertificatesNewDocument = async (
  data: FormationCertificatesNewDocumentsDto
) => {
  return await axios
    .post<void>(
      ENDPOINT.examRegistration.formationAttestations.postNewDocuments(),
      data
    )
    .then((res) => res.data);
};

export const useFormationCertificatesNewDocumentMutation = (
  props?: UseMutationOptions<
    void,
    AxiosError,
    FormationCertificatesNewDocumentsDto
  >
) => {
  return useMutation({
    mutationFn: formationCertificatesNewDocument,
    ...props,
  });
};

// indicate wire transfer done

const transferDone = async (registrationId: number) => {
  return await axios.post<void>(ENDPOINT.examRegistration.postTransferDone(), {
    registrationId,
  });
};

export const useTransferDoneMutation = (
  props?: UseMutationOptions<AxiosResponse<void>, AxiosError, number>
) => {
  return useMutation({
    mutationFn: transferDone,
    ...props,
  });
};

// cancel delegation

const cancelDelegation = async (data: CancelDelegationDto) => {
  return await axios.post<void>(
    ENDPOINT.examRegistration.delegation.postRefuse(),
    data
  );
};

export const useCancelDelegationMutation = (
  props?: UseMutationOptions<
    AxiosResponse<void>,
    AxiosError,
    CancelDelegationDto
  >
) => {
  return useMutation({
    mutationFn: cancelDelegation,
    ...props,
  });
};

// new delegation

const newDelegation = async (data: NewDelegationDto) => {
  return await axios.post<void>(
    ENDPOINT.examRegistration.delegation.postNew(),
    data
  );
};

export const useNewDelegationMutation = (
  props?: UseMutationOptions<AxiosResponse<void>, AxiosError, NewDelegationDto>
) => {
  return useMutation({
    mutationFn: newDelegation,
    ...props,
  });
};

// formation certificates all provided

const allAttestationsProvided = async (data: AllAttestationsProvidedDto) => {
  return await axios.post<void>(
    ENDPOINT.examRegistration.formationAttestations.postAllAttestationsProvided(),
    data
  );
};

export const useAllAttestationsProvidedMutation = (
  props?: UseMutationOptions<
    AxiosResponse<void>,
    AxiosError,
    AllAttestationsProvidedDto
  >
) => {
  return useMutation({
    mutationFn: allAttestationsProvided,
    ...props,
  });
};
