import { CandidateRegistrationDtoClient } from "@/api/candidate/dto/candidate-registration.dto";
import { DelegationStatus } from "@/api/enums/DelegationStatus";
import { PayementStatus } from "@/api/enums/PayementStatus";
import { PriceType } from "@/api/enums/PriceType";
import { ValidationStatus } from "@/api/enums/ValidationStatus";

export class RegistrationUtil {
  static isCompleted(registration: CandidateRegistrationDtoClient) {
    const { formationCertificatesStatus, reducedPriceStatus, preTestStatus } =
      registration;

    return (
      formationCertificatesStatus === ValidationStatus.VALIDATED &&
      [ValidationStatus.VALIDATED, ValidationStatus.UNAVAILABLE].includes(
        reducedPriceStatus
      ) &&
      preTestStatus === ValidationStatus.VALIDATED &&
      RegistrationUtil.isPayed(registration)
    );
  }

  static delegationPayed({ payementStatus }: CandidateRegistrationDtoClient) {
    return (
      payementStatus === PayementStatus.DELEGATION_PAYED_CARD ||
      payementStatus === PayementStatus.DELEGATION_PAYED_TRANSFER
    );
  }

  static personnalPayed({ payementStatus }: CandidateRegistrationDtoClient) {
    return (
      payementStatus === PayementStatus.PERSONNAL_PAYED_CARD ||
      payementStatus === PayementStatus.PERSONNAL_PAYED_TRANSFER
    );
  }

  static isPayed({ payementStatus }: CandidateRegistrationDtoClient) {
    return (
      payementStatus === PayementStatus.PERSONNAL_PAYED_CARD ||
      payementStatus === PayementStatus.PERSONNAL_PAYED_TRANSFER ||
      payementStatus === PayementStatus.DELEGATION_PAYED_CARD ||
      payementStatus === PayementStatus.DELEGATION_PAYED_TRANSFER
    );
  }

  static currentDebitNote = (registration: CandidateRegistrationDtoClient) => {
    const {
      delegation,
      debitCompanyPayedFile,
      debitCompanyUnpayedFile,
      debitPersonnalPayedFile,
      debitPersonnalUnpayedFile,
    } = registration;

    return delegation
      ? RegistrationUtil.delegationPayed(registration)
        ? debitCompanyPayedFile
        : debitCompanyUnpayedFile
      : RegistrationUtil.personnalPayed(registration)
      ? debitPersonnalPayedFile
      : debitPersonnalUnpayedFile;
  };

  static candidateCanPay = (registration: CandidateRegistrationDtoClient) => {
    const { delegation, payementStatus, priceType, reducedPriceStatus } =
      registration;

    return (
      !RegistrationUtil.isPayed(registration) &&
      ((delegation && delegation.status === DelegationStatus.REFUSED) ||
        (!delegation &&
          payementStatus === PayementStatus.UNPAYED &&
          (priceType === PriceType.FULL ||
            // reduced price document must be validated
            // in order to pay
            (priceType === PriceType.REDUCED &&
              reducedPriceStatus === ValidationStatus.VALIDATED))))
    );
  };

  static isPayedByCard = ({
    payementStatus,
  }: CandidateRegistrationDtoClient) => {
    return (
      payementStatus === PayementStatus.DELEGATION_PAYED_CARD ||
      payementStatus === PayementStatus.PERSONNAL_PAYED_CARD
    );
  };

  static isPayedByTransfer = ({
    payementStatus,
  }: CandidateRegistrationDtoClient) => {
    return (
      payementStatus === PayementStatus.DELEGATION_PAYED_TRANSFER ||
      payementStatus === PayementStatus.PERSONNAL_PAYED_TRANSFER
    );
  };

  static hasNonRefusedDelegation = (
    registration: CandidateRegistrationDtoClient
  ) => {
    return (
      registration.delegation &&
      registration.delegation.status !== DelegationStatus.REFUSED
    );
  };

  static payedDebitNote = (registration: CandidateRegistrationDtoClient) => {
    return RegistrationUtil.hasNonRefusedDelegation(registration)
      ? registration.debitCompanyPayedFile
      : registration.debitPersonnalPayedFile;
  };

  static unpayedDebitNote = (registration: CandidateRegistrationDtoClient) => {
    return RegistrationUtil.hasNonRefusedDelegation(registration)
      ? registration.debitCompanyUnpayedFile
      : registration.debitPersonnalUnpayedFile;
  };
}
