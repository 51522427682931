// import * as Sentry from "@sentry/react";
import AdminDashboardForeignCertificates from "@/components/pages/Admin/Dashboard/ForeignCertificates/ForeignCertificates.tsx";
import AdminDashboardSessionCandidates from "@/components/pages/Admin/Dashboard/Session/Candidates/Candidates.tsx";
import AdminDashboardSessionInformation from "@/components/pages/Admin/Dashboard/Session/Information/Information.tsx";
import AdminDashboardSessionResults from "@/components/pages/Admin/Dashboard/Session/Results/Results.tsx";
import AdminDashboardSession from "@/components/pages/Admin/Dashboard/Session/Session.tsx";
import AdminDashboardSessionCreation from "@/components/pages/Admin/Dashboard/SessionCreation/SessionCreation.tsx";
import CandidateExamsRegisterCertificate from "@/components/pages/Candidate/Dashboard/Exams/Register/Certificate/Certificate.tsx";
import CandidateExamsRegisterPayement from "@/components/pages/Candidate/Dashboard/Exams/Register/Payement/Payement.tsx";
import CandidateExamsRegisterPricing from "@/components/pages/Candidate/Dashboard/Exams/Register/Pricing/Pricing.tsx";
import CompanyCandidates from "@/components/pages/Company/Dashboard/Candidates/Candidates.tsx";
import CompanyInformation from "@/components/pages/Company/Dashboard/Information/Information.tsx";
import AdminDashboardCandidate from "@pages/Admin/Dashboard/Candidate/Candidate.tsx";
import AdminDashboardCandidateCertificates from "@pages/Admin/Dashboard/Candidate/Certificates/Certificates.tsx";
import AdminDashboardCandidateDocuments from "@pages/Admin/Dashboard/Candidate/Documents/Documents.tsx";
import AdminDashboardCandidateOnGoingSession from "@pages/Admin/Dashboard/Candidate/OnGoingSession/OnGoingSession.tsx";
import AdminDashboardCandidatePersonnalInformation from "@pages/Admin/Dashboard/Candidate/PersonnalInformation/PersonnalInformation.tsx";
import AdminDashboardCandidateResults from "@pages/Admin/Dashboard/Candidate/Results/Results.tsx";
import AdminDashboardCandidates from "@pages/Admin/Dashboard/Candidates/Candidates.tsx";
import AdminDashboard from "@pages/Admin/Dashboard/Dashboard.tsx";
import AdminDashboardSessions from "@pages/Admin/Dashboard/Sessions/Sessions.tsx";
import AdminLogin from "@pages/Admin/Login/Login.tsx";
import CandidateAccountCreation from "@pages/Candidate/AccountCreation/AccountCreation.tsx";
import CandidateDashboard from "@pages/Candidate/Dashboard/DashBoard.tsx";
import CandidateExams from "@pages/Candidate/Dashboard/Exams/Exams.tsx";
import CandidateExamsHome from "@pages/Candidate/Dashboard/Exams/Home/Home.tsx";
import CandidateExamsRegisterAcknowledgment from "@pages/Candidate/Dashboard/Exams/Register/Acknowledgment/Acknowledgement.tsx";
import CandidateExamsRegisterCheckInfo from "@pages/Candidate/Dashboard/Exams/Register/CheckInfo/CheckInfo.tsx";
import CandidateExamsRegister from "@pages/Candidate/Dashboard/Exams/Register/Register.tsx";
import CandidateExamsRegisterRegisterStep from "@pages/Candidate/Dashboard/Exams/Register/RegisterStep/RegisterStep.tsx";
import CandidateProfile from "@pages/Candidate/Dashboard/Profile/Profile.tsx";
import CompanyAccountCreation from "@pages/Company/AccountCreation.tsx";
import CompanyDashboard from "@pages/Company/Dashboard/Dashboard.tsx";
import CompanyDelegations from "@pages/Company/Dashboard/Delegations/Delegations.tsx";
import ConnectionHome from "@pages/ConnectionHome/ConnectionHome.tsx";
import ForgottenEmail from "@pages/ForgottenCredentials/ForgottenEmail.tsx";
import ForgottenPassword from "@pages/ForgottenCredentials/ForgottenPassword.tsx";
import PasswordChange from "@pages/ForgottenCredentials/PasswordChange.tsx";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { StrictMode, useCallback, useEffect, useState } from "react";
import {
  createBrowserRouter,
  Navigate,
  RouteObject,
  RouterProvider,
} from "react-router-dom";
import { toast } from "sonner";
import "unfonts.css";
import { LoginResponse } from "./api/auth/dto/login.dto";
import { Toaster } from "./components/atoms/Sonner.tsx";
import NotFound from "./components/pages/NotFound.tsx";
import SeedData from "./components/pages/SeedData.tsx";
import Welcome from "./components/pages/Welcome.tsx";
import { ENDPOINT } from "./constants/endpoints";
import { ROUTE } from "./constants/routes";
import { useAuthStore } from "./hooks/AuthStore";
import "./styles/index.css";

// FIXME: sentry triggers error on loading :
// types.js:3 Uncaught ReferenceError: Cannot access 'Rr' before initialization

// if (["production", "staging"].includes(import.meta.env.VITE_MODE)) {
//   Sentry.init({
//     dsn: import.meta.env.VITE_SENTRY_DSN,
//     integrations: [
//       Sentry.browserTracingIntegration(),
//       Sentry.browserProfilingIntegration(),
//       Sentry.replayIntegration(),
//     ],
//     environment: import.meta.env.VITE_MODE,
//     tracesSampleRate: 1.0,
//     profilesSampleRate: 1.0,
//     replaysSessionSampleRate: 0.1,
//     replaysOnErrorSampleRate: 1.0,
//   });
// }

const AppProviders = () => {
  const { login, logout, session } = useAuthStore();
  const [refreshing, setRefreshing] = useState(false);

  const [router] = useState(() => {
    const routes: RouteObject[] = [
      {
        path: "/home",
        element: <ConnectionHome />,
      },
      {
        path: "/admin",
        children: [
          {
            index: true,
            element: <Navigate to={"/admin/dashboard"} replace />,
          },
          {
            path: "login",
            element: <AdminLogin />,
          },
          {
            path: "dashboard",
            element: <AdminDashboard />,
            children: [
              {
                index: true,
                element: (
                  <Navigate to={"/admin/dashboard/candidates"} replace />
                ),
              },
              {
                path: "candidates",
                element: <AdminDashboardCandidates />,
              },
              {
                path: "foreign-certificates",
                element: <AdminDashboardForeignCertificates />,
              },
              {
                path: "candidate/:id",
                element: <AdminDashboardCandidate />,
                children: [
                  {
                    path: "",
                    element: <AdminDashboardCandidateOnGoingSession />,
                  },
                  {
                    path: "results",
                    element: <AdminDashboardCandidateResults />,
                  },
                  {
                    path: "documents",
                    element: <AdminDashboardCandidateDocuments />,
                  },
                  {
                    path: "personnal-information",
                    element: <AdminDashboardCandidatePersonnalInformation />,
                  },
                  {
                    path: "certificates",
                    element: <AdminDashboardCandidateCertificates />,
                  },
                ],
              },
              {
                path: "sessions",
                element: <AdminDashboardSessions />,
              },
              {
                path: "session-creation",
                element: <AdminDashboardSessionCreation />,
              },
              {
                path: "session/:id",
                element: <AdminDashboardSession />,
                children: [
                  {
                    path: "",
                    element: <AdminDashboardSessionCandidates />,
                  },
                  {
                    path: "results",
                    element: <AdminDashboardSessionResults />,
                  },
                  {
                    path: "information",
                    element: <AdminDashboardSessionInformation />,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "/candidate",
        children: [
          {
            index: true,
            element: <Navigate to={"/candidate/dashboard"} replace />,
          },
          {
            path: "account-creation",
            element: <CandidateAccountCreation />,
          },
          {
            path: "dashboard",
            element: <CandidateDashboard />,
            children: [
              {
                index: true,
                element: (
                  <Navigate to={"/candidate/dashboard/profile"} replace />
                ),
              },
              {
                path: "profile",
                element: <CandidateProfile />,
              },
              {
                path: "exams",
                element: <CandidateExams />,
                children: [
                  {
                    index: true,
                    element: (
                      <Navigate
                        to={"/candidate/dashboard/exams/home"}
                        replace
                      />
                    ),
                  },
                  {
                    path: "home",
                    element: <CandidateExamsHome />,
                  },
                  {
                    path: "register",
                    element: <CandidateExamsRegister />,
                    children: [
                      {
                        index: true,
                        element: (
                          <Navigate
                            to={
                              "/candidate/dashboard/exams/register/check-info"
                            }
                            replace
                          />
                        ),
                      },
                      {
                        path: "check-info",
                        element: <CandidateExamsRegisterCheckInfo />,
                      },

                      {
                        path: "acknowledgment",
                        element: <CandidateExamsRegisterAcknowledgment />,
                      },

                      {
                        path: "certificate",
                        element: <CandidateExamsRegisterCertificate />,
                      },

                      {
                        path: "pricing",
                        element: <CandidateExamsRegisterPricing />,
                      },

                      {
                        path: "payement",
                        element: <CandidateExamsRegisterPayement />,
                      },
                      {
                        path: "register-step",
                        element: <CandidateExamsRegisterRegisterStep />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "/company",
        children: [
          {
            index: true,
            element: <Navigate to={"/company/dashboard"} replace />,
          },
          {
            path: "dashboard",
            element: <CompanyDashboard />,
            children: [
              {
                index: true,
                element: (
                  <Navigate to={"/company/dashboard/delegations"} replace />
                ),
              },
              {
                path: "delegations",
                element: <CompanyDelegations />,
              },

              {
                path: "information",
                element: <CompanyInformation />,
              },
              {
                path: "candidates",
                element: <CompanyCandidates />,
              },
            ],
          },
          {
            path: "account-creation",
            element: <CompanyAccountCreation />,
          },
        ],
      },
      {
        path: "/forgotten",
        children: [
          {
            path: "password",
            children: [
              {
                path: "ask",
                element: <ForgottenPassword />,
              },
              {
                path: "reset",
                element: <PasswordChange />,
              },
            ],
          },
          {
            path: "email",
            children: [
              {
                path: "ask",
                element: <ForgottenEmail />,
              },
            ],
          },
        ],
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ];

    if (["staging", "development"].includes(import.meta.env.VITE_MODE)) {
      routes.push({
        path: "/",
        element: <Welcome />,
      });

      routes.push({
        path: "/seed-data",
        element: <SeedData />,
      });
    }

    return createBrowserRouter(routes);
  });

  const { navigate } = router;

  const disconnect = useCallback(() => {
    logout();
    toast.info("Session expirée", {
      description: "Reconnectez vous pour accéder à votre compte.",
    });
    navigate(ROUTE.connectionHome());
  }, [logout, navigate]);

  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      (response) => response,
      async (error: AxiosError) => {
        if (error.response?.status === 401) {
          if (refreshing) return;
          if (!session) return Promise.reject(error);
          if (session.refreshTokenExpirationTime < Date.now()) {
            disconnect();
            return Promise.reject(error);
          }

          setRefreshing(true);

          try {
            const { data: loginResponse } = await axios.post<LoginResponse>(
              ENDPOINT.auth.postRefresh()
            );

            // update session
            login(session.email, loginResponse);

            setRefreshing(false);

            const newConfig = error.config as AxiosRequestConfig;
            if (!newConfig.headers) newConfig.headers = {};
            newConfig.headers[
              "Authorization"
            ] = `Bearer ${loginResponse.token}`;

            return axios.request(newConfig);
          } catch {
            disconnect();
            setRefreshing(false);
          }
        }

        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(interceptor); // Cleanup interceptor on unmount
    };
  }, [disconnect, login, refreshing, session]);

  const [queryClient] = useState(() => new QueryClient());

  return (
    <StrictMode>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
        <Toaster />
      </QueryClientProvider>
    </StrictMode>
  );
};

export default AppProviders;
