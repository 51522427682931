import HelloPageLayout from "../templates/hello-page";

// Extract and format the admin data from environment variables
const adminEmails: string[] =
  import.meta.env.VITE_ADMIN_EMAILS?.split(" ") || [];
const adminPasswords: string[] =
  import.meta.env.VITE_ADMIN_PASSWORDS?.split(" ") || [];

// Combine emails and passwords into a list of admin objects
const admins = adminEmails.map((email, index) => ({
  email,
  password: adminPasswords[index] || "N/A",
}));

const SeedData = () => {
  return (
    <HelloPageLayout className="max-w-3xl">
      <div>
        <h1 className="text-3xl font-extrabold text-center mb-6">
          Données de test
        </h1>

        <section>
          <h2 className="text-2xl font-semibold mt-6 mb-4 border-b-2 border-gray-200">
            Admins
          </h2>
          <div className="grid grid-cols-1 gap-4">
            {admins.map((admin, index) => (
              <div key={index} className="p-4 rounded-lg bg-blue-50 shadow-md">
                <p>
                  <span className="font-semibold">Email:</span> {admin.email}
                </p>
                <p>
                  <span className="font-semibold">Password:</span>{" "}
                  {admin.password}
                </p>
              </div>
            ))}
          </div>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mt-6 mb-4 border-b-2 border-gray-200">
            Companies
          </h2>
          <div className="grid grid-cols-1 gap-4">
            {[
              { email: "antoinet.galadrim@gmail.com", key: "Password123!" },
            ].map((company, index) => (
              <div key={index} className="p-4 rounded-lg bg-green-50 shadow-md">
                <p>
                  <span className="font-semibold">Email:</span> {company.email}
                </p>
                <p>
                  <span className="font-semibold">Password:</span> {company.key}
                </p>
              </div>
            ))}
          </div>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mt-6 mb-4 border-b-2 border-gray-200">
            Candidates
          </h2>
          <div className="grid grid-cols-1 gap-4">
            {[{ email: "test@example.us", key: "Password123!" }].map(
              (candidate, index) => (
                <div
                  key={index}
                  className="p-4 rounded-lg bg-purple-50 shadow-md"
                >
                  <p>
                    <span className="font-semibold">Email:</span>{" "}
                    {candidate.email}
                  </p>
                  <p>
                    <span className="font-semibold">Password:</span>{" "}
                    {candidate.key}
                  </p>
                </div>
              )
            )}
          </div>
        </section>
      </div>
    </HelloPageLayout>
  );
};

export default SeedData;
