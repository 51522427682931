import { LoadingSpinner } from "@/components/molecules/LoadingSpinner";
import { Button } from "@atoms/Button";
import { ArrowLeft } from "lucide-react";

interface NextStepButtonProps {
  onPrevious: () => void;
  onNext: () => void;
  pending?: boolean;
  nextDisabled?: boolean;
  nextButtonText?: string;
}

const NextStepButton = ({
  onNext,
  onPrevious,
  pending = false,
  nextDisabled = false,
  nextButtonText = "Étape suivante",
}: NextStepButtonProps) => {
  return (
    <div className="flex flex-col self-stretch gap-8 flex-start">
      <div className="flex flex-row gap-2 self-stretch">
        <Button
          type="button"
          variant="white"
          className="w-1/4 gap-1 "
          onClick={onPrevious}
          disabled={pending}
        >
          <ArrowLeft className="w-5 h-5" />
          {"Étape précédente"}
        </Button>
        <Button
          type="submit"
          variant="default"
          className="w-3/4"
          onClick={onNext}
          disabled={nextDisabled || pending}
        >
          {pending ? <LoadingSpinner /> : nextButtonText}
        </Button>
      </div>
    </div>
  );
};

export default NextStepButton;
