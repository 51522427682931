import { useCandidateCertificatesQuery } from "@/api/candidate/candidate";
import { Badge } from "@/components/atoms/Badge";
import { Button } from "@/components/atoms/Button";
import CertificateStatusBadge from "@/components/molecules/Badges/CertificateStatusBadge";
import { LoadingSpinner } from "@/components/molecules/LoadingSpinner";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/molecules/Table";
import { PerimeterUtil } from "@/lib/perimeter-util";
import { cn } from "@/lib/utils";
import { format } from "date-fns";
import { Link } from "react-router-dom";

interface CertificatesProps {
  openCertificatePopup: () => void;
}

const Certificates = ({ openCertificatePopup }: CertificatesProps) => {
  const { isLoading, data, isError } = useCandidateCertificatesQuery();

  if (isLoading || isError)
    return (
      <LoadingSpinner
        isLoading={isLoading}
        loadingMessage="Chargement de vos certificats"
        isError={isError}
        errorMessage="Erreur lors du chargement de vos certificats"
      />
    );

  return (
    <div className="flex flex-col w-full relative">
      <Button
        className="z-10 absolute -top-16 right-0"
        onClick={openCertificatePopup}
      >
        Soumettre un certificat
      </Button>
      <div className="rounded-lg overflow-hidden border border-gray-200">
        <Table className="text-gray-500 text-sm font-normal leading-6">
          <TableHeader className="gap-3 items-center bg-gray-50">
            <TableRow>
              {[
                "Date de dêpot",
                "Statut",
                "Pays",
                "n° certificat",
                "Fin de validité",
                "Périmètre",
              ].map((text, i) => (
                <TableHead key={i}>
                  <div className="flex flex-row items-center gap-3">{text}</div>
                </TableHead>
              ))}
              <TableHead />
            </TableRow>
          </TableHeader>
          <TableBody>
            {data?.map((certificate, i) => (
              <TableRow
                className={cn({
                  "bg-white": i % 2 === 0,
                  "bg-gray-50": i % 2 !== 0,
                })}
                key={i}
              >
                <TableCell className="text-gray-500 ">
                  {format(certificate.expirationDate, "dd/MM/yyyy")}
                </TableCell>
                <TableCell className="w-full">
                  <CertificateStatusBadge
                    expirationDate={certificate.expirationDate}
                    status={certificate.status}
                  />
                </TableCell>
                <TableCell>{certificate.country}</TableCell>
                <TableCell>{certificate.number}</TableCell>
                <TableCell>
                  {format(certificate.expirationDate, "dd/MM/yyyy")}
                </TableCell>
                <TableCell>
                  <Badge variant="white">
                    {PerimeterUtil.toCode(certificate.perimeter)}
                  </Badge>
                </TableCell>
                <TableCell>
                  <Button variant="blueLink">
                    <Link
                      to={certificate.file.signedAccessKey as string}
                      target="_blank"
                      download
                    >
                      Télécharger
                    </Link>
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default Certificates;
