import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/atoms/HoverCard";
import { HelpCircle } from "lucide-react";

const UnmodifiableHoverCard = () => {
  return (
    <HoverCard openDelay={0} closeDelay={0}>
      <HoverCardTrigger className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400">
        <HelpCircle className="w-5 h-5" onClick={(e) => e.stopPropagation()} />
      </HoverCardTrigger>
      <HoverCardContent className="z-50 border-gray-200 border bg-gray-50 p-2 rounded-lg max-w-md">
        Ces informations ne sont pas modifiables directement depuis votre
        profil. Pour toute demande de modification, contactez le CIFMD.
      </HoverCardContent>
    </HoverCard>
  );
};

export default UnmodifiableHoverCard;
