import { ExamOptionsDto } from "@/api/exam-registration/dto/exam-options.dto";
import { useOutletContext } from "react-router-dom";

export enum RegisteringStep {
  CHECK_INFO = 0,
  REGISTER = 1,
  PRICING = 2,
  PAYEMENT = 3,
  CERTIFICATE = 4,
  ACKNOWLEDGEMENT = 5,
}

export type RegisterContext = {
  onNextStep: (registeringStep: RegisteringStep, goNext: boolean) => void;
  registrationDraft: ExamOptionsDto;
  changeDraft: (newOptions: ExamOptionsDto) => void;
  pending: boolean;
};

export const useRegisterContext = () => useOutletContext<RegisterContext>();
