import { ExamStatus } from "@/api/enums/exam-status.enum";
import { ExamType } from "@/api/enums/ExamType";
import { ExamDetailledtoClient } from "@/api/exam/dto/exam-detailled.dto";
import { useAllExamsDetailledQuery } from "@/api/exam/exam";
import ExamStatusBadge from "@/components/molecules/Badges/ExamStatusBadge";
import ExamTypeBadge from "@/components/molecules/Badges/ExamTypeBadge";
import SmartPagination from "@/components/molecules/SmartPagination";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/molecules/Table";
import { ROUTE } from "@/constants/routes";
import usePersistent from "@/hooks/use-persistent";
import { cn } from "@/lib/utils";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { SessionsTab } from "../sessions-tab.enum";
import PercentageProgress from "./PercentageProgress";

const sessionTypeFilter = (
  { type }: ExamDetailledtoClient,
  sessionTypeTab: ExamType | "all"
) => sessionTypeTab === "all" || sessionTypeTab === type;

const tabFilter = (
  { status, registeringEndDate }: ExamDetailledtoClient,
  tab: SessionsTab
) => {
  if (tab === SessionsTab.All) return true;

  switch (tab) {
    case SessionsTab.Open:
      return (
        status === ExamStatus.INCOMING &&
        new Date(registeringEndDate) > new Date()
      );
    case SessionsTab.Closed:
      return (
        status === ExamStatus.INCOMING &&
        new Date(registeringEndDate) < new Date()
      );
    case SessionsTab.Corrected:
      return status === ExamStatus.CORRECTED;
    case SessionsTab.NotesGenerated:
      return status === ExamStatus.NOTES_GENERATED;
    case SessionsTab.CertificatesGenerated:
      return status === ExamStatus.CERTIFICATES_GENERATED;
    default:
      return false;
  }
};

const searchTextFilter = (
  { number }: ExamDetailledtoClient,
  searchText?: string
) => !searchText || number.indexOf(searchText) >= 0;

interface SessionTableProps {
  tab: SessionsTab;
  sessionTypeTab: ExamType | "all";
  searchText?: string;
}

const columnHelper = createColumnHelper<ExamDetailledtoClient>();

const SessionsTable = ({
  sessionTypeTab,
  tab,
  searchText,
}: SessionTableProps) => {
  const [pageIndex, setPageIndex] = usePersistent("sessions-page-index", 0);
  const navigate = useNavigate();

  const examsQuery = useAllExamsDetailledQuery();

  const columns = useMemo(
    () => [
      columnHelper.accessor("number", {
        id: "number",
        header: "N° Session",
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor("type", {
        id: "type",
        header: "Type",
        cell: (info) => <ExamTypeBadge examType={info.getValue()} />,
      }),
      columnHelper.accessor("registeringEndDate", {
        id: "registeringEndDate",
        header: "Clôt. insc.",
        cell: (info) => new Date(info.getValue()).toLocaleDateString("fr-FR"),
      }),
      columnHelper.accessor("date", {
        id: "date",
        header: "Date exam.",
        cell: (info) => new Date(info.getValue()).toLocaleDateString("fr-FR"),
      }),
      columnHelper.accessor(
        ({ status, registeringEndDate }) => ({ status, registeringEndDate }),
        {
          id: "status",
          header: "Statut",
          cell: (info) => {
            const { registeringEndDate, status } = info.getValue();
            return (
              <ExamStatusBadge
                registeringEndDate={new Date(registeringEndDate)}
                status={status}
              />
            );
          },
        }
      ),
      columnHelper.accessor(
        ({ registeredCount, preRegisteredCount }) =>
          preRegisteredCount - registeredCount,
        {
          id: "preRegistered",
          header: "Pré-inscrits",
          cell: (info) => info.getValue(),
        }
      ),
      // pre-registered count stores all registered candidates,
      // whether they are registered or not,
      // which is what must be shown here
      columnHelper.accessor("preRegisteredCount", {
        id: "registered",
        header: "Inscrits",
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor(
        ({ preRegisteredCount, registeredCount }) =>
          preRegisteredCount === 0
            ? NaN
            : (registeredCount / preRegisteredCount) * 100,
        {
          id: "registeredPercentage",
          header: "% inscrits",
          cell: (info) => <PercentageProgress percentage={info.getValue()} />,
        }
      ),
      columnHelper.accessor(
        ({ successCount, registeredCount, status }) =>
          status !== ExamStatus.INCOMING && registeredCount !== 0
            ? (successCount / registeredCount) * 100
            : NaN,
        {
          id: "successPercentage",
          header: "% réussite",
          cell: (info) => <PercentageProgress percentage={info.getValue()} />,
        }
      ),
    ],
    []
  );

  const data = useMemo(
    () =>
      examsQuery.data
        ?.filter(
          (exam) =>
            sessionTypeFilter(exam, sessionTypeTab) &&
            tabFilter(exam, tab) &&
            searchTextFilter(exam, searchText)
        )
        .sort(
          ({ date: date0 }, { date: date1 }) =>
            new Date(date1).getTime() - new Date(date0).getTime()
        ) ?? [],
    [tab, sessionTypeTab, searchText, examsQuery.data]
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="flex flex-col w-full gap-5">
      <div className="rounded-lg overflow-hidden border border-gray-200">
        <Table className="text-gray-600 font-medium text-sm leading-6 rounded-lg">
          <TableHeader className="items-center bg-gray-50">
            <TableRow>
              {table.getHeaderGroups()[0].headers.map((header) => (
                <TableHead
                  key={header.id}
                  style={{ width: `${header.getSize()}px` }}
                >
                  <div className="flex flex-row items-center gap-3 text-xs">
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </div>
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows.map((row, i) => (
              <TableRow
                key={row.id}
                className={cn({ "bg-gray-50": i % 2 }, "cursor-pointer")}
                onClick={() =>
                  navigate(ROUTE.admin.dashboard.session.root(row.original.id))
                }
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id} className="py-4">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <SmartPagination
        currentPage={pageIndex}
        onPageChange={(newPageIndex) => setPageIndex(newPageIndex)}
        totalPages={0}
      />
    </div>
  );
};

export default SessionsTable;
