import { replaceNullsWith } from "@/api/app";
import { useChangeEmailMutation } from "@/api/auth/auth";
import {
  useChangeCandidateinfoMutation,
  useGetCandidateinfoQuery,
} from "@/api/candidate/candidate";
import { keyFactory } from "@/api/keyFactory";
import { Button } from "@/components/atoms/Button";
import { Form } from "@/components/atoms/Form";
import { AddressFields } from "@/components/molecules/AddressForm/AddressFields";
import AddressForm from "@/components/molecules/AddressForm/AddressForm";
import {
  addressDefaultValues,
  optionnalAddressSchema,
} from "@/components/molecules/AddressForm/AddressFormSchema";
import DisconnectPrompt from "@/components/molecules/DisconnectPrompt";
import ErrorDisplay from "@/components/molecules/ErrorDisplay";
import { QueryLoadingSpinner } from "@/components/molecules/LoadingSpinner";
import ModifiableForm from "@/components/molecules/ModifiableForm";
import { PersonnalInfoField } from "@/components/molecules/PersonnalInfoForm/PersonnalInfoFields";
import PersonnalInformationForm from "@/components/molecules/PersonnalInfoForm/PersonnalInfoForm";
import {
  emailDefaultValues,
  emailSchema,
  personnalInfoDefaultValues,
  personnalInfoSchema,
} from "@/components/molecules/PersonnalInfoForm/PersonnalInfoFormSchema";
import { ROUTE } from "@/constants/routes";
import { useAuthStore } from "@/hooks/AuthStore";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { HttpStatusCode } from "axios";
import _ from "lodash";
import { Edit } from "lucide-react";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";

const accountModificationSchema = z.object({
  address: optionnalAddressSchema,
  personnalInfo: personnalInfoSchema,
  credentials: emailSchema,
});

const PersonnalInformation = () => {
  const queryClient = useQueryClient();
  const [errorMsg, setErrorMsg] = useState("");
  const [PIIsModifiable, setPIIsModifiable] = useState(false);
  const [addressIsModifiable, setAddressIsModifiable] = useState(false);
  const { session, setNewEmail } = useAuthStore();
  const [disconnectsOpen, setDisconnectsOpen] = useState(false);

  /////////////
  // api calls
  /////////////

  const getCandidateQuery = useGetCandidateinfoQuery({
    staleTime: Infinity,
  });

  const changeCandidateInfoMutation = useChangeCandidateinfoMutation({
    onSuccess: async () => {
      setErrorMsg("");

      toast.success("Informations modifiées avec succès");

      queryClient.invalidateQueries({
        queryKey: keyFactory.candidate.info(),
      });

      setAddressIsModifiable(false);
      setPIIsModifiable(false);
    },
    onError: (error) => {
      switch (error.status) {
        case HttpStatusCode.InternalServerError:
          setErrorMsg(
            "Le serveur a rencontré une erreur lors de la modification de vos informations."
          );
          return;

        default:
          setErrorMsg(
            "Une erreur est survenue lors de la modification de vos informations."
          );
          return;
      }
    },
  });

  const changeUserEmailMutation = useChangeEmailMutation({
    onSuccess: () => {
      const { credentials, ...pi } = watch();
      changeCandidateInfoMutation.mutate(pi);
    },
  });

  /////////////
  // form
  /////////////

  const form = useForm<z.infer<typeof accountModificationSchema>>({
    resolver: zodResolver(accountModificationSchema),
    defaultValues: {
      address: addressDefaultValues,
      personnalInfo: personnalInfoDefaultValues,
      credentials: emailDefaultValues,
    },
  });

  const { data: candidateInfo } = getCandidateQuery;
  const { reset, watch } = form;

  const resetForm = useCallback(() => {
    const newState = watch();

    if (candidateInfo) {
      newState.address = _.merge(
        addressDefaultValues,
        replaceNullsWith(candidateInfo.address, "")
      );
      newState.personnalInfo = _.merge(
        personnalInfoDefaultValues,
        replaceNullsWith(candidateInfo.personnalInfo, "")
      );
    }

    if (session) {
      newState.credentials = {
        email: session?.email as string,
        emailConfirmation: session?.email as string,
      };
    }

    reset(newState);
  }, [candidateInfo, session, reset, watch]);

  useEffect(resetForm, [resetForm]);

  const onSubmit = (values: z.infer<typeof accountModificationSchema>) => {
    const { data, error, success } =
      accountModificationSchema.safeParse(values);

    if (!success) {
      console.error("can't parse form, aborting modification :", error);
      return;
    }

    const {
      credentials: { email: newEmail },
      ...pi
    } = data;

    if (newEmail !== session?.email) {
      changeUserEmailMutation.mutate({ newEmail });
      setNewEmail(newEmail);
    } else {
      changeCandidateInfoMutation.mutate(pi);
    }
  };

  if (getCandidateQuery.isLoading || getCandidateQuery.isError)
    return (
      <QueryLoadingSpinner
        errorMessage="Erreur lors du chargement de votre profil"
        loadingMessage="Chargement de votre profil..."
        queries={[getCandidateQuery]}
      />
    );

  return (
    <>
      <DisconnectPrompt
        isOpen={disconnectsOpen}
        close={() => setDisconnectsOpen(false)}
        redirectionPage={ROUTE.forgotten.password.ask()}
      />
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex flex-col gap-8"
        >
          <ModifiableForm
            setModifiable={(b: boolean) => {
              setAddressIsModifiable(false);
              resetForm();
              setPIIsModifiable(b);
            }}
            isModifiable={PIIsModifiable}
            onCancel={resetForm}
            modifyText="Modifier mes informations"
            saveText="Sauvegarder mes informations personnelles"
          >
            <PersonnalInformationForm
              hiddenFields={[
                PersonnalInfoField.Password,
                PersonnalInfoField.PasswordConfirmation,
              ]}
              disabledFields={
                PIIsModifiable
                  ? [PersonnalInfoField.FirstName, PersonnalInfoField.LastName]
                  : Object.values(PersonnalInfoField)
              }
              unmodifiableToolTipFields={
                PIIsModifiable
                  ? [PersonnalInfoField.FirstName, PersonnalInfoField.LastName]
                  : []
              }
            />
            <Button
              type="button"
              variant="outlineBlue"
              onClick={() => setDisconnectsOpen(true)}
            >
              <Edit className="w-4 h-4" />
              Modifier mon mot de passe
            </Button>
          </ModifiableForm>
          <ModifiableForm
            setModifiable={(b: boolean) => {
              setPIIsModifiable(false);
              resetForm();
              setAddressIsModifiable(b);
            }}
            isModifiable={addressIsModifiable}
            onCancel={resetForm}
            modifyText="Modifier mon adresse"
            saveText="Sauvegarder mon adresse"
          >
            <AddressForm
              fieldName="address"
              disabledFields={
                addressIsModifiable ? [] : Object.values(AddressFields)
              }
            />
          </ModifiableForm>
          {errorMsg && <ErrorDisplay msg={errorMsg} />}
        </form>
      </Form>
    </>
  );
};

export default PersonnalInformation;
