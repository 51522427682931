export enum PersonnalInfoField {
  Sex = "personnalInfo.sex",
  LastName = "personnalInfo.lastName",
  FirstName = "personnalInfo.firstName",
  Birthdate = "personnalInfo.birthdate",
  BirthCity = "personnalInfo.birthCity",
  BirthCountry = "personnalInfo.birthCountry",
  Nationality = "personnalInfo.nationality",
  HomePhone = "personnalInfo.homePhone",
  MobilePhone = "personnalInfo.mobilePhone",
  Email = "credentials.email",
  EmailConfirmation = "credentials.emailConfirmation",
  Password = "credentials.key",
  PasswordConfirmation = "credentials.keyConfirmation",
}

export interface Modifiers {
  disabledFields: PersonnalInfoField[];
  hiddenFields: PersonnalInfoField[];
  unmodifiableToolTipFields: PersonnalInfoField[];
}
