import { useReportRegistrationMutation } from "@/api/admin/admin";
import { CandidateRegistrationDtoClient } from "@/api/candidate/dto/candidate-registration.dto";
import { keyFactory } from "@/api/keyFactory";
import { Button } from "@/components/atoms/Button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/atoms/Dialog";
import { Form } from "@/components/atoms/Form";
import ExamDateCombobox from "@/components/molecules/ExamDateCombobox";
import { id } from "@/constants/zodTypes";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";

const reportSchema = z.object({
  newExamId: id(),
});

type ReportSchema = z.infer<typeof reportSchema>;

interface ReportDialogProps {
  isOpen: boolean;
  close: () => void;
  registration: CandidateRegistrationDtoClient;
  candidateId: number;
}

const ReportDialog = ({
  isOpen,
  close,
  registration,
  candidateId,
}: ReportDialogProps) => {
  const queryClient = useQueryClient();

  const reportMutation = useReportRegistrationMutation();

  const form = useForm<ReportSchema>({
    resolver: zodResolver(reportSchema),
    defaultValues: {
      newExamId: -1,
    },
  });

  const onSubmit = (values: ReportSchema) => {
    const { data, success, error } = reportSchema.safeParse(values);

    if (!success) {
      console.error(error);
      return;
    }

    reportMutation.mutate(
      {
        candidateId,
        registrationId: registration.id,
        ...data,
      },
      {
        onSuccess: () => {
          toast.success("Inscription reportée");
          queryClient.invalidateQueries({
            queryKey: keyFactory.admin.candidate.base(candidateId),
          });
          close();
        },
        onError: () => {
          toast.error("Echèc de la requête", {
            description: "Votre action n'a pas pu être prise en compte.",
          });
        },
      }
    );
  };

  return (
    <Dialog open={isOpen}>
      <DialogContent onClose={close}>
        <DialogHeader>
          <DialogTitle>Report de l'inscription</DialogTitle>
          <DialogDescription hidden={true}>
            Report de l'inscription
          </DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex flex-col gap-2"
          >
            <ExamDateCombobox
              registrationType={registration.type}
              fieldName="newExamId"
              forbiddenExamIds={new Set([registration.exam.id])}
            />
            <div className="flex flex-row gap-2">
              <Button
                variant="ghostWithBorders"
                type="button"
                onClick={close}
                className="w-full"
              >
                Annuler
              </Button>
              <Button
                type="submit"
                className="w-full"
                disabled={reportMutation.isPending}
              >
                {reportMutation.isPending
                  ? "En cours..."
                  : "Reporter l'inscription"}
              </Button>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default ReportDialog;
