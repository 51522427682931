import { removeNullsDeep } from "@/api/app";
import { useCandidateCertificatesQuery as useGetCandidateCertificatesQuery } from "@/api/candidate/candidate";
import { CertificateStatus } from "@/api/enums/CertificateStatus";
import { ExtensionType } from "@/api/enums/ExtensionType";
import { MerchandiseClass } from "@/api/enums/MerchandiseClass";
import { RegistrationType } from "@/api/enums/RegistrationType";
import { RenewingType } from "@/api/enums/RenewingType";
import { Perimeter, PerimeterUtil } from "@/lib/perimeter-util";
import { Form } from "@atoms/Form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  MerchandisesForm,
  PerimeterForm,
  TransportModesForm,
} from "@molecules/PerimeterForm/PerimeterForm";
import _ from "lodash";
import { Info } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import ExamDateCombobox from "../../../../../../molecules/ExamDateCombobox";
import NextStepButton from "../NextStepButtons";
import { RegisteringStep, useRegisterContext } from "../RegisterContext";
import CertificatesSwitches from "./CertificatesSwitches";
import ExtensionTypeButtons from "./ExtensionTypeButtons";
import { registerStepSchema } from "./RegisterStepSchema";
import RegistrationTypeRadio from "./RegistrationTypeRadio";
import RenewingTypeRadio from "./RenewingTypeRadio";
import UTCCombobox from "./UTCCombobox";

const WarningMessage = () => {
  return (
    <div className="flex flex-row p-4 items-start self-stretch gap-4 border rounded-md border-gray-300 bg-white shadow">
      <Info className="w-5 h-5 flex-shrink-0" />
      <div className="flex flex-col">
        <span className="font-bold">Attention</span>
        <span className="text-gray-600 font-normal">
          Votre choix de mode(s) de transport et de choix de la ou les classe(s)
          de marchandises dangereuses correspond à votre périmètre d'examen et
          de certificat. Nous vous invitons donc à bien vérifier l'adéquation de
          votre périmètre avec celui de votre ou vos formation(s), ainsi que
          celui de votre activité professionnelle.
        </span>
        <span className="text-brand-700 font-semibold">
          Si la date de clôture des inscriptions est dépassée, un changement ne
          sera plus possible.
        </span>
      </div>
    </div>
  );
};

const ExtensionMessage = () => {
  return (
    <div className="flex flex-row p-4 items-start self-stretch gap-4 border rounded-md border-gray-300 bg-white shadow">
      <Info className="w-5 h-5 flex-shrink-0" />
      <span className="font-bold">
        Vous êtes titulaire d'un certifcat en cours de validité et souhaitez son
        extension avec l'ajout d'un ou deux mode(s) de transport ou d'une{" "}
        <span className="text-brand-700">ou </span>
        plusieurs classe(s) de danger. Veuillez dans ce cas sélectionner le
        certificat associé.
      </span>
    </div>
  );
};

const RegisterStep = () => {
  const { changeDraft, onNextStep, registrationDraft, pending } =
    useRegisterContext();

  ////////////
  // api calls
  ////////////

  const certificatesQuery = useGetCandidateCertificatesQuery();

  ////////////
  // form
  ////////////

  const form = useForm<z.infer<typeof registerStepSchema>>({
    resolver: zodResolver(registerStepSchema),
    defaultValues: _.merge(
      {
        type: RegistrationType.INITIAL,
        initialExamId: -1,
        renewingExamId: -1,
        extensionExamId: -1,
        initialProperties: {
          utc: 1,
          perimeter: PerimeterUtil.emptyPerimeter(),
        },
        renewingProperties: {
          type: RegistrationType.RENEW,
          perimeter: PerimeterUtil.emptyPerimeter(),
          renewingCertificatesIDs: [],
          renewingType: RenewingType.SAME,
        },
        extensionProperties: {
          type: RegistrationType.EXTENSION,
          utc: 1,
          certificateId: [],
          extension: {
            extensionType: ExtensionType.MERCHANDISES,
            extendedMerchandiseClasses: [],
            extendedTransportModes: [],
          },
        },
      },
      removeNullsDeep({
        type: registrationDraft.type,
        initialExamId:
          registrationDraft.type === RegistrationType.INITIAL
            ? registrationDraft.examId
            : -1,
        renewingExamId:
          registrationDraft.type === RegistrationType.RENEW
            ? registrationDraft.examId
            : -1,
        extensionExamId:
          registrationDraft.type === RegistrationType.EXTENSION
            ? registrationDraft.examId
            : -1,
        initialProperties: registrationDraft.initialProperties,
        renewingProperties: registrationDraft.renewingProperties,
        extensionProperties: {
          utc: registrationDraft.extensionProperties?.utc,
          certificateId: [registrationDraft.extensionProperties?.certificateId],
          extension: {
            extensionType: registrationDraft.extensionProperties?.extensionType,
            extendedMerchandiseClasses:
              registrationDraft.extensionProperties?.extendedMerchandiseClasses,
            extendedTransportModes:
              registrationDraft.extensionProperties?.extendedTransportModes,
          },
        },
      })
    ),
  });

  const onSubmit = (
    values: z.infer<typeof registerStepSchema>,
    goNext: boolean
  ) => {
    const { data, error, success } = registerStepSchema.safeParse(values);

    if (!success) {
      console.error("can't parse form, aborting modification :", error);
      return;
    }

    changeDraft({
      ...(data.type === RegistrationType.INITIAL && {
        examId: data.initialExamId,
        initialProperties: data.initialProperties,
      }),
      ...(data.type === RegistrationType.EXTENSION && {
        examId: data.extensionExamId,
        extensionProperties: {
          extendedMerchandiseClasses: [],
          extendedTransportModes: [],
          utc: data.extensionProperties.utc,
          certificateId: data.extensionProperties.certificateId[0],
          ...data.extensionProperties.extension,
        },
      }),
      ...(data.type === RegistrationType.RENEW && {
        examId: data.renewingExamId,
        renewingProperties: data.renewingProperties,
      }),
      type: data.type,
    });
    onNextStep(RegisteringStep.REGISTER, goNext);
  };

  const { setValue, watch } = form;
  const registrationType = watch("type");

  /// renewal update mechanism

  const renewingType = watch("renewingProperties.renewingType");
  const renewingCertificatesIDs = watch(
    "renewingProperties.renewingCertificatesIDs"
  );
  const [renewalAllowedPerimeter, setRenewalAllowedPerimeter] =
    useState<Perimeter>({ merchandises: [], transportModes: [] });

  // auto-select allowed fields
  useEffect(() => {
    const newAllowedPerimeter = PerimeterUtil.union(
      ...(certificatesQuery.data
        ?.filter(({ id }) => renewingCertificatesIDs.includes(id as number))
        .map(({ perimeter }) => perimeter) || [])
    );

    if ([RenewingType.SAME, RenewingType.FUSION].includes(renewingType)) {
      setValue("renewingProperties.perimeter", newAllowedPerimeter);
    } else {
      const restrictedPerimeter = PerimeterUtil.restrict(
        watch("renewingProperties.perimeter"),
        newAllowedPerimeter
      );
      setValue("renewingProperties.perimeter", restrictedPerimeter);
    }

    setRenewalAllowedPerimeter(newAllowedPerimeter);
  }, [
    renewingType,
    renewingCertificatesIDs,
    setValue,
    watch,
    certificatesQuery.data,
  ]);

  // avoid multiple selections if forbidden
  useEffect(() => {
    if ([RenewingType.SAME, RenewingType.REDUCED].includes(renewingType)) {
      setValue(
        "renewingProperties.renewingCertificatesIDs",
        watch("renewingProperties.renewingCertificatesIDs").slice(0, 1)
      );
    }
  }, [renewingType, setValue, watch]);

  /// extension update mechanism

  const extensionType = watch("extensionProperties.extension.extensionType");
  const extensionCertificateID = watch("extensionProperties.certificateId");
  const [extensionMinimumPerimeter, setExtensionMinimumPerimeter] =
    useState<Perimeter>(PerimeterUtil.fullPerimeter());

  useEffect(() => {
    const newMinimumPerimeter =
      certificatesQuery.data?.find(({ id }) =>
        extensionCertificateID.includes(id as number)
      )?.perimeter || PerimeterUtil.fullPerimeter();

    if (extensionType === ExtensionType.MERCHANDISES)
      setValue(
        "extensionProperties.extension.extendedMerchandiseClasses",
        watch(
          "extensionProperties.extension.extendedMerchandiseClasses"
        ).filter((val) => !newMinimumPerimeter.merchandises.includes(val))
      );
    else
      setValue(
        "extensionProperties.extension.extendedTransportModes",
        watch("extensionProperties.extension.extendedTransportModes").filter(
          (val) => !newMinimumPerimeter.transportModes.includes(val)
        )
      );

    setExtensionMinimumPerimeter(newMinimumPerimeter);
  }, [
    extensionType,
    extensionCertificateID,
    setValue,
    watch,
    certificatesQuery.data,
  ]);

  const validCertificates =
    certificatesQuery.data?.filter(
      ({ expirationDate, status }) =>
        new Date(expirationDate) >= new Date() &&
        (status as CertificateStatus) === CertificateStatus.VALIDATED
    ) ?? [];

  return (
    <div className="flex flex-col items-start w-full max-w-4xl">
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit((data) => onSubmit(data, true))}
          className="flex w-full flex-col space-y-8"
        >
          <div className="flex p-4 flex-col items-start gap-8 self-stretch rounded-md border-gray-200 bg-white border w-full">
            <h1>Inscription à l'examen</h1>
            <RegistrationTypeRadio />
            {registrationType === RegistrationType.INITIAL && (
              <>
                <ExamDateCombobox
                  fieldName="initialExamId"
                  registrationType={RegistrationType.INITIAL}
                />
                <UTCCombobox fieldName="initialProperties.utc" />
                <PerimeterForm
                  fieldName="initialProperties.perimeter"
                  hiddenPerimeter={{
                    merchandises: [MerchandiseClass.HYDROCARBONS],
                    transportModes: [],
                  }} // hydrocarbons not proposed anymore in initial
                />
              </>
            )}
            {registrationType === RegistrationType.RENEW && (
              <>
                <ExamDateCombobox
                  fieldName="renewingExamId"
                  registrationType={RegistrationType.RENEW}
                />
                <RenewingTypeRadio />
                {certificatesQuery.isLoading ? (
                  <div>Chargement des certificats...</div>
                ) : certificatesQuery.isError ? (
                  <div>Erreur lors du chargement des certificats.</div>
                ) : (
                  <>
                    <CertificatesSwitches
                      certificates={validCertificates}
                      unique={[
                        RenewingType.SAME,
                        RenewingType.REDUCED,
                      ].includes(renewingType)}
                      fieldName="renewingProperties.renewingCertificatesIDs"
                      titleElement={<h2>Vos certificat(s) à renouveler</h2>}
                    />
                    <PerimeterForm
                      fieldName="renewingProperties.perimeter"
                      disabledPerimeter={
                        [RenewingType.SAME, RenewingType.FUSION].includes(
                          renewingType
                        )
                          ? PerimeterUtil.fullPerimeter()
                          : PerimeterUtil.emptyPerimeter()
                      }
                      displayedPerimeter={renewalAllowedPerimeter}
                      uncheckedAreRed={renewingType === RenewingType.REDUCED}
                    />
                  </>
                )}
              </>
            )}
            {registrationType === RegistrationType.EXTENSION && (
              <>
                <ExamDateCombobox
                  fieldName="extensionExamId"
                  registrationType={RegistrationType.EXTENSION}
                />
                <UTCCombobox fieldName="extensionProperties.utc" />
                <CertificatesSwitches
                  certificates={validCertificates}
                  unique={true}
                  fieldName="extensionProperties.certificateId"
                  titleElement={
                    <>
                      <h2>Extension de certificat</h2>
                      <ExtensionMessage />
                    </>
                  }
                />
                <ExtensionTypeButtons />
                {extensionType === ExtensionType.MERCHANDISES && (
                  <MerchandisesForm
                    fieldName="extensionProperties.extension.extendedMerchandiseClasses"
                    hiddenMerchandises={[
                      ...extensionMinimumPerimeter.merchandises,
                      MerchandiseClass.HYDROCARBONS,
                    ]}
                  />
                )}
                {extensionType === ExtensionType.TRANSPORTS && (
                  <TransportModesForm
                    fieldName="extensionProperties.extension.extendedTransportModes"
                    hiddenTransportModes={
                      extensionMinimumPerimeter.transportModes
                    }
                  />
                )}
              </>
            )}
            <WarningMessage />
          </div>
          <NextStepButton
            onNext={form.handleSubmit((data) => onSubmit(data, true))}
            onPrevious={() => onNextStep(RegisteringStep.REGISTER, false)}
            pending={pending}
          />
        </form>
      </Form>
    </div>
  );
};

export default RegisterStep;
