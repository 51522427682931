import { CandidateRegistrationDtoClient } from "@/api/candidate/dto/candidate-registration.dto";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/atoms/HoverCard";
import PreTestStatusBadge from "@/components/molecules/Badges/PreTestStatusBadge";
import { Card, CardTitle } from "@/components/molecules/RegistrationCard/Card";
import { Info } from "lucide-react";

interface PreTestCardProps {
  registration: CandidateRegistrationDtoClient;
}

const PreTestCard = ({ registration }: PreTestCardProps) => {
  return (
    <Card>
      <CardTitle>Test préalable</CardTitle>
      <HoverCard openDelay={0} closeDelay={0}>
        <HoverCardTrigger>
          <Info className="w-4 h-4 text-gray-500" />
        </HoverCardTrigger>
        <HoverCardContent className="flex flex-col gap-2 w-80">
          <span className="text-gray-900 font-semibold">
            Une convocation au test sera envoyée
          </span>
          <span className="text-gray-600 font-medium">
            par mail, par Managexam, après la date de clôture des inscriptions.
          </span>
        </HoverCardContent>
      </HoverCard>
      <PreTestStatusBadge status={registration.preTestStatus} />
    </Card>
  );
};

export default PreTestCard;
