import SideBar from "@/components/molecules/SideBar";
import { ROUTE } from "@/constants/routes";
import useRoleBasedRedirect from "@/hooks/RoleBasedRedirect";
import { cn } from "@/lib/utils";
import { Calendar, GraduationCap, SquareUser } from "lucide-react";
import { NavLink, Outlet } from "react-router-dom";
import { Role } from "../../../../api/enums/Role";

const SidBarExpandedButtons = () => {
  return (
    <div className="flex flex-col gap-1 self-stretch h-full">
      {[
        {
          children: (
            <>
              <SquareUser className="w-4 h-4" />
              Candidats
            </>
          ),
          to: ROUTE.admin.dashboard.candidates(),
        },
        {
          children: (
            <>
              <Calendar className="w-4 h-4" />
              Sessions
            </>
          ),
          to: ROUTE.admin.dashboard.sessions.root(),
        },
        {
          children: (
            <>
              <GraduationCap className="w-4 h-4" />
              Certificats étrangers
            </>
          ),
          to: ROUTE.admin.dashboard.foreignCertificates(),
        },
      ].map(({ children, to }) => (
        <NavLink
          key={to}
          to={to}
          className={({ isActive }) =>
            cn(
              "flex flex-row items-center gap-2.5 rounded-lg py-2 px-4 text-sm font-medium transition-colors duration-200",
              {
                "text-brand-700 bg-gray-100 hover:bg-blue-50 hover:text-blue-700":
                  isActive,
                "text-gray-600 hover:bg-gray-50 hover:text-gray-700": !isActive,
              }
            )
          }
        >
          {children}
        </NavLink>
      ))}
    </div>
  );
};

const Dashboard = () => {
  useRoleBasedRedirect(Role.ADMIN);

  return (
    <div className="flex flex-row h-screen">
      <SideBar expandedChildren={<SidBarExpandedButtons />} />
      <div className="flex-1 overflow-auto">
        <Outlet />
      </div>
    </div>
  );
};

export default Dashboard;
