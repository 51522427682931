import { useRegistrationQuery } from "@/api/admin/admin";
import { ExamDtoClient } from "@/api/dto/exam.dto";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/atoms/Collapsible";
import FileLink from "@/components/molecules/FileLink";
import { QueryLoadingSpinner } from "@/components/molecules/LoadingSpinner";
import { RegistrationUtil } from "@/lib/registration-util";
import { cn } from "@/lib/utils";
import { useState } from "react";
import DocumentTypeBadge from "./DocumentTypeBadge";

interface SessionDocumentsProps {
  candidateId: number;
  registrationId: number;
  isLast: boolean;
  exam: ExamDtoClient;
}

const SessionDocuments = ({
  isLast,
  candidateId,
  registrationId,
  exam,
}: SessionDocumentsProps) => {
  const registrationQuery = useRegistrationQuery(candidateId, registrationId, {
    enabled: false,
  });
  const [open, setOpen] = useState(false);

  const data = registrationQuery.data;

  const debitUnpayed = data
    ? RegistrationUtil.unpayedDebitNote(data)
    : undefined;
  const debitPayed = data ? RegistrationUtil.payedDebitNote(data) : undefined;
  const rows = [
    ...(debitPayed ? [debitPayed] : []),
    ...(debitUnpayed ? [debitUnpayed] : []),
    ...(data?.convocation ? [data.convocation] : []),
    ...(data?.examResult?.attendanceCertificate
      ? [data?.examResult?.attendanceCertificate]
      : []),
    ...(data?.examResult?.resultsDocument
      ? [data?.examResult?.resultsDocument]
      : []),
  ];

  return (
    <Collapsible
      open={open}
      onOpenChange={(open) => {
        if (open && !registrationQuery.data) {
          registrationQuery.refetch();
        }
        setOpen(open);
      }}
    >
      <CollapsibleTrigger
        className={cn(
          "flex flex-row items-center bg-gray-50 border border-gray-200 w-full text-gray-900",
          { "rounded-b-lg": isLast && !open, "mt-3": open, "border-t-0": !open }
        )}
      >
        <span className="flex px-4 py-3 w-1/3">{exam.number}</span>
        <span className="flex px-4 py-3 w-1/3">
          {new Date(exam.date).toLocaleDateString("fr-FR")}
        </span>
      </CollapsibleTrigger>
      <CollapsibleContent
        className={cn("flex flex-col w-full", {
          "mb-3 border-b": open,
        })}
      >
        <div className="flex flex-row items-center w-full border-b border-x border-gray-200 bg-gray-50 text-gray-500">
          <span className="flex px-4 py-3 w-1/3">Document</span>
          <span className="flex px-4 py-3 w-1/3">Date du dépôt</span>
        </div>
        {registrationQuery.data ? (
          rows.map((file, i) => (
            <div
              key={i}
              className={cn(
                "flex flex-row items-center w-full border-b border-x border-gray-200",
                {
                  "border-b-0": i === rows.length - 1,
                  "bg-gray-50": i % 2 === 0,
                }
              )}
            >
              <div className="w-1/3 py-3 px-4">
                <DocumentTypeBadge type={file.type} />
              </div>
              <span className="w-1/3 py-3 px-4">
                {file.createdAt &&
                  new Date(file.createdAt).toLocaleDateString("fr-FR")}
              </span>
              <div className="flex w-1/3 py-3 px-4 justify-end">
                <FileLink file={file}>Télécharger</FileLink>
              </div>
            </div>
          ))
        ) : (
          <QueryLoadingSpinner
            queries={[registrationQuery]}
            loadingMessage="Chargement des documents de la session..."
            errorMessage="Erreur lors du chargement des documents de la session"
          />
        )}
      </CollapsibleContent>
    </Collapsible>
  );
};

export default SessionDocuments;
