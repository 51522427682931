export enum FormationType {
  INSTITUTE = "INSTITUTE",
  COMPANY = "COMPANY",
  ON_HONOR = "ON_HONOR",
}

export const FormationTypeDescriptions = {
  [FormationType.INSTITUTE]:
    "Formation dispensée par un Organisme de Formation (OF)",
  [FormationType.COMPANY]:
    "Formation dispensée par votre entreprise, en interne",
  [FormationType.ON_HONOR]:
    "Attestation sur l'honneur d'un maintien à jour des connaissances réglementaires couvrant la version applicable des Règlements à la date de l'examen.",
};
