import useRoleBasedRedirect from "@/hooks/RoleBasedRedirect";
import HelloPageLayout from "@templates/hello-page";
import { Link } from "react-router-dom";
import { useHelloWorldQuery } from "../../api/app";
import { ROUTE } from "../../constants/routes";

const Welcome = () => {
  const helloWorldQuery = useHelloWorldQuery();

  useRoleBasedRedirect(null);

  return (
    <HelloPageLayout useBackButton={false} className="max-w-xl">
      <div>
        <h1>Site de test.</h1>
        <br />
        Si cette page s'affiche, c'est que le serveur frontend est en ligne :)
      </div>
      <div>
        <h2>Test de la connexion</h2>
        Le serveur backend est-il en ligne ? →{" "}
        {helloWorldQuery.isLoading
          ? "Chargement..."
          : helloWorldQuery.isError
          ? "Erreur : " + helloWorldQuery.error.message
          : "Oui !"}
      </div>
      <div className="flex flex-col w-full w">
        <h2>Test des pages</h2>
        <ul className="flex flex-col gap-2 mt-3">
          {[
            { path: ROUTE.seedData(), msg: "Données de test" },
            { path: ROUTE.connectionHome(), msg: "Accueil" },
            { path: ROUTE.admin.login(), msg: "Login admin" },
            {
              path: ROUTE.candidate.dashboard.root(),
              msg: "Dashboard candidate",
            },
            { path: ROUTE.admin.dashboard.root(), msg: "Dashboard admin" },
          ].map(({ path, msg }) => (
            <li key={path} className="flex w-full items-center justify-center">
              <Link
                className="flex w-full border items-center justify-center rounded-lg bg-brand-500 border-brand-700 text-white hover:bg-brand-600 hover:text-white py-2"
                to={path}
              >
                {msg}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </HelloPageLayout>
  );
};

export default Welcome;
