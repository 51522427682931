import { email, optionalPhone, optionnalString } from "@/constants/zodTypes";
import { z } from "zod";

const delegationSchema = z.object({
  name: optionnalString(),
  surname: optionnalString(),
  email: email(),
  phoneNumber: optionalPhone(),
  companyName: optionnalString(),
});

const delegationDefaultValues = {
  name: "",
  surname: "",
  email: "",
  phoneNumber: "",
  companyName: "",
};

export { delegationDefaultValues, delegationSchema };
