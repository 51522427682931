import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/atoms/Form";
import { Input } from "@/components/atoms/Input";
import { PhoneInput } from "@/components/molecules/PhoneInput";
import useChildForm from "@/hooks/ChildForm";

interface DelegationFormProps {
  fieldName?: string;
}

const DelegationForm = ({ fieldName }: DelegationFormProps) => {
  const { transformPath, control } = useChildForm(fieldName);

  return (
    <>
      <FormField
        control={control}
        name={transformPath("email")}
        render={({ field }) => (
          <FormItem className="w-full">
            <FormLabel>Adresse email *</FormLabel>
            <FormControl>
              <Input placeholder={"-"} {...field} autoComplete="email" />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <div className="flex flex-wrap w-full gap-2">
        <FormField
          control={control}
          name={transformPath("name")}
          render={({ field }) => (
            <FormItem className="min-w-96 flex-grow">
              <FormLabel>Nom</FormLabel>
              <FormControl>
                <Input placeholder={"-"} {...field} autoComplete="name" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name={transformPath("surname")}
          render={({ field }) => (
            <FormItem className="min-w-96 flex-grow">
              <FormLabel>Prénom</FormLabel>
              <FormControl>
                <Input placeholder={"-"} {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
      <FormField
        control={control}
        name={transformPath("phoneNumber")}
        render={({ field }) => (
          <FormItem className="w-full">
            <FormLabel>Téléphone</FormLabel>
            <FormControl>
              <PhoneInput
                defaultCountry="FR"
                placeholder={"06 XX XX XX XX"}
                {...field}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={control}
        name={transformPath("companyName")}
        render={({ field }) => (
          <FormItem className="w-full">
            <FormLabel>Nom société</FormLabel>
            <FormControl>
              <Input placeholder={"-"} {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </>
  );
};

export default DelegationForm;
