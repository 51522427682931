import { FieldMandatoryType } from "./mandatory-type";

interface FieldWithOptionnalityProps {
  str: string;
  fieldMandatoryType: FieldMandatoryType;
}

export const FieldWithOptionnality = ({
  fieldMandatoryType,
  str,
}: FieldWithOptionnalityProps) => {
  switch (fieldMandatoryType) {
    case FieldMandatoryType.MANDATORY:
      return <>{str} *</>;
    case FieldMandatoryType.MANDATORY_ON_CERTIFICATE:
      return (
        <>
          {str} <span className="text-brand-600">*</span>
        </>
      );
    case FieldMandatoryType.OPTIONNAL:
      return <>str</>;
  }
};

export default FieldWithOptionnality;
