import { useCanRegisterQuery } from "@/api/exam-registration/exam-registration";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/components/atoms/Tabs";
import BlueNavLink from "@/components/molecules/BlueNavLink";
import { ROUTE } from "@/constants/routes";
import usePersistent from "@/hooks/use-persistent";
import { Button } from "@atoms/Button";
import { Search } from "lucide-react";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { MyExamsTab } from "./my-exams-tab.enum";
import ToCome from "./ToCome/ToCome";

const NoExams = () => {
  return (
    <div className="flex py-8 flex-col gap-4 self-stretch items-center rounded-lg bg-gray-100">
      <Search className="w-6 h-6 text-gray-400" />
      <span className="text-gray-800">Vous n'avez pas réalisé d'examen</span>
      <NavLink to={ROUTE.candidate.dashboard.exams.register.root()}>
        <Button className="flex px-3 py-2 mt-3 justify-center rounded-lg border border-brand-700 bg-brand-600 font-semibold text-sm">
          S'inscrire à une session d'examen
        </Button>
      </NavLink>
    </div>
  );
};

const Home = () => {
  const [exams] = useState(["hello"]);
  const [currentTab, setCurrentTab] = usePersistent(
    "my-exams-tab",
    MyExamsTab.ToCome
  );

  const canRegisterQuery = useCanRegisterQuery();

  return (
    <div className="flex flex-col items-start gap-6 self-stretch py-8 px-28">
      <div className="flex flex-row w-full justify-between items-center">
        <div className="flex flex-col items-start">
          <h1 className="text-2xl">Mes examens</h1>
          <span className="text-gray-600 text-sm font-">
            M'inscrire et visualiser mes résultats aux examens.
          </span>
        </div>
        {exams.length > 0 && (
          <BlueNavLink
            to={ROUTE.candidate.dashboard.exams.register.root()}
            // candidate can't register to multiple exams at the same time
            disabled={!canRegisterQuery.data}
          >
            S'inscrire à une session d'examen
          </BlueNavLink>
        )}
      </div>
      {exams.length === 0 && <NoExams />}
      {exams.length > 0 && (
        <Tabs
          value={currentTab}
          className="flex flex-col gap-6 w-full"
          onValueChange={(t) => setCurrentTab(t as MyExamsTab)}
        >
          <TabsList className="p-0.5 h-full w-fit">
            <TabsTrigger value={MyExamsTab.ToCome}>
              Mes examens à venir
            </TabsTrigger>
            <TabsTrigger value={MyExamsTab.DocumentsAndResults}>
              Mes documents et résultats
            </TabsTrigger>
          </TabsList>
          <TabsContent value={MyExamsTab.ToCome}>
            <ToCome />
          </TabsContent>
          <TabsContent value={MyExamsTab.DocumentsAndResults}>TODO</TabsContent>
        </Tabs>
      )}
    </div>
  );
};

export default Home;
