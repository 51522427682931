import { ExamRegistrationStatus } from "@/api/enums/exam-registration-status.enum";
import { Input } from "@/components/atoms/Input";
import { Tabs, TabsList, TabsTrigger } from "@/components/atoms/Tabs";
import usePersistent from "@/hooks/use-persistent";
import { CheckCircle2, User2, X } from "lucide-react";
import { useState } from "react";
import { useDebounce } from "use-debounce";
import { useSessionContext } from "../session-context";
import CandidatesTable from "./CandidatesTable";

const statusPerTab: {
  [key in ExamRegistrationStatus]: ExamRegistrationStatus[];
} = {
  [ExamRegistrationStatus.UNCOMPLETE]: [ExamRegistrationStatus.UNCOMPLETE],
  [ExamRegistrationStatus.COMPLETE]: [
    ExamRegistrationStatus.COMPLETE,
    ExamRegistrationStatus.DONE,
    ExamRegistrationStatus.WAITING_RESULTS,
  ],
  [ExamRegistrationStatus.WAITING_RESULTS]: [
    ExamRegistrationStatus.DONE,
    ExamRegistrationStatus.WAITING_RESULTS,
  ],
  [ExamRegistrationStatus.DONE]: [ExamRegistrationStatus.DONE],
};

const Candidates = () => {
  const [searchText, setSearchText] = useState("");
  const [debouncedSearchText] = useDebounce(searchText, 300);
  const [tab, setTab] = usePersistent<ExamRegistrationStatus | "none">(
    "admin-session-candidates-tab",
    "none"
  );
  const { exam } = useSessionContext();

  return (
    <div className="p-6 flex flex-col gap-4">
      <div className="flex flex-row gap-4">
        <div className="flex flex-col gap-2 py-4 px-6 border border-gray-200 rounded-lg w-full">
          <div className="flex flex-row justify-between">
            <span className="text-gray-600 font-medium">Nb candidats</span>
            <User2 className="text-brand-600" />
          </div>
          <span className="text-xl font-semibold text-gray-950">
            {exam.preRegisteredCount}
          </span>
        </div>
        <div className="flex flex-col gap-2 py-4 px-6 border border-gray-200 rounded-lg w-full">
          <div className="flex flex-row justify-between">
            <span className="text-gray-600 font-medium">Candidats validés</span>
            <CheckCircle2 className="text-brand-600" />
          </div>
          <span className="text-xl font-semibold text-gray-950">
            {exam.registeredCount}
          </span>
        </div>
        <div className="flex flex-col gap-2 py-4 px-6 border border-gray-200 rounded-lg w-full">
          <div className="flex flex-row justify-between">
            <span className="text-gray-600 font-medium">
              Candidats non validés
            </span>
            <X className="text-brand-600" />
          </div>
          <span className="text-xl font-semibold text-gray-950">
            {exam.preRegisteredCount - exam.registeredCount}
          </span>
        </div>
      </div>
      <Tabs
        onValueChange={(e) => setTab(e as ExamRegistrationStatus)}
        value={tab}
      >
        <TabsList
          variant="transparent"
          className="pb-0 mb-0 border-b border-b-gray-200 rounded-none"
        >
          <TabsTrigger variant="underlineBlue" value={"none"}>
            Tous
          </TabsTrigger>
          <TabsTrigger
            variant="underlineBlue"
            value={ExamRegistrationStatus.WAITING_RESULTS}
          >
            En cours
          </TabsTrigger>
          <TabsTrigger
            variant="underlineBlue"
            value={ExamRegistrationStatus.COMPLETE}
          >
            Finalisées
          </TabsTrigger>
        </TabsList>
      </Tabs>
      <Input
        className="shadow-none"
        id="shadcn-input"
        searchIcon
        type="text"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value as string)}
        placeholder="Rechercher"
      />
      <CandidatesTable
        examId={exam.id}
        searchText={debouncedSearchText}
        status={tab !== "none" ? statusPerTab[tab] : undefined}
      />
    </div>
  );
};

export default Candidates;
