import { ValidationStatus } from "@/api/enums/ValidationStatus";
import { Badge } from "@/components/atoms/Badge";
import GreenCheck from "@/components/atoms/GreenCheck";
import RedCross from "@/components/atoms/RedCross";

interface AttestationStatusBadgeProps {
  status: ValidationStatus;
}

const AttestationStatusBadge = ({ status }: AttestationStatusBadgeProps) => {
  switch (status) {
    case ValidationStatus.REFUSED:
      return <RedCross />;
    case ValidationStatus.VALIDATED:
      return <GreenCheck />;
    default:
      return <Badge variant="orange">En attente validation</Badge>;
  }
};

export default AttestationStatusBadge;
