import { usePreTestStatusMutation } from "@/api/admin/admin";
import { CandidateRegistrationDtoClient } from "@/api/candidate/dto/candidate-registration.dto";
import { ValidationStatus } from "@/api/enums/ValidationStatus";
import { keyFactory } from "@/api/keyFactory";
import { Switch } from "@/components/atoms/Switch";
import PreTestStatusBadge from "@/components/molecules/Badges/PreTestStatusBadge";
import ConfirmDialog from "@/components/molecules/ConfirmDialog";
import { Card, CardTitle } from "@/components/molecules/RegistrationCard/Card";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { toast } from "sonner";

interface PreTestCardProps {
  registration: CandidateRegistrationDtoClient;
  candidateId: number;
}

const PreTestCard = ({
  registration: { preTestStatus, id: registrationId },
  candidateId: id,
}: PreTestCardProps) => {
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const isValidated = preTestStatus === ValidationStatus.VALIDATED;
  const queryClient = useQueryClient();

  const statusMutation = usePreTestStatusMutation();

  return (
    <>
      <ConfirmDialog
        isOpen={confirmDialogOpen}
        title={(isValidated ? "Invalider" : "Valider") + " le test préalable"}
        cancelStr="Annuler"
        validateStr="Confirmer"
        onResult={(accepted) => {
          if (accepted) {
            statusMutation.mutate(
              {
                registrationId,
                candidateId: id,
                validated: !isValidated,
              },
              {
                onSuccess: () => {
                  toast.success(
                    "Test préalable " + (isValidated ? "invalidé" : "validé")
                  );
                  queryClient.invalidateQueries({
                    queryKey:
                      keyFactory.admin.candidate.ongoingRegistration(id),
                  });
                },
                onError: () => {
                  toast.error("Echèc de la requête", {
                    description:
                      "Votre action n'a pas pu être prise en compte.",
                  });
                },
              }
            );
          }
          setConfirmDialogOpen(false);
        }}
      />
      <Card>
        <div className="flex flex-row justify-between w-full items-center">
          <div className="flex flex-row gap-2">
            <CardTitle>Test préalable</CardTitle>
            <PreTestStatusBadge status={preTestStatus} />
          </div>
          <Switch
            checked={isValidated}
            className="data-[state=checked]:bg-green-600"
            onCheckedChange={() => setConfirmDialogOpen(true)}
          />
        </div>
      </Card>
    </>
  );
};

export default PreTestCard;
