import { DocumentType } from "@/api/dto/document-type.enum";
import { PriceType } from "@/api/enums/PriceType";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/atoms/Form";
import { RadioGroup, RadioGroupItem } from "@/components/atoms/RadioGroup";
import DropzoneMolecule from "@/components/molecules/Dropzone";
import { Prices } from "@/constants/prices";
import { emptyfile, file } from "@/constants/zodTypes";
import useFileUpload from "@/hooks/FileUpload";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import NextStepButton from "../NextStepButtons";
import { RegisteringStep, useRegisterContext } from "../RegisterContext";

const priceTypeFormSchema = z.discriminatedUnion("priceType", [
  z.object({
    priceType: z.literal(PriceType.REDUCED),
    reducedPriceFile: file(),
  }),
  z.object({
    priceType: z.literal(PriceType.FULL),
  }),
]);

const Pricing = () => {
  const { changeDraft, onNextStep, registrationDraft, pending } =
    useRegisterContext();
  const { ensureUploaded, uploadIsPending } = useFileUpload(
    DocumentType.REDUCED_PRICE_JUSTIFICATION
  );

  const form = useForm<z.infer<typeof priceTypeFormSchema>>({
    resolver: zodResolver(priceTypeFormSchema),
    defaultValues: {
      priceType: registrationDraft.priceType ?? PriceType.FULL,
      reducedPriceFile: registrationDraft.reducedPriceDocument ?? emptyfile(),
    },
  });

  const onSubmit = async (
    values: z.infer<typeof priceTypeFormSchema>,
    goNext: boolean
  ) => {
    const { data, error, success } = priceTypeFormSchema.safeParse(values);

    if (!success) {
      console.error("can't parse form, aborting modification :", error);
      return;
    }

    const reducedPriceDocument =
      data.priceType === PriceType.REDUCED
        ? (await ensureUploaded(data.reducedPriceFile))[0] ?? undefined
        : undefined;

    changeDraft({
      priceType: data.priceType,
      reducedPriceDocument,
    });

    onNextStep(RegisteringStep.PRICING, goNext);
  };

  return (
    <div className="flex flex-col gap-8 items-start w-full max-w-4xl">
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit((data) => onSubmit(data, true))}
          className="flex w-full flex-col space-y-8"
        >
          <div className="flex p-4 flex-col items-start gap-8 self-stretch rounded-md border-gray-200 bg-white border w-full">
            <h1>Tarif</h1>
            <FormField
              control={form.control}
              name="priceType"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <RadioGroup
                      name={field.name}
                      defaultValue={field.value}
                      onValueChange={(val) => field.onChange(val as PriceType)}
                      className="flex flex-col gap-4 w-full mt-1"
                    >
                      {[
                        {
                          value: PriceType.FULL,
                          msg: `Tarif plein - ${Prices.FULL.toString()} €`,
                        },
                        {
                          value: PriceType.REDUCED,
                          msg: `Tarif réduit - ${Prices.REDUCED.toString()} € (uniquement étudiant ou demandeur d'emploi)`,
                        },
                      ].map(({ value, msg }) => {
                        const itemId = `${field.name}${value}`;

                        return (
                          <div
                            className="flex items-center self-stretch"
                            key={itemId}
                          >
                            <RadioGroupItem
                              id={itemId}
                              value={value}
                              className="flex items-center justify-center gap-2"
                            />
                            <FormLabel htmlFor={itemId}>{msg}</FormLabel>
                          </div>
                        );
                      })}
                    </RadioGroup>
                  </FormControl>
                </FormItem>
              )}
            />
            {form.watch("priceType") === PriceType.REDUCED && (
              <FormField
                control={form.control}
                name={"reducedPriceFile"}
                render={() => (
                  <FormItem className="w-full">
                    <FormControl>
                      <DropzoneMolecule canBeRemoved={true}>
                        <span className="text-center">
                          <span className="text-brand-700">Déposez</span> ou
                          cliquez pour déposer votre justificatif étudiant ou
                          demandeur d'emploi <br />
                          <span className="text-gray-600 text-sm font-normal">
                            Pour les demandeurs d'emploi, le justificatif doit
                            dater de moins de 3 mois. Il sera contrôlé par le
                            CIFMD. Si celui-ci n'est pas recevable, le tarif
                            plein vous sera appliqué.
                          </span>
                        </span>
                      </DropzoneMolecule>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}
          </div>
          <NextStepButton
            onNext={form.handleSubmit((data) => onSubmit(data, true))}
            onPrevious={() => onNextStep(RegisteringStep.PRICING, false)}
            pending={pending || uploadIsPending}
          />
        </form>
      </Form>
    </div>
  );
};

export default Pricing;
