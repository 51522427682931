import {
  useCandidateInfoQuery,
  useModifyCandidateInfoMutation,
} from "@/api/admin/admin";
import { replaceNullsWith } from "@/api/app";
import { keyFactory } from "@/api/keyFactory";
import { Form } from "@/components/atoms/Form";
import { AddressFields } from "@/components/molecules/AddressForm/AddressFields";
import AddressForm from "@/components/molecules/AddressForm/AddressForm";
import {
  addressDefaultValues,
  optionnalAddressSchema,
} from "@/components/molecules/AddressForm/AddressFormSchema";
import { QueryLoadingSpinner } from "@/components/molecules/LoadingSpinner";
import ModifiableForm from "@/components/molecules/ModifiableForm";
import { PersonnalInfoField } from "@/components/molecules/PersonnalInfoForm/PersonnalInfoFields";
import PersonnalInformationForm from "@/components/molecules/PersonnalInfoForm/PersonnalInfoForm";
import {
  personnalInfoDefaultValues,
  personnalInfoSchema,
} from "@/components/molecules/PersonnalInfoForm/PersonnalInfoFormSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "sonner";
import { z } from "zod";

const accountModificationSchema = z.object({
  address: optionnalAddressSchema,
  personnalInfo: personnalInfoSchema,
});

type AccountModificationSchema = z.infer<typeof accountModificationSchema>;

const PersonnalInformation = () => {
  const { id: idstr } = useParams();
  const candidateId = Number(idstr);
  const queryClient = useQueryClient();
  const [PIIsModifiable, setPIIsModifiable] = useState(false);
  const [addressIsModifiable, setAddressIsModifiable] = useState(false);

  /////////////
  // api calls
  /////////////

  const getCandidateQuery = useCandidateInfoQuery(candidateId);

  const changeCandidateInfoMutation = useModifyCandidateInfoMutation({
    onSuccess: async () => {
      toast.success("Informations modifiées avec succès");

      queryClient.invalidateQueries({
        queryKey: keyFactory.admin.candidate.info(candidateId),
      });
      queryClient.invalidateQueries({
        queryKey: keyFactory.admin.candidate.basicInfo(candidateId),
      });

      setAddressIsModifiable(false);
      setPIIsModifiable(false);
    },
    onError: () => {
      toast.error("Erreur", {
        description: "Les informations du candidat n'ont pas pu être modifiées",
      });
    },
  });

  /////////////
  // form
  /////////////

  const form = useForm<AccountModificationSchema>({
    resolver: zodResolver(accountModificationSchema),
    defaultValues: {
      address: addressDefaultValues,
      personnalInfo: personnalInfoDefaultValues,
    },
  });

  const { data: candidateInfo } = getCandidateQuery;
  const { reset, watch } = form;

  const resetForm = useCallback(() => {
    const newState = watch();

    if (candidateInfo) {
      newState.address = _.merge(
        addressDefaultValues,
        replaceNullsWith(candidateInfo.address, "")
      );
      newState.personnalInfo = _.merge(
        personnalInfoDefaultValues,
        replaceNullsWith(candidateInfo.personnalInfo, "")
      );
    }

    reset(newState);
  }, [candidateInfo, reset, watch]);

  useEffect(resetForm, [resetForm]);

  const onSubmit = (values: AccountModificationSchema) => {
    const { data, error, success } =
      accountModificationSchema.safeParse(values);

    if (!success) {
      console.error("can't parse form, aborting modification :", error);
      return;
    }

    changeCandidateInfoMutation.mutate({ candidateId, ...data });
  };

  if (getCandidateQuery.isLoading || getCandidateQuery.isError)
    return (
      <QueryLoadingSpinner
        errorMessage="Erreur lors du chargement du profil candidat"
        loadingMessage="Chargement du profil candidat..."
        queries={[getCandidateQuery]}
      />
    );

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-8 p-4"
      >
        <ModifiableForm
          setModifiable={(b: boolean) => {
            setAddressIsModifiable(false);
            resetForm();
            setPIIsModifiable(b);
          }}
          isModifiable={PIIsModifiable}
          onCancel={resetForm}
          modifyText="Modifier"
          saveText="Sauvegarder"
        >
          <PersonnalInformationForm
            hiddenFields={[
              PersonnalInfoField.Password,
              PersonnalInfoField.PasswordConfirmation,
              PersonnalInfoField.Email,
              PersonnalInfoField.EmailConfirmation,
            ]}
            disabledFields={
              PIIsModifiable ? [] : Object.values(PersonnalInfoField)
            }
          />
        </ModifiableForm>
        <ModifiableForm
          setModifiable={(b: boolean) => {
            setPIIsModifiable(false);
            resetForm();
            setAddressIsModifiable(b);
          }}
          isModifiable={addressIsModifiable}
          onCancel={resetForm}
          modifyText="Modifier"
          saveText="Sauvegarder"
        >
          <AddressForm
            fieldName="address"
            disabledFields={
              addressIsModifiable ? [] : Object.values(AddressFields)
            }
          />
        </ModifiableForm>
      </form>
    </Form>
  );
};

export default PersonnalInformation;
