import { CandidateRegistrationDtoClient } from "@/api/candidate/dto/candidate-registration.dto";
import { Badge } from "@/components/atoms/Badge";
import { RegistrationUtil } from "@/lib/registration-util";

interface RegisteringStatusBadgeProps {
  registration: CandidateRegistrationDtoClient;
}

const RegistrationStatusBadge = ({
  registration,
}: RegisteringStatusBadgeProps) => {
  const completed = RegistrationUtil.isCompleted(registration);

  if (completed) return <Badge variant="green">Inscrit</Badge>;
  else return <Badge variant="orange">Pré-inscrit</Badge>;
};

export default RegistrationStatusBadge;
