import { date, file, mandatoryString, nullDate } from "@/constants/zodTypes";
import { PerimeterUtil } from "@/lib/perimeter-util";
import { perimeterSchema } from "@molecules/PerimeterForm/PerimeterFormSchema";
import { z } from "zod";

const certificateSchema = z.object({
  number: mandatoryString(),
  country: mandatoryString(),
  expirationDate: date(
    new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
    new Date(new Date().getTime() + 100 * 365 * 24 * 60 * 60 * 1000)
  ),
  file: file(),
  perimeter: perimeterSchema,
});

const certificateDefaultValues: z.infer<typeof certificateSchema> = {
  number: "",
  expirationDate: nullDate(),
  country: "",
  file: new File([""], ""),
  perimeter: PerimeterUtil.emptyPerimeter(),
};

export { certificateDefaultValues, certificateSchema };
