import { FileDto } from "@/api/dto/file.dto";
import { keyFactory } from "@/api/keyFactory";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import axios from "axios";

export type GlobalFile = File | FileDto;

export class GlobalFileHelper {
  static fetch = async (file: GlobalFile): Promise<File> => {
    if (file instanceof File) return file;

    if (!file.signedAccessKey)
      throw new Error("Signed access key is required to fetch the file.");

    const response = await axios.get(file.signedAccessKey, {
      responseType: "blob",
      withCredentials: false,
    });

    return new File([response.data], file.name, {
      type: response.data.type,
    });
  };

  static fileName = (file: GlobalFile): string => {
    if (file instanceof File) return file.name;
    return file.name;
  };
}

export const useFetchFileQuery = (
  fileDto: GlobalFile,
  props?: Partial<UseQueryOptions<File>>
) => {
  return useQuery({
    queryKey: keyFactory.file(GlobalFileHelper.fileName(fileDto)),
    queryFn: async () => await GlobalFileHelper.fetch(fileDto),
    staleTime: Infinity,
    gcTime: Infinity,
    enabled: false,
    ...props,
  });
};
