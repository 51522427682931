import { ExamType } from "@/api/enums/ExamType";
import { date, nullDate } from "@/constants/zodTypes";
import { z } from "zod";

enum ExamFormField {
  Type = "Type",
  RegisteringStartDate = "RegisteringStartDate",
  RegisteringEndDate = "RegisteringEndDate",
  FormationAttestationEndDate = "FormationAttestationEndDate",
  Date = "Date",
}

const examSchema = (isModification: boolean = false) =>
  z
    .object({
      type: z.nativeEnum(ExamType),
      registeringStartDate: date(isModification ? undefined : new Date()),
      registeringEndDate: date(isModification ? undefined : new Date()),
      formationCertificateEndDate: date(
        isModification ? undefined : new Date()
      ),
      date: date(isModification ? undefined : new Date()),
    })
    .superRefine(
      (
        {
          registeringStartDate,
          registeringEndDate,
          formationCertificateEndDate,
          date,
        },
        ctx
      ) => {
        if (registeringStartDate > registeringEndDate) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message:
              "La date de début des inscriptions doit précéder à la date de fin",
          });
          return;
        }

        if (registeringEndDate > date) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message:
              "La date de clôture des inscriptions doit précéder la date de l'examen",
          });
          return;
        }

        if (formationCertificateEndDate > date) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message:
              "La date de clôture des attestations de formation doit précéder la date de l'examen",
          });
          return;
        }

        if (formationCertificateEndDate < registeringStartDate) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message:
              "La date de clôture att. formation doit être postérieure à la date de début de formation",
          });
          return;
        }
      }
    );

const examDefaultValues = {
  type: ExamType.INITIAL,
  registeringStartDate: nullDate(),
  registeringEndDate: nullDate(),
  formationCertificateEndDate: nullDate(),
  date: nullDate(),
};

export { examDefaultValues, ExamFormField, examSchema };
