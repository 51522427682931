import {
  useFormationAttestationUploadMutation,
  useGlobalFormationAttestationStatusMutation,
} from "@/api/admin/admin";
import { CandidateRegistrationDtoClient } from "@/api/candidate/dto/candidate-registration.dto";
import { ValidationStatus } from "@/api/enums/ValidationStatus";
import { keyFactory } from "@/api/keyFactory";
import { Badge } from "@/components/atoms/Badge";
import { Button } from "@/components/atoms/Button";
import { Switch } from "@/components/atoms/Switch";
import ConfirmDialog from "@/components/molecules/ConfirmDialog";
import AttestationsDialog from "@/components/pages/Candidate/Dashboard/Exams/Home/ToCome/RegistrationCard/Cards/AttestationsCard/AttestationsDialog";
import { PerimeterUtil } from "@/lib/perimeter-util";
import { useQueryClient } from "@tanstack/react-query";
import { Plus } from "lucide-react";
import { useState } from "react";
import { toast } from "sonner";
import FormationAttestationCard from "./FormationAttestationCard";

interface FormationAttestationsProps {
  registration: CandidateRegistrationDtoClient;
  candidateId: number;
}

const FormationAttestations = ({
  registration,
  candidateId,
}: FormationAttestationsProps) => {
  const [attestationsDialogOpen, setAttestationsDialogOpen] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const queryClient = useQueryClient();
  const isValidated =
    registration.formationCertificatesStatus === ValidationStatus.VALIDATED;

  const globalStatusMutation = useGlobalFormationAttestationStatusMutation();
  const uploadMutation = useFormationAttestationUploadMutation();

  const invalidateQuery = () =>
    queryClient.invalidateQueries({
      queryKey: keyFactory.admin.candidate.ongoingRegistration(candidateId),
    });

  return (
    <>
      <AttestationsDialog
        isOpen={attestationsDialogOpen}
        close={() => setAttestationsDialogOpen(false)}
        registration={registration}
        onSubmitMutation={(data) =>
          uploadMutation.mutate(
            {
              candidateId,
              ...data,
            },
            {
              onSuccess: () => {
                toast.success("Attestations envoyées");
                invalidateQuery();
                setAttestationsDialogOpen(false);
              },
              onError: () => {
                toast.error("Echèc de la requête", {
                  description: "Votre action n'a pas pu être prise en compte.",
                });
              },
            }
          )
        }
        isPending={uploadMutation.isPending}
      />
      <ConfirmDialog
        isOpen={confirmDialogOpen}
        title={
          (isValidated ? "Invalider" : "Valider") +
          " les attestations de formation"
        }
        cancelStr="Annuler"
        validateStr="Confirmer"
        onResult={(accepted) => {
          if (accepted) {
            globalStatusMutation.mutate(
              {
                registrationId: registration.id,
                candidateId,
                validated: !isValidated,
              },
              {
                onSuccess: () => {
                  invalidateQuery();
                  toast.success(
                    "Attestations de formation " +
                      (isValidated ? "invalidées" : "validées")
                  );
                  setAttestationsDialogOpen(false);
                },
                onError: () => {
                  toast.error("Echèc de la requête", {
                    description:
                      "Votre action n'a pas pu être prise en compte.",
                  });
                },
              }
            );
          }
          setConfirmDialogOpen(false);
        }}
      />
      <div className="flex flex-col gap-2">
        <div className="flex flex-row items-center gap-4">
          <h2>Attestation de formation</h2>

          <span>Clôture attestation formation</span>
          <Badge variant="white">
            {new Date(
              registration.exam.formationCertificateEndDate
            ).toLocaleDateString("fr-Fr")}
          </Badge>
          <Badge variant="white">
            {PerimeterUtil.toCode(registration.perimeter)}
          </Badge>
        </div>
        <div className="flex flex-row justify-between w-full items-center">
          <div className="flex flex-row gap-2 items-center">
            <Switch
              checked={isValidated}
              className="data-[state=checked]:bg-green-600"
              onCheckedChange={() => setConfirmDialogOpen(true)}
            />
            Attestations validées
          </div>
          <Button
            className="gap-1"
            onClick={() => setAttestationsDialogOpen(true)}
          >
            <Plus />
            Ajouter une attestation
          </Button>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
          {registration.formationCertificates
            .sort((attestation) => attestation.id)
            .map((attestation, i) => (
              <FormationAttestationCard
                key={i}
                attestation={attestation}
                candidateId={candidateId}
                registrationId={registration.id}
                disabled={isValidated}
                examPerimeter={registration.perimeter}
              />
            ))}
        </div>
      </div>
    </>
  );
};

export default FormationAttestations;
