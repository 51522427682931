import { useForeignCertificatesQuery } from "@/api/admin/admin";
import { ForeignCertificateDtoClient } from "@/api/certificate/dto/foreign-certificates-paginated.dto";
import { CertificateStatus } from "@/api/enums/CertificateStatus";
import { Badge } from "@/components/atoms/Badge";
import { Button } from "@/components/atoms/Button";
import CertificateStatusBadge from "@/components/molecules/Badges/CertificateStatusBadge";
import FileLink from "@/components/molecules/FileLink";
import { QueryLoadingSpinner } from "@/components/molecules/LoadingSpinner";
import SmartPagination from "@/components/molecules/SmartPagination";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/molecules/Table";
import usePersistent from "@/hooks/use-persistent";
import { PerimeterUtil } from "@/lib/perimeter-util";
import { cn } from "@/lib/utils";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useMemo, useState } from "react";
import ForeignCertificateValidateDialog from "./ForeignCertificateValidateDialog";

interface ForeignCertificatesTableProps {
  tab: CertificateStatus;
  searchText?: string;
}

const columnHelper = createColumnHelper<ForeignCertificateDtoClient>();

const ForeignCertificatesTable = ({
  tab,
  searchText,
}: ForeignCertificatesTableProps) => {
  const [consultDialogCertificate, setConsultDialogCertificate] = useState<
    ForeignCertificateDtoClient | undefined
  >(undefined);
  const [pageIndex, setPageIndex] = usePersistent(
    "foreign-certificates-page-index",
    0
  );

  const foreignCertificatesQuery = useForeignCertificatesQuery({
    pageIndex,
    searchText,
    status: tab,
  });

  const columns = useMemo(
    () => [
      columnHelper.accessor(
        ({ firstName, lastName }) => `${firstName} ${lastName}`,
        {
          id: "candidate",
          header: "Candidat",
          cell: (info) => <span>{info.getValue()}</span>,
        }
      ),
      columnHelper.accessor((x) => x, {
        id: "status",
        header: "Statut",
        cell: (info) => {
          const certificate = info.getValue();
          const { status, expirationDate } = certificate;
          return (
            <div className="flex flex-row items-center gap-2">
              <CertificateStatusBadge
                expirationDate={new Date(expirationDate)}
                status={status}
                isAdmin={true}
              />
              {status === CertificateStatus.PENDING && (
                <Button
                  onClick={() => setConsultDialogCertificate(certificate)}
                  className="h-fit py-1.5 px-2 rounded-lg"
                >
                  Consulter
                </Button>
              )}
            </div>
          );
        },
      }),
      columnHelper.accessor("country", {
        id: "country",
        header: "Pays certif.",
        cell: (info) => <span>{info.getValue()}</span>,
      }),
      columnHelper.accessor("number", {
        id: "number",
        header: "Numéro",
        cell: (info) => <span>{info.getValue()}</span>,
      }),
      columnHelper.accessor("expirationDate", {
        id: "expirationDate",
        header: "Date de validité",
        cell: (info) => (
          <span>{new Date(info.getValue()).toLocaleDateString("fr-FR")}</span>
        ),
      }),
      columnHelper.accessor("perimeter", {
        id: "perimeter",
        header: "Numéro",
        cell: (info) => (
          <Badge variant="white">{PerimeterUtil.toCode(info.getValue())}</Badge>
        ),
      }),
      columnHelper.accessor("file", {
        id: "download",
        header: "",
        cell: (info) => (
          <FileLink file={info.getValue()} showName={false}>
            Télécharger
          </FileLink>
        ),
      }),
    ],
    []
  );

  const table = useReactTable({
    data: foreignCertificatesQuery?.data?.data ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  if (!foreignCertificatesQuery.data)
    return (
      <QueryLoadingSpinner
        queries={[foreignCertificatesQuery]}
        errorMessage="Erreur lors du chargement des certificats"
        loadingMessage="Chargement des certificats..."
      />
    );

  const { totalPages } = foreignCertificatesQuery.data;

  return (
    <>
      <ForeignCertificateValidateDialog
        close={() => setConsultDialogCertificate(undefined)}
        certificate={consultDialogCertificate}
      />
      <div className="flex flex-col w-full gap-5">
        <div className="rounded-lg overflow-hidden border border-gray-200 w-full">
          <Table className="text-gray-600 font-medium text-sm leading-6">
            <TableHeader className="items-center bg-gray-50">
              <TableRow>
                {table.getHeaderGroups()[0].headers.map((header) => (
                  <TableHead
                    key={header.id}
                    style={{ width: `${header.getSize()}px` }}
                  >
                    <div className="flex flex-row items-center gap-3 text-xs">
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </div>
                  </TableHead>
                ))}
              </TableRow>
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows.map((row, i) => (
                <TableRow key={row.id} className={cn({ "bg-gray-50": i % 2 })}>
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id} className="py-4">
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
        <SmartPagination
          currentPage={pageIndex}
          onPageChange={setPageIndex}
          totalPages={totalPages}
        />
      </div>
    </>
  );
};

export default ForeignCertificatesTable;
