import { cn } from "@/lib/utils";
import { NavLink, NavLinkProps } from "react-router-dom";

interface BlueNavLinkProps extends NavLinkProps {
  children: React.ReactNode;
  disabled?: boolean;
}

const BlueNavLink = ({
  to,
  children,
  className = "",
  disabled = false,
  ...rest
}: BlueNavLinkProps) => {
  const baseClass =
    "px-3 py-2 gap-1 rounded-lg flex flex-row items-center transition duration-200 bg-brand-600 text-white hover:bg-brand-800 cursor-pointer";

  if (disabled) {
    return (
      <div
        className={cn(
          baseClass,
          { "opacity-50 cursor-not-allowed hover:bg-brand-600": disabled },
          className
        )}
        aria-disabled="true"
      >
        {children}
      </div>
    );
  }

  return (
    <NavLink className={cn(baseClass, className)} to={to} {...rest}>
      {children}
    </NavLink>
  );
};

export default BlueNavLink;
