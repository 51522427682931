const ButtonMailto = ({ email, label }: { email: string; label: string }) => {
  const handleMailtoClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault(); // Prevent default anchor behavior
    const mailtoLink = `mailto:${email}`;

    // Attempt to open in a new tab/window
    const mailtoWindow = window.open(mailtoLink, "_blank");

    // Fallback if popup is blocked
    if (!mailtoWindow) {
      window.location.href = mailtoLink;
    }
  };

  return (
    <a
      href={`mailto:${email}`} // Fallback for non-JS users
      onClick={handleMailtoClick}
      target="_self" // Same tab, matches email client's behavior
      rel="noopener noreferrer" // Security best practices
    >
      {label}
    </a>
  );
};

export default ButtonMailto;
