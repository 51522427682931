import { useReducedPriceDocumentStatusMutation } from "@/api/admin/admin";
import { CandidateRegistrationDtoClient } from "@/api/candidate/dto/candidate-registration.dto";
import { PriceType } from "@/api/enums/PriceType";
import { ValidationStatus } from "@/api/enums/ValidationStatus";
import { keyFactory } from "@/api/keyFactory";
import { Badge } from "@/components/atoms/Badge";
import { Button } from "@/components/atoms/Button";
import GreenCheck from "@/components/atoms/GreenCheck";
import RedCross from "@/components/atoms/RedCross";
import ConfirmDialog from "@/components/molecules/ConfirmDialog";
import FileLink from "@/components/molecules/FileLink";
import { Card, CardTitle } from "@/components/molecules/RegistrationCard/Card";
import { RegistrationUtil } from "@/lib/registration-util";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { toast } from "sonner";
import AddDocumentDialog from "./AddDocumentDialog";
import ConsultDocumentDialog from "./ConsultDocumentDialog";

interface ReducedPriceCardProps {
  registration: CandidateRegistrationDtoClient;
  candidateId: number;
}

const ReducedPriceCard = ({
  registration,
  candidateId: id,
}: ReducedPriceCardProps) => {
  const { reducedPriceStatus, reducedPriceDocument, priceType } = registration;
  const queryClient = useQueryClient();
  const [addDocumentDialogOpen, setAddDocumentDialogOpen] = useState(false);
  const [consultDocumentDialogOpen, setConsultDocumentDialogOpen] =
    useState(false);
  const [confirmCancelDialogOpen, setConfirmCancelDialogOpen] = useState(false);

  const statusMutation = useReducedPriceDocumentStatusMutation();

  if (
    priceType !== PriceType.REDUCED ||
    RegistrationUtil.hasNonRefusedDelegation(registration)
  )
    return false;

  return (
    <>
      <AddDocumentDialog
        isOpen={addDocumentDialogOpen}
        close={() => setAddDocumentDialogOpen(false)}
        registration={registration}
      />
      <ConsultDocumentDialog
        isOpen={consultDocumentDialogOpen}
        registration={registration}
        close={() => setConsultDocumentDialogOpen(false)}
      />
      <ConfirmDialog
        isOpen={confirmCancelDialogOpen}
        onResult={(accepted) => {
          if (accepted) {
            statusMutation.mutate(
              {
                candidateId: id,
                registrationId: registration.id,
                validated: false,
              },
              {
                onSuccess: () => {
                  toast.success("Justificatif de tarif réduit invalidé");
                  queryClient.invalidateQueries({
                    queryKey:
                      keyFactory.admin.candidate.ongoingRegistration(id),
                  });
                  setConfirmCancelDialogOpen(false);
                },
                onError: () => {
                  toast.error("Echèc de la requête", {
                    description:
                      "Votre action n'a pas pu être prise en compte.",
                  });
                },
              }
            );
          } else setConfirmCancelDialogOpen(false);
        }}
        title="Invalidation du justificatif de tarif réduit"
        validateButtonVariant="red"
      />
      <Card>
        <div className="flex flex-row justify-between w-full items-center">
          <div className="flex flex-row gap-2 items-center">
            <CardTitle>Tarif réduit</CardTitle>
            {reducedPriceStatus === ValidationStatus.REFUSED && <RedCross />}
            {reducedPriceStatus === ValidationStatus.PENDING && (
              <Badge variant="orange">En attente validation</Badge>
            )}
            {reducedPriceStatus === ValidationStatus.VALIDATED && (
              <GreenCheck />
            )}
            {reducedPriceDocument && (
              <FileLink showName={false} file={reducedPriceDocument} />
            )}
          </div>

          {reducedPriceStatus === ValidationStatus.REFUSED && (
            <Button onClick={() => setAddDocumentDialogOpen(true)}>
              Ajouter un document
            </Button>
          )}
          {reducedPriceStatus === ValidationStatus.PENDING && (
            <Button onClick={() => setConsultDocumentDialogOpen(true)}>
              Consulter
            </Button>
          )}
          {reducedPriceStatus === ValidationStatus.VALIDATED && (
            <Button onClick={() => setConfirmCancelDialogOpen(true)}>
              Annuler
            </Button>
          )}
        </div>
      </Card>
    </>
  );
};

export default ReducedPriceCard;
