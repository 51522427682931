import { ValidationStatus } from "@/api/enums/ValidationStatus";
import { Badge } from "@/components/atoms/Badge";
import GreenCheck from "@/components/atoms/GreenCheck";

interface PreTestStatusBadgeProps {
  status: ValidationStatus;
  fromAdmin?: boolean;
}

const PreTestStatusBadge = ({ status, fromAdmin }: PreTestStatusBadgeProps) => {
  switch (status) {
    case ValidationStatus.PENDING:
      return <Badge variant="orange">En attente de validation</Badge>;
    case ValidationStatus.VALIDATED:
      if (fromAdmin) return <Badge variant="green">Validé</Badge>;
      else return <GreenCheck />;
    case ValidationStatus.REFUSED:
      return <Badge variant="red">Refusé</Badge>;
    case ValidationStatus.UNAVAILABLE:
      return <Badge variant="white">Non disponible</Badge>;
  }
};

export default PreTestStatusBadge;
