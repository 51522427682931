import SexRadio from "@/components/molecules/SexRadio";
import { countries } from "@/constants/countries";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@atoms/Form";
import { ComboboxField } from "@molecules/Combobox";
import { PhoneInput } from "@molecules/PhoneInput";
import { useFormContext } from "react-hook-form";
import BirthDatePicker from "./BirthDatePicker";
import { formatFieldCapital } from "./Formatters/field-capitals";
import FieldWithOptionnality from "./Formatters/FieldWithOptionnality";
import { FieldMandatoryType } from "./Formatters/mandatory-type";
import { PersonnalInfoField } from "./PersonnalInfoFields";
import TextInputField from "./TextInputField";

const formattedOptionList = (options: string[]) => {
  options.sort();
  return options.map((option: string) => ({
    value: option,
    label: option.charAt(0).toUpperCase() + option.slice(1),
  }));
};

interface PersonnalInformationFormProps {
  disabledFields?: PersonnalInfoField[];
  hiddenFields?: PersonnalInfoField[];
  unmodifiableToolTipFields?: PersonnalInfoField[];
}

const PersonnalInformationForm = ({
  disabledFields = [],
  hiddenFields = [],
  unmodifiableToolTipFields = [],
}: PersonnalInformationFormProps) => {
  const { control } = useFormContext();

  const rowClasses = "flex flex-row gap-2 w-full mt-1";
  const modifiers = {
    disabledFields,
    hiddenFields,
    unmodifiableToolTipFields,
  };

  return (
    <div className="flex flex-col w-full">
      <h2>Informations personnelles</h2>
      <div className="flex flex-col gap-1">
        {!hiddenFields.includes(PersonnalInfoField.Sex) && (
          <div className={rowClasses + " py-2"}>
            <SexRadio
              name="personnalInfo.sex"
              disabled={disabledFields.includes(PersonnalInfoField.Sex)}
            />
          </div>
        )}
        <div className={rowClasses}>
          <TextInputField
            fieldMandatoryType={FieldMandatoryType.MANDATORY_ON_CERTIFICATE}
            title="Nom"
            type={PersonnalInfoField.LastName}
            formattingFunction={formatFieldCapital}
            modifiers={modifiers}
          />
          <TextInputField
            fieldMandatoryType={FieldMandatoryType.MANDATORY_ON_CERTIFICATE}
            title="Prénom"
            type={PersonnalInfoField.FirstName}
            formattingFunction={formatFieldCapital}
            modifiers={modifiers}
          />
        </div>
        <div className={rowClasses}>
          <BirthDatePicker modifiers={modifiers} />
          <TextInputField
            fieldMandatoryType={FieldMandatoryType.MANDATORY_ON_CERTIFICATE}
            title="Ville de naissance"
            type={PersonnalInfoField.BirthCity}
            formattingFunction={formatFieldCapital}
            modifiers={modifiers}
            placeholder="Constantine"
          />
        </div>
        <div className={rowClasses}>
          {!hiddenFields.includes(PersonnalInfoField.BirthCountry) && (
            <FormField
              control={control}
              name="personnalInfo.birthCountry"
              render={() => (
                <FormItem className="w-full">
                  <FormLabel>Pays de naissance</FormLabel>
                  <FormControl>
                    <ComboboxField
                      options={formattedOptionList(
                        countries.map((country) => country.libelle)
                      )}
                      placeholder={"-"}
                      optionnal={true}
                      disabled={disabledFields.includes(
                        PersonnalInfoField.BirthCountry
                      )}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}
          {!hiddenFields.includes(PersonnalInfoField.Nationality) && (
            <FormField
              control={control}
              name="personnalInfo.nationality"
              render={() => (
                <FormItem className="w-full">
                  <FormLabel>
                    <FieldWithOptionnality
                      str="Nationalité"
                      fieldMandatoryType={
                        FieldMandatoryType.MANDATORY_ON_CERTIFICATE
                      }
                    />
                  </FormLabel>
                  <FormControl>
                    <ComboboxField
                      options={formattedOptionList(
                        countries.map((country) => country.nationalite)
                      )}
                      placeholder={"-"}
                      disabled={disabledFields.includes(
                        PersonnalInfoField.Nationality
                      )}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}
        </div>
        {(!hiddenFields.includes(PersonnalInfoField.Password) ||
          !hiddenFields.includes(PersonnalInfoField.PasswordConfirmation)) && (
          <div className={rowClasses}>
            <TextInputField
              fieldMandatoryType={FieldMandatoryType.MANDATORY}
              title="Adresse email"
              type={PersonnalInfoField.Email}
              modifiers={modifiers}
              placeholder="email@example.fr"
            />
            <TextInputField
              fieldMandatoryType={FieldMandatoryType.MANDATORY}
              title="Confirmation adresse email"
              type={PersonnalInfoField.EmailConfirmation}
              modifiers={modifiers}
              placeholder="email@example.fr"
            />
          </div>
        )}
        {(!hiddenFields.includes(PersonnalInfoField.Password) ||
          !hiddenFields.includes(PersonnalInfoField.PasswordConfirmation)) && (
          <div className={rowClasses}>
            <TextInputField
              fieldMandatoryType={FieldMandatoryType.MANDATORY}
              title="Mot de passe"
              type={PersonnalInfoField.Password}
              modifiers={modifiers}
              isPassword
            />
            <TextInputField
              fieldMandatoryType={FieldMandatoryType.MANDATORY}
              title="Confirmation mot de passe"
              type={PersonnalInfoField.PasswordConfirmation}
              modifiers={modifiers}
              isPassword
            />
          </div>
        )}
        <div className={rowClasses}>
          {!hiddenFields.includes(PersonnalInfoField.HomePhone) && (
            <FormField
              control={control}
              name="personnalInfo.homePhone"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>Téléphone fixe</FormLabel>
                  <FormControl>
                    <PhoneInput
                      defaultCountry="FR"
                      placeholder={"01 XX XX XX XX"}
                      {...field}
                      disabled={disabledFields.includes(
                        PersonnalInfoField.HomePhone
                      )}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}
          {!hiddenFields.includes(PersonnalInfoField.MobilePhone) && (
            <FormField
              control={control}
              name="personnalInfo.mobilePhone"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>Mobile</FormLabel>
                  <FormControl>
                    <PhoneInput
                      defaultCountry="FR"
                      placeholder={"06 XX XX XX XX"}
                      {...field}
                      disabled={disabledFields.includes(
                        PersonnalInfoField.MobilePhone
                      )}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PersonnalInformationForm;
