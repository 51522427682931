import {
  useCandidateBasicInfo,
  useCandidateDeleteMutation,
} from "@/api/admin/admin";
import { keyFactory } from "@/api/keyFactory";
import { Button } from "@/components/atoms/Button";
import { Tabs, TabsList, TabsTrigger } from "@/components/atoms/Tabs";
import ConfirmDialog from "@/components/molecules/ConfirmDialog";
import { QueryLoadingSpinner } from "@/components/molecules/LoadingSpinner";
import { ROUTE } from "@/constants/routes";
import usePersistent from "@/hooks/use-persistent";
import { useQueryClient } from "@tanstack/react-query";
import { ArrowLeft, Ban, Trash2 } from "lucide-react";
import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";
import StatusBadge from "../Candidates/CandidatesTable/StatusBadge";
import { CandidateTab } from "./candidate-tab.enum";

const Candidate = () => {
  const { id: idstr } = useParams();
  const id = Number(idstr);
  const [currentTab, setCurrentTab] = usePersistent(
    "admin-candidate-tab",
    CandidateTab.OnGoingSession
  );
  const navigate = useNavigate();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const queryClient = useQueryClient();
  const location = useLocation();

  const candidateQuery = useCandidateBasicInfo(id, {
    enabled: !!id,
  });
  const { data } = candidateQuery;
  const candidateDeleteMutation = useCandidateDeleteMutation();

  // navigate to current tab
  useEffect(() => {
    if (!currentTab || !id) return;

    const tabRoutes = {
      [CandidateTab.OnGoingSession]:
        ROUTE.admin.dashboard.candidate.onGoingSession(id),
      [CandidateTab.Results]: ROUTE.admin.dashboard.candidate.results(id),
      [CandidateTab.Documents]: ROUTE.admin.dashboard.candidate.documents(id),
      [CandidateTab.PersonnalInfo]:
        ROUTE.admin.dashboard.candidate.personnalInformation(id),
      [CandidateTab.Certificates]:
        ROUTE.admin.dashboard.candidate.certificates(id),
    };

    const targetRoute = tabRoutes[currentTab];
    if (targetRoute && location.pathname !== targetRoute) navigate(targetRoute);
  }, [currentTab, id, navigate, location.pathname]);

  if (!id) navigate(ROUTE.admin.dashboard.candidates());

  return (
    <>
      <ConfirmDialog
        isOpen={deleteDialogOpen}
        cancelStr="Annuler"
        title="Supprimer le compte candidat"
        validateStr="Supprimer"
        validateButtonVariant="red"
        cancelButtonVariant="ghostWithBorders"
        onResult={(accepted) => {
          if (accepted)
            candidateDeleteMutation.mutate(id, {
              onSuccess: () => {
                toast.success("Candidat supprimé");
                queryClient.invalidateQueries({
                  queryKey: keyFactory.admin.candidate.base(id),
                });
                navigate(ROUTE.admin.dashboard.candidates());
              },
              onError: () => {
                toast.error("Echec de la suppression");
              },
            });
          else setDeleteDialogOpen(false);
        }}
      />
      <div className="flex flex-col w-full bg-white h-screen">
        <div className="flex flex-col w-full border-b border-b-gray-200 px-6 pt-3 gap-2">
          <div className="flex flex-row justify-between w-full items-center">
            <div className="flex flex-row gap-4 items-center">
              <Button
                variant="ghostWithBorders"
                className="p-2 rounded-lg"
                onClick={() =>
                  navigate(
                    location.state?.from || ROUTE.admin.dashboard.candidates()
                  )
                }
              >
                <ArrowLeft className="w-5 h-5 text-gray-500" />
              </Button>
              {data && data !== "id-not-found" ? (
                <div className="flex flex-col justify-center">
                  <div className="flex flex-row gap-2 items-center">
                    <h1>
                      {data.firstName} {data.lastName}
                    </h1>
                    <StatusBadge basicInfo={data} />
                  </div>
                  {data.lastCertificate && (
                    <span className="text-gray-600">
                      Fin de validité :{" "}
                      {new Date(
                        data.lastCertificate?.expirationDate
                      ).toLocaleDateString()}
                    </span>
                  )}
                </div>
              ) : (
                <QueryLoadingSpinner
                  queries={[candidateQuery]}
                  loadingMessage="Chargement du candidat..."
                  errorMessage="Erreur lors du chargement du candidat."
                />
              )}
              {data && data === "id-not-found" && (
                <Ban className="text-red-500 text-2xl" />
              )}
            </div>
            <Button
              variant="ghostWithBorders"
              className="p-2 rounded-lg"
              onClick={() => setDeleteDialogOpen(true)}
            >
              <Trash2 className="w-5 h-5 text-gray-500" />
            </Button>
          </div>
          <Tabs
            onValueChange={(e) => setCurrentTab(e as CandidateTab)}
            value={currentTab}
          >
            <TabsList variant="transparent" className="pb-0 mb-0">
              <TabsTrigger
                variant="underlineBlue"
                value={CandidateTab.OnGoingSession}
              >
                Session en cours
              </TabsTrigger>
              <TabsTrigger variant="underlineBlue" value={CandidateTab.Results}>
                Résultats
              </TabsTrigger>
              <TabsTrigger
                variant="underlineBlue"
                value={CandidateTab.Documents}
              >
                Documents
              </TabsTrigger>
              <TabsTrigger
                variant="underlineBlue"
                value={CandidateTab.PersonnalInfo}
              >
                Informations personelles
              </TabsTrigger>
              <TabsTrigger
                variant="underlineBlue"
                value={CandidateTab.Certificates}
              >
                Certificats
              </TabsTrigger>
            </TabsList>
          </Tabs>
        </div>
        {data && data === "id-not-found" ? (
          <div className="flex w-full h-full justify-center items-center">
            Aucun utilisateur avec cet identifiant
          </div>
        ) : (
          <Outlet />
        )}
      </div>
    </>
  );
};

export default Candidate;
