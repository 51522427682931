import { FormationType } from "@/api/enums/FormationType";
import { ValidationStatus } from "@/api/enums/ValidationStatus";
import { Button } from "@/components/atoms/Button";
import { Checkbox } from "@/components/atoms/CheckBox";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/atoms/Form";
import useChildForm from "@/hooks/ChildForm";
import { Perimeter, PerimeterUtil } from "@/lib/perimeter-util";
import { Plus } from "lucide-react";
import { useFieldArray } from "react-hook-form";
import FormationAttestationForm from "./FormationAttestationForm";
import { formationAttestationDefaultValues } from "./FormationAttestationsFormSchema";

interface FormationAttestationFormProps {
  fieldName?: string;
  forbiddenFormationTypes?: FormationType[];
  hiddenPerimeter?: Perimeter;
  validitionStatus?: ValidationStatus[];
}

const FormationAttestationsForm = ({
  fieldName,
  forbiddenFormationTypes = [],
  hiddenPerimeter = PerimeterUtil.emptyPerimeter(),
  validitionStatus = [],
}: FormationAttestationFormProps) => {
  const { transformPath, control } = useChildForm(fieldName);

  const { fields, append, remove } = useFieldArray({
    control,
    name: transformPath("attestations"),
  });

  return (
    <>
      {fields.map((field, i) => (
        <FormationAttestationForm
          key={field.id}
          fieldName={`${transformPath("attestations")}.${i}`}
          id={i}
          forbiddenFormationTypes={forbiddenFormationTypes}
          hiddenPerimeter={hiddenPerimeter}
          remove={() => remove(i)}
          validationStatus={
            validitionStatus.length > 0 ? validitionStatus[i] : undefined
          }
        />
      ))}
      <Button
        variant={"white"}
        className="w-full text-gray-800 font-semibold text-sm gap-2"
        onClick={(e) => {
          e.preventDefault();
          append(formationAttestationDefaultValues);
        }}
      >
        <Plus className="text-gray-500 w-5 h-5" />{" "}
        {fields.length > 0 ? (
          <>Ajouter une autre attestation</>
        ) : (
          <>Ajouter une attestation</>
        )}
      </Button>
      {fields.length > 0 && (
        <FormField
          control={control}
          name={transformPath("allAttestationsProvided")}
          render={({ field }) => (
            <FormItem className="flex flex-row items-center gap-2 space-y-0">
              <FormControl>
                <Checkbox
                  name="allAttestationsProvided"
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              </FormControl>
              <FormLabel>
                L'ensemble des justificatifs couvrant mon périmètre d'examen a
                été déposé
              </FormLabel>
            </FormItem>
          )}
        />
      )}
    </>
  );
};

export default FormationAttestationsForm;
