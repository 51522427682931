import { ReactNode } from "react";

interface CardTitleProps {
  children: ReactNode;
}

const CardTitle = ({ children }: CardTitleProps) => {
  return <span className="text-gray-700 font-bold">{children}</span>;
};

interface CardProps {
  children: ReactNode;
}

const Card = ({ children }: CardProps) => {
  return (
    <div className="flex w-full border border-gray-300 bg-white rounded-lg shadow-sm px-4 py-4 items-center gap-2">
      {children}
    </div>
  );
};

export { Card, CardTitle };
