import { useCompanyDelegationsQuery } from "@/api/company/company";
import { CompanyDelegationDtoClient } from "@/api/company/dto/company-delegation.dto";
import { PayementStatus } from "@/api/enums/PayementStatus";
import { Badge } from "@/components/atoms/Badge";
import { Input } from "@/components/atoms/Input";
import FileLink from "@/components/molecules/FileLink";
import { LoadingSpinner } from "@/components/molecules/LoadingSpinner";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/molecules/Table";
import { PerimeterUtil } from "@/lib/perimeter-util";
import { cn } from "@/lib/utils";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { format } from "date-fns";
import { useMemo, useState } from "react";

const columnHelper = createColumnHelper<CompanyDelegationDtoClient>();

const Candidates = () => {
  const [searchText, setSearchText] = useState("");

  /////////////////////////
  // api calls
  /////////////////////////

  const delegationsQuery = useCompanyDelegationsQuery();
  const { data: delegations } = delegationsQuery;
  const filteredData = useMemo(() => {
    if (!delegations) return [];
    return delegations.filter(
      ({ payementStatus, candidateEmail, candidateName, candidateSurname }) =>
        ((payementStatus === PayementStatus.DELEGATION_PAYED_CARD ||
          payementStatus === PayementStatus.DELEGATION_PAYED_TRANSFER) &&
          candidateEmail.toLowerCase().includes(searchText.toLowerCase())) ||
        candidateName.toLowerCase().includes(searchText.toLowerCase()) ||
        candidateSurname.toLowerCase().includes(searchText.toLowerCase())
    );
  }, [delegations, searchText]);

  /////////////////////////
  // table
  /////////////////////////

  const columns = useMemo(
    () => [
      columnHelper.accessor("exam.date", {
        id: "examDate",
        header: "Date examen",
        cell: (info) => (
          <span className="text-gray-600 font-medium">
            {format(new Date(info.getValue()), "dd/MM/yyyy")}
          </span>
        ),
      }),
      columnHelper.accessor(
        ({ candidateEmail, candidateName, candidateSurname }) => ({
          candidateEmail,
          candidateName,
          candidateSurname,
        }),
        {
          id: "user",
          header: "Candidat",
          cell: (info) => {
            const { candidateEmail, candidateName, candidateSurname } =
              info.getValue();
            return (
              <span className="text-gray-900 font-medium w-full">
                {candidateSurname + " " + candidateName}
                <br />
                <span className="text-gray-500 font-normal">
                  {candidateEmail}
                </span>
              </span>
            );
          },
        }
      ),
      columnHelper.accessor("result", {
        id: "validityDate",
        header: "Fin de validité",
        cell: (info) => {
          const validityEndDate = info.getValue()?.validityEndDate;
          if (!validityEndDate) return <></>;

          return (
            <span className="text-gray-600 font-medium">
              {format(validityEndDate, "dd/MM/yyyy")}
            </span>
          );
        },
      }),
      columnHelper.accessor("result", {
        id: "perimeter",
        header: "Pér.",
        cell: (info) => {
          const perimeter = info.getValue()?.perimeter;
          if (!perimeter) return <></>;

          return (
            <Badge className="text-gray-700" variant="white">
              {PerimeterUtil.toCode(perimeter)}
            </Badge>
          );
        },
      }),
      columnHelper.accessor("debitNote", {
        id: "payedDebitNote",
        header: "Note de débit réglée",
        cell: (info) => <FileLink file={info.getValue()} showName={false} />,
      }),
      columnHelper.accessor("convocation", {
        id: "convocation",
        header: "Convocation",
        cell: (info) => <FileLink file={info.getValue()} showName={false} />,
      }),
      columnHelper.accessor("result", {
        id: "attendanceCertificate",
        header: "Attestation de présence",
        cell: (info) => (
          <FileLink
            file={info.getValue()?.attendanceCertificate}
            showName={false}
          />
        ),
      }),
    ],
    []
  );

  const table = useReactTable({
    // data: delegationsQuery.data ?? [],
    data: filteredData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  /////////////////////////
  // component
  /////////////////////////

  if (delegations === undefined)
    return (
      <LoadingSpinner
        isLoading={delegationsQuery.isLoading}
        isError={delegationsQuery.isError}
        loadingMessage="Chargement de vos délégations..."
        errorMessage="Erreur lors du chargement de vos délégations."
      />
    );

  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-col w-full border-b border-b-gray-200">
        <div className="flex flex-col gap-2 py-3 px-6 bg-white w-full">
          <h1>Vos candidats</h1>
        </div>
      </div>
      <div className="py-4 px-6 flex flex-col gap-3">
        <Input
          className="shadow-none"
          id="shadcn-input"
          searchIcon
          type="text"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          placeholder="Rechercher"
        />
        <div className="rounded-lg overflow-hidden border border-gray-200">
          <Table className="text-gray-500 text-sm font-normal leading-6">
            <TableHeader className="items-center bg-gray-50">
              <TableRow>
                {table.getHeaderGroups()[0].headers.map((header) => (
                  <TableHead key={header.id}>
                    <div className="flex flex-row items-center gap-3 text-xs">
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </div>
                  </TableHead>
                ))}
              </TableRow>
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows.map((row, i) => (
                <TableRow key={row.id} className={cn({ "bg-white": !(i % 2) })}>
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id} className="py-2">
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default Candidates;
