import { FormationType } from "@/api/enums/FormationType";
import { ValidationStatus } from "@/api/enums/ValidationStatus";
import { perimeterSchema } from "@/components/molecules/PerimeterForm/PerimeterFormSchema";
import { file } from "@/constants/zodTypes";
import { PerimeterUtil } from "@/lib/perimeter-util";
import { z } from "zod";

const formationAttestationSchema = z
  .object({
    formationType: z.nativeEnum(FormationType),
    formationInstituteId: z.number().nullable().optional(),
    certificate: file(),
    perimeter: perimeterSchema,
    status: z.nativeEnum(ValidationStatus).optional(),
  })
  .superRefine(({ formationType, formationInstituteId }, ctx) => {
    if (
      formationType === FormationType.INSTITUTE &&
      formationInstituteId === -1
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Vous devez sélectionner un institut",
        path: ["formationInstituteId"],
      });
    }
  });

const formationAttestationsSchema = z.object({
  attestations: formationAttestationSchema.array(),
  allAttestationsProvided: z.boolean(),
});

const formationAttestationDefaultValues: z.infer<
  typeof formationAttestationSchema
> = {
  formationType: FormationType.COMPANY,
  formationInstituteId: -1,
  certificate: new File([""], ""),
  perimeter: PerimeterUtil.emptyPerimeter(),
  status: ValidationStatus.VALIDATED,
};

type FormationAttestationsSchemaType = z.infer<
  typeof formationAttestationsSchema
>;

export {
  formationAttestationDefaultValues,
  formationAttestationSchema,
  formationAttestationsSchema,
};

export type { FormationAttestationsSchemaType };
